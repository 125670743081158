import React, { useState } from "react";
import AppHeader from "../../components/AppHeader";
import SnackbarAlert from "../../components/SnackbarAlert";
import Loader from "../../components/Loader";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import TextInput from "../../components/TextInput";
import TextLabel from "../../components/TextLabel";
import CustomButton from "../../components/CustomButton";
import { appIcons } from "../../assets";
import LoginDialog from "./Login";
import { useNavigate } from "react-router-dom";
import { sendOtpCode } from "../../service/authentiction";

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleSendOtp = () => {
    if (!email) {
      handleSnackbarVal(true, "error", "Email is required");
    } else {
      setIsLoading(true);
      sendOtpCode({ email })
        .then((ress) => {
          setIsLoading(false);
          if (ress?.data?.status === "ok") {
            handleSnackbarVal(true, "success", ress?.data?.message);
            setTimeout(() => {
              navigate("/verifyCode", { state: email });
            }, 2000);
          } else {
            handleSnackbarVal(true, "error", ress?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };

  return (
    <AppHeader>
      <Divider />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Container sx={{ maxWidth: "450px !important", marginTop: "110px" }}>
        <Typography
          variant={"h4"}
          fontSize={26}
          fontWeight={700}
          textAlign={"center"}
          mt={3}
        >
          🔐 Forgot Password?
        </Typography>
        <Typography
          variant={"body1"}
          fontSize={13}
          fontWeight={400}
          textAlign={"center"}
          mt={1}
        >
          No worries, we’ll send you reset instructions.
        </Typography>
        <Grid container m={"20px 0"}>
          <Grid item xs={12}>
            <TextLabel inputText={"Email"} />
            <TextInput
              placeholder={"Enter your email"}
              inputType={"email"}
              inputVal={email}
              handleChangeInput={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>
        <Box width={"100%"} height={42}>
          <CustomButton
            btnText={"Send Code"}
            textWeight={500}
            handleClickBtn={handleSendOtp}
          />
        </Box>
        <Typography
          mt={2}
          gap={"5px"}
          fontSize={13}
          variant={"body1"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/login")}
        >
          <img src={appIcons?.backArrow} width={"18px"} /> Back to login
        </Typography>
      </Container>
    </AppHeader>
  );
}

export default ForgotPasswordPage;
