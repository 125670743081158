import { InputLabel } from "@mui/material";
import React from "react";
import { appColor } from "../constants/Colors";

function TextLabel({ inputText, textSize }) {
  return (
    <InputLabel
      sx={{
        fontSize: textSize ? textSize : "13px",
        color: appColor.black,
        textTransform: "capitalize",
        marginBottom: "2px",
      }}
    >
      {inputText}
    </InputLabel>
  );
}

export default TextLabel;
