export const appImages = {
  girlSinging: require("./images/girl-singing.png"),
  blackCirlcle: require("./images/black-circle.png"),
  roundText: require("./images/round-text.png"),
  playButton: require("./images/play-button.png"),
  musicImage: require("./images/music-image.jpg"),
  whiteLine: require("./images/white-line.png"),
  instaProfile: require("./images/insta-profile-image.png"),
  alienAvatar: require("./images/alien.png"),
  noComments: require("./images/no-comments.png"),
  dottedLine: require("./images/dotted-line.png"),
  dumyImage: require("./images/Mask-group.png"),
  appWhiteLogo: require("./images/whiteLogo.png"),
  dumyImage2: require("./images/Mask group.png"),
  musicBeat1: require("./images/beat-1.png"),
  musicBeat2: require("./images/beat-2.png"),
  cBackground1: require("./images/c-background1.png"),
  cBackground2: require("./images/c-background2.png"),
  winnerColor: require("./images/winner-text-color.png"),
  winnerWhite: require("./images/Winner-text-white.png"),
  cirlcleTransparent: require("./images/circle-transparent.png"),
  radiusCircle: require("./images/radius-circle.png"),
  emojiAvatar: require("./images/emoji-avatar.png"),
  giveAway: require("./images/giveaway-image.png"),
  homeGraphic1: require("./images/home-graphic-1.png"),
  homeGraphic2: require("./images/home-graphic-2.png"),
  NoPostImage: require("./images/img_noimage-available.jpg"),
  roundedFlower: require("./images/Round-Animated-flower.png"),
  pinkEastArrow: require("./images/pink-east-arrow.png"),
  instagramHome: require("./images/Instagram-home.png"),
  instaStopButton: require("./images/Stop-button.png"),
  tiktokHome: require("./images/tktok-home.png"),
};

export const appIcons = {
  commentIcon: require("./icons/Comment-Icon.png"),
  likeIcon: require("./icons/Like-Icon.png"),
  viewIcon: require("./icons/View-Icon.png"),
  facebookIcon: require("./icons/Facebook-icon.png"),
  googleIcon: require("./icons/Google-icon.png"),
  questionIcon: require("./icons/Question-icon.png"),
  editIcon: require("./icons/Edit-icon.png"),
  deleteIcon: require("./icons/Delete-icon.png"),
  instagramIcon: require("./icons/Instagram-icon.png"),
  copyIcon: require("./icons/Copy.png"),
  youtubeIcon: require("./icons/Youtube-icon.png"),
  replaceIcon: require("./icons/Replace-icon.png"),
  linkIcon: require("./icons/Link-icon.png"),
  refreshIcon: require("./icons/Refresh-icon.png"),
  americanFlag: require("./images/usa-flag-image.jpg"),
  calendorIcon: require("./icons/Calendar-icon.png"),
  persoinIcon: require("./icons/person-icon.png"),
  checkIcon: require("./icons/Check-icon.png"),
  tickIcon: require("./icons/Tick-icon.png"),
  crossIcon: require("./icons/Croose-icon.png"),
  backArrow: require("./icons/back-Arrow.png"),
  facebookOutline: require("./icons/facebookOutline-icon.png"),
  instagramOutline: require("./icons/instagramOutline-icon.png"),
  youtubeOutline: require("./icons/youtubeOutline-icon.png"),
  tiktokOutline: require("./icons/tiktokOutline-icon.png"),
  twiterOutline: require("./icons/twiterOutline-icon.png"),
  eastArrow: require("./icons/east-arrow-icon.png"),
  vlogCamera: require("./icons/vlog-camera-icon.png"),
  captureCamera: require("./icons/captured-camera-icon.png"),
  instagramColorIcon: require("./icons/instagram-color-icon.png"),
  fbColorIcon: require("./icons/fb-color-icon.png"),
  youtubeColorIcon: require("./icons/youtube-color-icon.png"),
  tikTokColorIcon: require("./icons/tiktok-color-icon.png"),
  twitterColorIcon: require("./icons/twiter-color-icon.png"),
  commentWhite: require("./icons/Comment-white-icon.png"),
  commentBlack: require("./icons/comment-black-icon.png"),
  heartWhite: require("./icons/heart-white-icon.png"),
  shareWhite: require("./icons/share-white-icon.png"),
  shareBlack: require("./icons/share-black-icon.png"),
  heartBlack: require("./icons/heart-black-icon.png"),
  clockIcon: require("./icons/Union.png"),
};

export const appGifs = {
  soundWaveGif: require("./gif/black-transparent.gif"),
  soundWaveGifblue: require("./gif/purple-color-gif.gif"),
  instaWave: require("./gif/white-wave-second.gif"),
};

export const appLogo = {
  rafleLogo: require("./images/rafle-logo.png"),
};

export const appAudio = {
  coinFlipSound: require("./audio/coin-flip-audio-rafle.mp3"),
  diceRollSound: require("./audio/dicce-roll-sound.mp3"),
};
