import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { appColor } from "../../../constants/Colors";
import TextInput from "../../../components/TextInput";
import { AppContext } from "../../../context";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";

const InputLabels = ({ text }) => {
  return (
    <Typography
      variant={"body1"}
      fontFamily={"Roboto Flex"}
      color={appColor.black}
      fontSize={13}
    >
      {text}
    </Typography>
  );
};

const BoxHeaders = ({ text }) => {
  return (
    <Box
      direction={"row"}
      p={"12px"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography
        variant={"h6"}
        fontSize={16}
        fontWeight={700}
        color={appColor.black}
        fontFamily={"Roboto Flex"}
      >
        {text}
      </Typography>
    </Box>
  );
};

function Billing() {
  const imgRef = useRef(null);
  const navigate = useNavigate();
  const [apiKey, setApiKey] = useState(
    "dw_25f45sdf4e5bfd158asd1dsg45asde8411cdb"
  );
  const { userData } = useContext(AppContext);
  const [resetPassword, setResePassword] = useState({
    password: "My Company, Inc.",
    newPassword: "Street 123, Country Z91919",
  });

  useEffect(() => {}, [userData]);
  console.log(userData, "aJHJHJHASUJHJHJJJKASJKJKJK");

  const handleResetPassword = (e) => {
    const { name, value } = e.target;
    setResePassword((preeeee) => ({
      ...preeeee,
      [name]: value,
    }));
  };

  const giveAway = [
    {
      name: "Instagram Giveaway",
      limit: "Up to 600 commments",
    },
    {
      name: "Facebook Giveaway",
      limit: "Up to 600 commments",
    },
    {
      name: "Tiktok Giveaway",
      limit: "Up to 600 commments",
    },
    {
      name: "Yotube Giveaway",
      limit: "Up to 600 commments",
    },
    {
      name: "List Giveaway",
      limit: "Up to 600 commments",
    },
    {
      name: "Muli-Network Giveaway",
      limit: "Up to 600 commments",
    },
  ];

  const campaigns = [
    {
      name: "Wheel of Prizes",
      limit: "✓",
    },
    {
      name: "Active Campaigns",
      limit: "1",
    },
    {
      name: "Active Campaigns",
      limit: "100",
    },
  ];

  return (
    <Box sx={{ margin: { xs: "30px 5px", md: "30px 20px" } }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box
            bgcolor={appColor.white}
            borderRadius={"20px"}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
          >
            <BoxHeaders text={"Subscription 💰"} />
            <Divider />
            <Box sx={{ padding: { xs: "12px", md: "12px 20px" } }}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                flexWrap={"wrap"}
                gap={"10px"}
              >
                <Typography
                  variant={"h3"}
                  fontSize={30}
                  fontFamily={"Roboto Flex"}
                  fontWeight={800}
                >
                  {userData?.package} Plan
                </Typography>
                <Box height={40} width={150}>
                  <CustomButton
                    btnText={"Upgrade Plan"}
                    textWeight={400}
                    handleClickBtn={() => navigate("/shop")}
                  />
                </Box>
              </Stack>
              <Typography variant={"body1"} fontSize={13} mt={1}>
                Unlock all additional features with our premium plans.
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ padding: { xs: "12px", md: "12px 8px" } }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant={"h3"}
                        fontWeight={500}
                        fontSize={16}
                        fontFamily={"Roboto Flex"}
                        mb={1}
                      >
                        Social Media Giveaway 🙌🏻
                      </Typography>
                    </Grid>
                    {giveAway?.map(({ name, limit }) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          display={"flex"}
                          justifyContent={"space-between"}
                          mb={1.5}
                          flexWrap={"wrap"}
                          gap={"10px"}
                        >
                          <Typography
                            variant={"body1"}
                            fontFamily={"Roboto Flex"}
                            fontSize={11}
                            color="#000"
                          >
                            {name}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            fontFamily={"Roboto Flex"}
                            fontSize={11}
                            color="#000"
                          >
                            {limit}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container sx={{ ml: { xs: "0", md: 2 } }}>
                    <Grid item xs={12}>
                      <Typography
                        variant={"h3"}
                        fontWeight={500}
                        fontSize={16}
                        fontFamily={"Roboto Flex"}
                        mb={1.5}
                      >
                        Campaigns & Promotions 🏕
                      </Typography>
                    </Grid>
                    {campaigns?.map(({ name, limit }) => {
                      return (
                        <Grid item xs={12} display={"flex"} gap={"10px"} mb={1}>
                          <Typography
                            variant={"body1"}
                            fontFamily={"Roboto Flex"}
                            fontSize={11}
                            width={"100px"}
                            color="#000"
                          >
                            {name}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            fontFamily={"Roboto Flex"}
                            fontSize={11}
                            color="#000"
                          >
                            {limit}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                bgcolor={appColor.white}
                borderRadius={"20px"}
                boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
              >
                <BoxHeaders text={"Invoices 📜"} />
                <Divider />
                <Box sx={{ padding: { xs: "12px", md: "12px 20px" } }}>
                  <InputLabels text={"Your Dual Wire’s API Key is:"} />
                  <Box mt={"4px"}>
                    <TextInput
                      paddingCustom={"8.5"}
                      inputVal={apiKey}
                      handleChangeInput={(e) => setApiKey(e.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                bgcolor={appColor.white}
                borderRadius={"20px"}
                boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
              >
                <BoxHeaders text={"Billing information 🧾"} />
                <Divider />
                <Box sx={{ padding: { xs: "12px", md: "12px 20px" } }}>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12} md={4}>
                      <InputLabels text={"Business Name"} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextInput
                        paddingCustom={"8.5"}
                        inputType={"text"}
                        inputName={"password"}
                        inputVal={resetPassword?.password}
                        handleChangeInput={handleResetPassword}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabels text={"Address"} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextInput
                        minRows={3}
                        multiline={true}
                        paddingCustom={"8.5"}
                        inputType={"text"}
                        inputName={"newPassword"}
                        inputVal={resetPassword?.newPassword}
                        handleChangeInput={handleResetPassword}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabels text={"Tax Number / ID"} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextInput
                        paddingCustom={"8.5"}
                        inputType={"text"}
                        inputName={"password"}
                        inputVal={resetPassword?.password}
                        handleChangeInput={handleResetPassword}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box height={40} width={150} margin={"20px auto"}>
        <CustomButton
          btnText={"Save Changes"}
          textWeight={400}
          // handleClickBtn={handleClick}
        />
      </Box>
    </Box>
  );
}

export default Billing;
