import { Box, Container, Typography } from "@mui/material";
import React from "react";
import CustomButton from "./CustomButton";
import { useNavigate } from "react-router-dom";

function GotoLogin() {
    const navigate = useNavigate()
  return (
    <Container sx={{ maxWidth: "1250px !important", marginTop: "30px" }}>
      <Box mt={8}>
        <Typography variant={"h5"} fontWeight={600} textAlign={"center"}>
          Create your first Giveaway
        </Typography>
        <Typography
          variant={"body1"}
          fontWeight={400}
          fontSize={"14px"}
          textAlign={"center"}
        >
          Create giveaways for Youtube, Facebook, Tiktok, Lists and more
        </Typography>
        <Box width={"160px"} height={40} margin={"20px auto"}>
          <CustomButton
            btnText={"Continue"}
            handleClickBtn={() => navigate("/login")}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default GotoLogin;
