import React, { useContext } from "react";
import Select from "react-select";
import { AppContext } from "../context";
import { appColor } from "../constants/Colors";

const CanMultipleSelect = ({ options, state, setState }) => {
  const { currentColor } = useContext(AppContext);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color:
        currentColor === "#161616" ||
        currentColor === "#5A663E" ||
        currentColor === "#798952"
          ? "#fff"
          : "#bdb9b9",
      borderColor:
        currentColor === "#161616" ||
        currentColor === "#5A663E" ||
        currentColor === "#798952"
          ? "#fff"
          : "#bdb9b9",
      // background:"transparent"
    }),
  };
  return (
    <Select
      isMulti
      styles={customStyles}
      closeMenuOnSelect={false}
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      value={state}
      onChange={(selectedOption) => setState(selectedOption)}
    />
  );
};

export default CanMultipleSelect;
