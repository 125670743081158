import Api from "./index";
import { endPoints, requestType } from "../constants/Variable";
//getVedioInfo

export const getVedioInfo = (params) => {
  return Api(endPoints.getVideoInfo, params, requestType.POST);
};

export const getYoutubeCommentsbg = (videoId, userId, giveawayId) => {
  return Api(
    `${endPoints.ytbackgroundComments}?id=${videoId}&userId=${userId}&giveawayId=${giveawayId}`,
    null,
    requestType.GET
  );
};

export const getYoutubeCommentsdb = (videoId, userId, page) => {
  return Api(
    `${endPoints.getYoutbeComments}/${videoId}/${userId}?page=${page}`,
    null,
    requestType.GET
  );
};

export const saveVideoHistory = (params) => {
  return Api(endPoints.saveVideoData, params, requestType.POST);
};

export const saveYoutubeBgData = (params) => {
  return Api(endPoints.saveYoutubeBgData, params, requestType.POST);
};

export const getVideoHistoryById = (id, params) => {
  return Api(`${endPoints.getVideoHistoryById}/${id}`, params, requestType.GET);
};

export const updateVideoHistory = (id, params) => {
  return Api(`${endPoints.updateVideoHistory}/${id}`, params, requestType.PUT);
};

export const getTwitterPost = (params) => {
  return Api(endPoints.getTwitterPostInfo, params, requestType.POST);
};

export const getInstagramPostByUserName = (params) => {
  return Api(endPoints.getInstagramPostByUserName, params, requestType.POST);
};

export const getSingleInstaPost = (params) => {
  return Api(endPoints.fetchSinglePost, params, requestType.POST);
};

export const getInstaPosComments = (params) => {
  return Api(endPoints.fetchIntaComments, params, requestType.POST);
};

export const getAllHelps = (params) => {
  return Api(endPoints.getAllHelp, null, requestType.GET);
};

export const getTiktokVideoData = (id) => {
  return Api(`${endPoints.getTikTokVideoData}/${id}`, null, requestType.GET);
};

export const getTiktokVideoComments = (id) => {
  return Api(`${endPoints.getTikTokVideComments}/${id}`, null, requestType.GET);
};

export const checkExistComment = (videoId, userId) => {
  return Api(
    `${endPoints.checkExistComment}/${videoId}/${userId}`,
    null,
    requestType.GET
  );
};
