import React, { useContext, useState } from "react";
import AppHeader from "../../components/AppHeader";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context";
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import SnackbarAlert from "../../components/SnackbarAlert";
import Loader from "../../components/Loader";
import { loginUser, socialRegister } from "../../service/authentiction";
import TextLabel from "../../components/TextLabel";
import TextInput from "../../components/TextInput";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomButton from "../../components/CustomButton";
import { appIcons } from "../../assets";
import WelcomeDialog from "../../components/WelComeDialog";
import { signInWithPopup } from "firebase/auth";
import { googleAuth, firebaseAuth } from "../../components/FirebaseConfig";
import { appColor } from "../../constants/Colors";

function LoginPage() {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUserData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleLogin = () => {
    if (email == "") {
      handleSnackbarVal(true, "error", "Email is required");
    } else if (!emailRegex.test(email)) {
      handleSnackbarVal(true, "error", "Invalid email format");
    } else if (password == "") {
      handleSnackbarVal(true, "error", "Password is required");
    } else {
      let payload = {
        email,
        password,
      };
      setIsLoading(true);
      loginUser(payload)
        .then((res) => {
          console.log(res, "RESSSPONSEEEEEE");
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            let userData = res?.data?.data?.userDetails;
            let userToken = res?.data?.data?.token;
            localStorage.setItem("user", JSON.stringify(userData));
            localStorage.setItem("token", JSON.stringify(userToken));
            setUserData(userData);
            handleSnackbarVal(true, "success", `Welcom Back ${userData?.name}`);
            setEmail("");
            setPassword("");
            setTimeout(() => {
              navigate("/");
            });
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((errr) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", errr?.message);
          console.log(errr, "EROOORRRR");
        });
    }
  };

  const handleSinginWithGoogle = () => {
    signInWithPopup(firebaseAuth, googleAuth)
      .then((result) => {
        console.log(result.user, "junaidddddd");
        let data = result?.user;
        let name = data?.displayName.split(" ");
        let newObj = {
          firstName: name[0],
          lastName: name[1] === undefined ? "" : name[1],
          email: data?.email,
          profileImage: data?.photoURL,
        };
        socialRegister(newObj)
          .then((response) => {
            setIsLoading(false);
            if (response?.data?.status === "ok") {
              let userData = response?.data?.data;
              localStorage.setItem("user", JSON.stringify(userData));
              setUserData(userData);
              setTimeout(() => {
                navigate("/");
              });
            } else {
              handleSnackbarVal(true, "error", response?.data?.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            handleSnackbarVal(true, "error", error?.message);
          });
        // console.log(data, "masooddddd");
        // return;
        // localStorage.setItem("user", JSON.stringify(newObj));
        // localStorage.setItem("token", JSON.stringify(data?.accessToken));
        // setOpen(false);
      })
      .catch((error) => {
        console.log(error, "ASDasdasdasdsad");
        handleSnackbarVal(true, "error", error?.message);
      });
  };

  return (
    <AppHeader>
      <Divider />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Container sx={{ maxWidth: "450px !important", marginTop: "50px" }}>
        <Typography
          variant={"h4"}
          fontSize={26}
          fontWeight={700}
          textAlign={"center"}
          mt={3}
        >
          👋🏻 Join the Fun!!
        </Typography>
        <Typography
          variant={"body1"}
          fontSize={13}
          fontWeight={400}
          textAlign={"center"}
          mt={1}
        >
          Login for your shot at randoms wins!!
        </Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <TextLabel inputText={"Email"} />
            <TextInput
              placeholder={"Enter your email"}
              inputType={"email"}
              inputVal={email}
              handleChangeInput={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextLabel inputText={"Password"} />
            <TextInput
              placeholder={"Enter your password"}
              inputType={hidePassword ? "text" : "password"}
              inputVal={password}
              handleChangeInput={(e) => setPassword(e.target.value)}
              endIcon={
                hidePassword ? (
                  <RemoveRedEyeIcon
                    sx={{ color: "#C9CCD1", mr: "10px", cursor: "pointer" }}
                    onClick={() => setHidePassword(false)}
                  />
                ) : (
                  <VisibilityOffIcon
                    sx={{ color: "#C9CCD1", mr: "10px", cursor: "pointer" }}
                    onClick={() => setHidePassword(true)}
                  />
                )
              }
            />
          </Grid>
        </Grid>
        <Box textAlign={"right"} m={"10px 0px"}>
          <Typography
            variant={"span"}
            fontSize={13}
            color={"#161616"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/forgotPassword");
            }}
          >
            Forgot password?
          </Typography>
        </Box>
        <Box>
          <Box width={"100%"} height={40} mt={3}>
            <CustomButton
              btnText={"Login"}
              textWeight={500}
              handleClickBtn={handleLogin}
            />
          </Box>
        </Box>
        <Stack
          direction={"row"}
          mt={2.5}
          flexWrap={"wrap"}
          useFlexGap
          width={"100%"}
          justifyContent={"center"}
          spacing={2}
          height={"45px"}
        >
          <Stack
            direction={"row"}
            // p={1}
            spacing={1}
            border={"1px solid #C9CCD1"}
            borderRadius={"10px"}
            sx={{ cursor: "pointer", width: { xs: "100%", md: "45%" } }}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={handleSinginWithGoogle}
          >
            <img src={appIcons.googleIcon} width={"20px"} />
            <Typography variant={"body1"} fontSize={12}>
              Sign up with Google
            </Typography>
          </Stack>
        </Stack>
        <Typography variant={"body1"} fontSize={13} mt={2} textAlign={"center"}>
          Do not have an account?
          <Link
            sx={{
              ml: "3px",
              color: appColor.black,
              fontWeight: 500,
              textDecorationColor: appColor.black,
              textDecorationThickness: "2px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/signup")}
          >
            Create an Account
          </Link>
        </Typography>
      </Container>
    </AppHeader>
  );
}

export default LoginPage;
