import Api from "./index";
import { endPoints, requestType } from "../constants/Variable";

export const fetchHome = () => {
  return Api(endPoints.getHome, null, requestType.GET);
};
export const fetchVideoInfo = () => {
  return Api(endPoints.getVideoInfo, null, requestType.GET);
};
export const fetchSetting = () => {
  return Api(endPoints.getSetting, null, requestType.GET);
};
export const fetchLoader = () => {
  return Api(endPoints.getLoader, null, requestType.GET);
};
export const fetchCongratulation = () => {
  return Api(endPoints.getCongratulation, null, requestType.GET);
};
export const fetchWinnerData = () => {
  return Api(endPoints.getWinner, null, requestType.GET);
};
export const fetchCertificate = () => {
  return Api(endPoints.getCertificate, null, requestType.GET);
};
export const fetchPackagesData = () => {
  return Api(endPoints.getPackages, null, requestType.GET);
};
export const fetchPackagesHeading = () => {
  return Api(endPoints.getPlansHeading, null, requestType.GET);
};
export const fetchPrivacy = () => {
  return Api(endPoints.getPrivacy, null, requestType.GET);
};
export const upgradePlan = (id, params) => {
  return Api(`${endPoints.upgradePlan}/${id}`, params, requestType.PUT);
};
export const getPromo = (id, params) => {
  return Api(endPoints.getPromo, null, requestType.GET);
};
