import Api from "./index";
import { endPoints, requestType } from "../constants/Variable";

export const fetchAllTwitterReply = (params) => {
  return Api(
    `twitter/${endPoints.fetchTwitterReply}`,
    params,
    requestType.POST
  );
};

export const getTwitterPostLikes = (params) => {
  return Api(
    `twitter/${endPoints.getTwitterPostLikes}`,
    params,
    requestType.POST
  );
};

export const getTwitterPostRetweets = (params) => {
  return Api(
    `twitter/${endPoints.getTwitterPostRetweets}`,
    params,
    requestType.POST
  );
};
