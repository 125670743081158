import {
  Box,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { appImages } from "../../../../assets";
import AnimatedLoader from "../../../../components/AnimatedLoader";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import CustomButton from "../../../../components/CustomButton";
import Loader from "../../../../components/Loader";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import SoundWaveGif from "../../../../components/SoundWaveGif";
import { appColor } from "../../../../constants/Colors";
import { appFonts } from "../../../../constants/Fonts";
import { AppContext } from "../../../../context";
import GlobalStyle from "../../../../globalStyle";
import { setAppFilters } from "../../../../redux/slices/GiveawaySlice";
import { getVedioInfo } from "../../../../service/comments";
import { fetchHome } from "../../../../service/common";
import "./style.css";

function YoutubeGiveAway() {
  const classes = GlobalStyle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [homeData, setHomeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState("");
  const { setYoutubeData, setCurrentColor, setCountDown } =
    useContext(AppContext);
  const [dataLoading, setDataLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    let params = {
      giveawayType: "Youtube",
      dublicate: false,
      hashtag: false,
      block: false,
      extraChance: false,
      extraChanceNames: [],
      blockListNames: [],
      hashtagText: "",
      counter: 5,
      totalWinners: 1,
      totalSubstitutes: 0,
      actionType: "add",
      allComments: [],
      currentColor: "#ffffff",
      giveAwayId: "",
    };
    dispatch(setAppFilters(params));
    setCurrentColor("#fff");
    setCountDown(5);
  }, []);

  function extractYouTubeVideoID(url) {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  const handleClick = () => {
    if (!youtubeLink) {
      handleSnackbarVal(true, "error", "Please enter the URL first");
    } else {
      let starts =
        youtubeLink.startsWith("https://www.youtube.com") ||
        youtubeLink.startsWith("https://youtube.com") ||
        youtubeLink.startsWith("https://youtu.be");
      let videoId = extractYouTubeVideoID(youtubeLink);

      if (starts && videoId) {
        setDataLoading(true);
        getVedioInfo({ videoId })
          .then((ress) => {
            setDataLoading(false);
            if (ress?.data?.status == "ok") {
              let data = ress?.data?.data;
              let newData = { ...data, videoId, youtubeLink };
              setYoutubeData(newData);
              localStorage.setItem("post_data", JSON.stringify(newData));
              navigate("/youtube-giveaway/video-details");
            } else {
              handleSnackbarVal(true, "error", ress?.data?.message);
            }
          })
          .catch((errr) => {
            setDataLoading(false);
            handleSnackbarVal(true, "error", errr?.message);
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "The URL you entered is incorrect.",
          confirmButtonColor: "#ff3600",
          customClass: {
            container: "custom-swal-container",
          },
        });
      }
    }
  };

  useEffect(() => {
    getHomeData();
  }, []);

  const getHomeData = () => {
    setIsLoading(true);
    fetchHome()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setHomeData(data);
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  return (
    <Box>
      <AppHeader>
        <Divider />
        <SnackbarAlert
          snackbarProps={snackbarProps}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <Loader isloading={isLoading} />
        {dataLoading ? (
          <AnimatedLoader />
        ) : (
          <Container sx={{ maxWidth: "1250px !important", marginTop: "70px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box>
                  <img
                    src={appImages.radiusCircle}
                    width={"400px"}
                    style={{
                      position: "absolute",
                      top: "4%",
                      left: "8%",
                      zIndex: "666",
                    }}
                    className="rectangle-image"
                  />
                  <Box
                    position={"absolute"}
                    top={"30px"}
                    zIndex={"1100"}
                    left={"22%"}
                    sx={{
                      top: { xs: "72px", sm: "30px", md: "30px", lg: "30px" },
                      left: { xs: "30%", sm: "36%", md: "23%", lg: "20%" },
                    }}
                  >
                    <Box sx={{ width: 220 }}>
                      <img
                        src={appImages.giveAway}
                        width={"100%"}
                        className="main-image"
                      />
                    </Box>
                    <Box
                      height={220}
                      width={220}
                      position={"absolute"}
                      top={"45%"}
                      left={"30%"}
                      zIndex={"1150"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        top: { xs: "48%", sm: "70%" },
                        left: { xs: "16.5px", sm: "-50px" },
                        height: { xs: "155px", sm: "200px" },
                        width: { xs: "155px", sm: "200px" },
                      }}
                    >
                      <img
                        src={appImages.roundText}
                        width={"100%"}
                        className="round-text"
                      />
                      <img
                        src={appImages.whiteLine}
                        style={{ position: "absolute", width: "85%" }}
                      />
                      <img
                        src={appImages.blackCirlcle}
                        width={"78%"}
                        style={{
                          position: "absolute",
                        }}
                      />
                      <img
                        src={appImages.playButton}
                        style={{
                          width: "12%",
                          position: "absolute",
                        }}
                      />
                      <img
                        src={appImages.cirlcleTransparent}
                        style={{
                          width: "70%",
                          position: "absolute",
                          left: "-80px",
                        }}
                        className="rectangle-image"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ mt: { xs: "15rem", sm: "22rem", md: "0px" } }}
              >
                <Typography
                  variant={"h6"}
                  fontSize={15}
                  letterSpacing={"10px"}
                  fontWeight={600}
                  textTransform={"capitalize"}
                  sx={{ letterSpacing: { xs: "4px", sm: "10px", lg: "15px" } }}
                  mb={1}
                >
                  {homeData?.heading1 ? homeData?.heading1 : ""}
                </Typography>
                <Typography
                  variant={"h1"}
                  fontWeight={900}
                  textTransform={"uppercase"}
                  letterSpacing={"2px"}
                  sx={{ fontSize: { xs: 30, sm: 40, md: 40, lg: 50 } }}
                  fontFamily={appFonts?.boldExtended}
                  textAlign={"left"}
                >
                  Your Comment‚
                </Typography>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  flexWrap={"wrap"}
                >
                  <Typography
                    variant={"h1"}
                    fontWeight={900}
                    textTransform={"uppercase"}
                    letterSpacing={"2px"}
                    sx={{ fontSize: { xs: 30, sm: 40, md: 45, lg: 50 } }}
                    fontFamily={appFonts?.boldExtended}
                  >
                    Our Pick
                  </Typography>

                  <SoundWaveGif />
                </Stack>
                <Typography
                  variant={"body1"}
                  fontSize={13}
                  color={appColor.black}
                  mb={1}
                >
                  {homeData?.heading2 ? homeData?.heading2 : ""}
                </Typography>

                <TextField
                  size="small"
                  placeholder={"Enter a Youtube Video URL"}
                  className={classes.inputField}
                  value={youtubeLink}
                  onChange={(e) => setYoutubeLink(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box height={40} width={90}>
                          <CustomButton
                            btnText={"start"}
                            textWeight={400}
                            handleClickBtn={handleClick}
                          />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography variant={"body1"} fontSize={12} mt={1}>
                  🔗
                  {homeData?.heading3 ? homeData?.heading3 : ""}
                </Typography>
              </Grid>
            </Grid>
            {!dataLoading && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                sx={{ marginTop: { xs: "20px", md: "0px" } }}
              >
                <Container
                  sx={{
                    maxWidth: "1200px !important",
                    position: { xs: "relative", md: "absolute" },
                    bottom: 0,
                  }}
                >
                  <AppFooter />
                </Container>
              </Box>
            )}
          </Container>
        )}
      </AppHeader>
    </Box>
  );
}

export default YoutubeGiveAway;
