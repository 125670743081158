import { Stack } from "@mui/material";

const StackTemplate = ({ children, stackProps }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      {...stackProps}
    >
      {children}
    </Stack>
  );
};

export default StackTemplate;
