import { makeStyles } from "@mui/styles";

const InstaPostStyle = makeStyles((theme) => ({
  instaCrad: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 220,
    borderRadius: "15px",
    overflow: "hidden",
    transform: "translate(0)",
    transition: "0.5s ease !important",
    cursor: "pointer",
    "&:hover": {
      transform: "translate(5px, -5px)",
    //   filter:"contrast(0.5)"
      filter:"brightness(0.5)"
    },

  },

  instaCradImg: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
}));

export default InstaPostStyle;
