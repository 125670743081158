import React, { useContext, useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Avatar,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { appColor } from "../constants/Colors";
import CustomButton from "./CustomButton";
import TextLabel from "./TextLabel";
import TextInput from "./TextInput";
import { AppContext } from "../context";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
    flexDirection: "row",
    justifyContent: "space-between",
  },
  "& .MuiDialog-paper": {
    width: "450px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ReplaceWinner({
  open,
  setOpen,
  handleClose,
  dialogData,
  replaceIndex,
  handleSnackbarVal,
  setOpenNewProfile,
}) {
  const { setWin, win, setSubs, subs, setNewWinnerData } =
    useContext(AppContext);
  const [alternateData, setAlternteData] = useState(null);
  const [alternateIndex, setAlternateIndex] = useState(0);
  const [reason, setReason] = useState("");

  const handleReplace = () => {
    if (!alternateData) {
      handleSnackbarVal(true, "error", "Please Select Alternate");
    } else {
      const newWinners = [...win];
      const newAlternates = [...subs];
      const deletedObject = newWinners.splice(replaceIndex, 1, alternateData);
      newAlternates.splice(alternateIndex, 1, deletedObject[0]);
      setWin(newWinners);
      setSubs(newAlternates);
      handleClose();
      handleSnackbarVal(true, "success", "Winner Replace Successfully");
      setAlternteData(null);
      setReason("");
      setAlternateIndex(0);
      handleClose();
      setOpenNewProfile(true);
    }
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        scroll="body"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            variant={"h6"}
            fontSize={22}
            fontWeight={700}
            textAlign={"center"}
            mt={2}
            fontFamily={"Roboto Flex"}
          >
            🎖 Replace Winner!!
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={13}
            textAlign={"center"}
            fontFamily={"Roboto Flex"}
            lineHeight={"normal"}
            p={"0 50px"}
          >
            Do you want to replace the winner{" "}
            <span style={{ fontWeight: "600" }}>{dialogData}</span> with an
            alternate?
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent style={{ padding: "10px 20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextLabel inputText={"Replace with"} />
              <Select
                onChange={(e) => {
                  setAlternteData(e.target.value);
                  setNewWinnerData(e.target.value);
                }}
                fullWidth
                size="small"
              >
                {subs?.map((dataa, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={dataa}
                      onClick={() => setAlternateIndex(index)}
                    >
                      {dataa?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextLabel inputText={"Reason"} />
              <TextInput
                placeholder={"Substitution reason"}
                multiline={true}
                minRows={3}
                inputVal={reason}
                handleChangeInput={(e) => setReason(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box width={"150px"} height={40}>
            <CustomButton btnText={"Replace"} handleClickBtn={handleReplace} />
          </Box>
          <Box
            width={"150px"}
            height={40}
            border={"1px solid"}
            borderRadius={"50px"}
          >
            <CustomButton
              btnText={"Cancel"}
              btnBackground={"transparent"}
              textColor={appColor.black}
              handleClickBtn={handleClose}
            />
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
