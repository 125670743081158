import {
  Avatar,
  Box,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appIcons } from "../../../../assets";
import AnimatedLoader from "../../../../components/AnimatedLoader";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import CommentWaitDialog from "../../../../components/CommentWaitDialog";
import CustomButton from "../../../../components/CustomButton";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import SoundWaveGif from "../../../../components/SoundWaveGif";
import StackTemplate from "../../../../components/StackTemplate";
import { appColor } from "../../../../constants/Colors";
import { AppContext } from "../../../../context";
import GlobalStyle from "../../../../globalStyle";
import { setAppFilters } from "../../../../redux/slices/GiveawaySlice";
import {
  checkExistComment,
  getYoutubeCommentsbg,
  saveYoutubeBgData,
} from "../../../../service/comments";
import { fetchVideoInfo } from "../../../../service/common";
import LoginDialog from "../../../auth/Login";
import SignupDialog from "../../../auth/Signup";
import BuyPlanModal from "./../../../../components/BuyPlan";
import "./style.css";

function ChannelSection() {
  const globalCls = GlobalStyle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openLogin, setOpenLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [videoInfo, setVideoInfo] = useState(null);
  const [openSignup, setOpenSignup] = useState(false);
  const [openWaitDialog, setOpenWaitDialog] = useState(false);
  const [openPlanDialog, setOpenPlanDialog] = useState(false);
  const { userData, youtubeData, handleUpdateSteps, isExist, setIsExist } =
    useContext(AppContext);
  const filterData = useSelector((state) => state?.giveaway?.appFilters);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  console.log(filterData, "youtubeDatayoutubeDatayoutubeData");

  // dispatch(setAppFilters({ ...filterData, allComments: comments }));
  // navigate("/youtube-giveaway/comment-filters", { replace: true });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleClosePlanDialog = () => {
    setOpenPlanDialog(false);
  };

  useEffect(() => {
    getVideoInfo();
  }, []);

  useEffect(() => {
    if (userData) {
      handleUpdateSteps(userData?._id, 1, "Youtube");
    }
  }, [userData]);

  useEffect(() => {
    if (youtubeData && userData) {
      handleCheckExistComment(youtubeData?.videoId, userData?._id);
    }
  }, [youtubeData, userData]);

  const handleCheckExistComment = async (videoId, userId) => {
    try {
      const response = await checkExistComment(videoId, userId);
      if (response.data.status == "ok") {
        const data = response.data.exists;
        setIsExist(data);
      } else {
        setIsExist(true);
      }
    } catch (error) {
      console.log(error);
      setIsExist(false);
    }
  };

  const getVideoInfo = () => {
    fetchVideoInfo()
      .then((res) => {
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setVideoInfo(data);
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        handleSnackbarVal(true, "error", err?.message);
        setIsLoading(false);
      });
  };

  const handleFetchCommentsRemotely = async (giveawayId) => {
    try {
      const response = await getYoutubeCommentsbg(
        youtubeData?.videoId,
        userData?._id,
        giveawayId
      );
      if (response?.status == 200 || response?.status == 201) {
        console.log("open pop up");
      } else {
        handleSnackbarVal(
          true,
          "error",
          "An error occurred while fetching comments"
        );
      }
    } catch (error) {
      handleSnackbarVal(
        true,
        "error",
        "An error occurred while fetching comments"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveGiveaway = async () => {
    let payload = {
      userId: userData?._id,
      channelName: youtubeData.chanelName,
      videoTitle: youtubeData.title,
      commentCount: youtubeData.commentCount,
      likeCount: youtubeData.likeCount,
      viewCount: youtubeData.viewCount,
      videoLink: youtubeData.youtubeLink,
      videoThumbnail: youtubeData.thumbnail,
      giveawayType: "Youtube",
      status: "Pending",
      contentId: youtubeData.videoId,
    };
    try {
      setIsLoading(true);
      const response = await saveYoutubeBgData(payload);
      if (response.status === 200 || response.status === 201) {
        let giveawayId = response.data.data._id;
        setOpenWaitDialog(true);
        handleFetchCommentsRemotely(giveawayId);
      } else {
        handleSnackbarVal(
          true,
          "error",
          "Can not process your comments please try again"
        );
      }
    } catch (error) {
      console.log(error);
      console.log(error, "sdnasdjhasdjsdjasjkasjkd");
      handleSnackbarVal(
        true,
        "error",
        "Can not process your comments please try again"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckLogin = () => {
    let commentCount = youtubeData?.commentCount;
    let userAllowedComments = parseInt(userData?.comments);

    if (!userData) {
      setOpenLogin(true);
      return;
    }

    if (commentCount > userAllowedComments) {
      setOpenPlanDialog(true);
      return;
    }

    if (youtubeData?.commentCount == 0) {
      handleSnackbarVal(true, "error", "Opps this post has no comments");
      return;
    }

    if (isExist && commentCount > 5000) {
      return navigate("/youtube-giveaway/comment-filters", { replace: true });
      // return alert("database se ayega");
    }

    if (!isExist && commentCount <= 5000) {
      handleFetchCommentsDirectly();
    } else {
      handleSaveGiveaway();
    }
  };

  const handleFetchCommentsDirectly = async () => {
    try {
      const API_KEY = "AIzaSyBfG5TsICtyKC08-ntCHH9UhNiFL9B56JE";
      let nextPageToken = "";
      const allComments = [];
      setIsLoading(true);
      while (nextPageToken !== undefined) {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/commentThreads`,
          {
            params: {
              part: "snippet",
              videoId: youtubeData?.videoId,
              textFormat: "plainText",
              maxResults: 100,
              pageToken: nextPageToken,
              key: API_KEY,
            },
          }
        );

        const items = response?.data?.items || [];

        allComments.push(
          ...items.map((item) => {
            const comment = item.snippet.topLevelComment.snippet;
            const hashtags =
              comment.textOriginal.toLowerCase().match(/#(\w+)/g) || [];
            return {
              text: comment.textOriginal,
              name: comment.authorDisplayName,
              image: comment.authorProfileImageUrl,
              userName: comment.authorDisplayName,
              hashtags: hashtags.map((tag) => tag.substring(1)),
              authorId: comment.authorChannelId,
              authorUrl: comment.authorChannelUrl,
            };
          })
        );

        nextPageToken = response.data.nextPageToken;
      }

      setIsLoading(false);
      navigate("/youtube-giveaway/comment-filters", { replace: true });
      dispatch(setAppFilters({ ...filterData, allComments }));
    } catch (error) {
      handleCloseSnackbar(true, "success", error);
    }
  };

  if (isLoading) return <AnimatedLoader />;

  return (
    <>
      <AppHeader>
        <SnackbarAlert
          snackbarProps={snackbarProps}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <Divider />

        <>
          <Stack direction={"row"} justifyContent={"center"} mt={2}>
            <SoundWaveGif />
          </Stack>

          <Typography variant={"h1"} className={globalCls.detailH1}>
            {videoInfo?.heading ? videoInfo?.heading : ""}
          </Typography>
          <Container sx={{ maxWidth: "480px !important" }}>
            <Box
              bgcolor={appColor.white}
              borderRadius={"20px"}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
            >
              <StackTemplate stackProps={{ p: "10px" }}>
                <StackTemplate stackProps={{ gap: 1 }}>
                  <Avatar src={youtubeData?.profilePic} alt="Person Image" />
                  <Typography
                    variant={"h6"}
                    fontSize={13}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {youtubeData?.chanelName}
                  </Typography>
                </StackTemplate>
                <Typography
                  variant={"body1"}
                  fontSize={13}
                  fontWeight={600}
                  color={appColor.black}
                  onClick={() => navigate(-1)}
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    textDecorationThickness: "2px",
                    textDecorationColor: "#b3b3b3",
                  }}
                >
                  Modify
                </Typography>
              </StackTemplate>
              <Divider />
              <Box width={"100%"} p={"2px"} minHeight={"200px"}>
                <img
                  src={youtubeData?.thumbnail}
                  width={"100%"}
                  height={"100%"}
                  style={{ borderRadius: "10px" }}
                />
              </Box>
              <StackTemplate
                stackProps={{
                  justifyContent: "flex-start",
                  pl: "12px",
                  gap: 1,
                }}
              >
                <Stack direction={"row"} spacing={"5px"} alignItems={"end"}>
                  <img src={appIcons.commentIcon} width={20} />
                  <Typography
                    variant={"body1"}
                    fontSize={11}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {youtubeData?.commentCount}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={"5px"} alignItems={"end"}>
                  <img src={appIcons.viewIcon} width={20} />
                  <Typography
                    variant={"body1"}
                    fontSize={11}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {youtubeData?.viewCount}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={"5px"} alignItems={"end"}>
                  <img src={appIcons.likeIcon} width={20} />
                  <Typography
                    variant={"body1"}
                    fontSize={11}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {youtubeData?.likeCount}
                  </Typography>
                </Stack>
              </StackTemplate>
              <Stack
                direction={"row"}
                p={"10px"}
                justifyContent={"end"}
                alignItems={"center"}
                spacing={2}
                mt={2}
              >
                <Typography
                  variant={"body1"}
                  fontSize={12}
                  fontWeight={400}
                  color={appColor.black}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Typography>
                <Box width={88} height={30}>
                  <CustomButton
                    btnText={"Comments"}
                    textWeight={400}
                    textSize={10}
                    iconSize={"15px !important"}
                    handleClickBtn={handleCheckLogin}
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
          <Container sx={{ maxWidth: "1200px !important", marginTop: "10px" }}>
            <AppFooter />
          </Container>
        </>
      </AppHeader>
      <SignupDialog
        open={openSignup}
        setOpen={setOpenSignup}
        openLogin={setOpenLogin}
      />
      <LoginDialog
        open={openLogin}
        setOpen={setOpenLogin}
        openSignup={setOpenSignup}
      />
      <BuyPlanModal
        open={openPlanDialog}
        handleClose={handleClosePlanDialog}
        dialogData={videoInfo}
      />
      <CommentWaitDialog
        open={openWaitDialog}
        handleClose={() => setOpenWaitDialog(false)}
      />
    </>
  );
}

export default ChannelSection;
