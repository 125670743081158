import React, { useContext } from "react";
import AppHeader from "./AppHeader";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { appFonts } from "../constants/Fonts";
import { AppContext } from "../context";
import { appGifs } from "../assets";
import { appColor } from "../constants/Colors";

function AnimatedLoader() {
  const { loaderData, getLoaderData } = useContext(AppContext);

  return (
    <Container sx={{ maxWidth: "480px !important" }}>
      <Typography
        variant={"h2"}
        fontSize={28}
        fontWeight={600}
        textAlign={"center"}
        letterSpacing={"3px"}
        mt={8}
        mb={3}
        sx={{ wordSpacing: "6px" }}
        fontFamily={appFonts.boldExtended}
      >
        Loading....
      </Typography>
      <Box>
        <Typography variant={"body1"} fontSize={12} textAlign={"center"}>
          Do not reload the page
        </Typography>
        <Box textAlign={"center"} margin={"10px 0"}>
          <img src={appGifs?.soundWaveGifblue} height={"70px"} />
          <img src={appGifs?.soundWaveGifblue} height={"70px"} />
          <img src={appGifs?.soundWaveGifblue} height={"70px"} />
        </Box>
        <Typography
          variant={"body1"}
          fontSize={13}
          textAlign={"center"}
          mt={15}
        >
          {loaderData?.title}
        </Typography>
        <Stack
          direction={"row"}
          p={"12px"}
          justifyContent={"space-between"}
          alignItems={"center"}
          bgcolor={appColor.white}
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
          mt={3}
          borderRadius={4}
        >
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Avatar src={loaderData?.boxImage} />
            <Stack direction={"column"}>
              <Typography
                variant={"h6"}
                fontSize={14}
                fontWeight={500}
                color={appColor.black}
              >
                {loaderData?.boxHeading}
              </Typography>
              <Typography
                variant={"h6"}
                fontSize={12}
                fontWeight={400}
                color={appColor.black}
              >
                {loaderData?.boxFollowers}followers
              </Typography>
            </Stack>
          </Stack>
          <Typography
            variant={"h6"}
            fontSize={13}
            fontWeight={600}
            color={appColor.black}
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() =>
              window.open(
                "https://www.instagram.com/raffleselectpro/",
                "_blank"
              )
            }
          >
            folllow
          </Typography>
        </Stack>
      </Box>
    </Container>
  );
}

export default AnimatedLoader;
