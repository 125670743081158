import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Avatar,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { appColor } from "../../../constants/Colors";
import CustomButton from "../../../components/CustomButton";
import { appIcons } from "../../../assets";
import { deleteVideoHistory } from "../../../service/authentiction";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
    flexDirection: "row",
    justifyContent: "space-between",
  },
  "& .MuiDialog-paper": {
    width: "450px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function DeleteDialog({
  open,
  setOpen,
  dialogData,
  handleClose,
  setIsLoading,
  handleSnackbarVal,
  getVideoHistory,
}) {
  console.log(dialogData, "jjujjjjjjjjjjjjjjjj");
  const userData = JSON.parse(localStorage.getItem("user"));


  const handleDelete = () => {
    setIsLoading(true);
    deleteVideoHistory(dialogData?._id)
      .then((ress) => {
        setIsLoading(false);
        if (ress?.data?.status == "ok") {
          handleSnackbarVal(true, "success", ress?.data?.message);
          handleClose();
          getVideoHistory(userData?._id);
        } else {
          handleSnackbarVal(true, "error", ress?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        scroll="body"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent style={{ padding: "0px 60px" }}>
          <Typography
            variant={"h6"}
            fontWeight={"800"}
            fontFamily={"Roboto Flex"}
            textAlign={"center"}
            fontSize={30}
            mt={3}
          >
            <span style={{ color: "red", fontSize: "35px" }}>⁉️</span> Delete
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={13}
            fontWeight={400}
            textAlign={"center"}
            fontFamily={"Roboto Flex"}
          >
            Do you really want to delete this Giveaway? This action cannot be
            undone.
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={13}
            fontWeight={400}
            fontFamily={"Roboto Flex"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"5px"}
            mt={2}
          >
            <img src={appIcons.checkIcon} width={16} />I agree to permanently
            delete
          </Typography>
        </DialogContent>
        <Divider sx={{ mt: "20px", mb: "10px" }} />
        <DialogActions>
          <Box width={"160px"} height={40}>
            <CustomButton btnText={"Delete"} handleClickBtn={handleDelete} />
          </Box>
          <Box
            width={"160px"}
            height={40}
            border={"1px solid"}
            borderRadius={"50px"}
          >
            <CustomButton
              btnText={"Cancel"}
              btnBackground={"transparent"}
              textColor={appColor.black}
              handleClickBtn={handleClose}
            />
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
