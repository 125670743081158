import {
  Avatar,
  Box,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { appIcons, appImages } from "../../../../assets";
import AnimatedLoader from "../../../../components/AnimatedLoader";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import BuyPlanModal from "../../../../components/BuyPlan";
import CustomButton from "../../../../components/CustomButton";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import SoundWaveGif from "../../../../components/SoundWaveGif";
import { appColor } from "../../../../constants/Colors";
import { appFonts } from "../../../../constants/Fonts";
import { AppContext } from "../../../../context";
import { setAppFilters } from "../../../../redux/slices/GiveawaySlice";
import {
  getTwitterPostLikes,
  getTwitterPostRetweets,
} from "../../../../service/twitter";
import LoginDialog from "../../../auth/Login";
import SignupDialog from "../../../auth/Signup";
import GlobalStyle from "../../../../globalStyle";
import StackTemplate from "../../../../components/StackTemplate";

function PostStatus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalClass = GlobalStyle();
  const { state: tweetId } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const { tweetData, userData } = useContext(AppContext);
  const [openPlanDialog, setOpenPlanDialog] = useState(false);
  const filterData = useSelector((state) => state?.giveaway?.appFilters);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleClosePlanDialog = () => {
    setOpenPlanDialog(false);
  };

  const handleFetchData = async (apiFunction, stateType) => {
    if (!userData) {
      setOpenSignup(true);
      return;
    }

    if (tweetData?.commentCount === 0) {
      handleSnackbarVal(true, "error", "Opps this post has 0 comments");
      return;
    }

    if (tweetData?.commentCount <= userData?.comments) {
      setIsLoading(true);
      try {
        const response = await apiFunction({ tweetId });
        setIsLoading(false);

        if (response?.data?.status === "ok") {
          const reply = response?.data?.data;
          dispatch(
            setAppFilters({
              ...filterData,
              allComments: reply,
              twitterType: stateType,
            })
          );
          navigate("/twitter-giveaway/comment-filters", {
            state: stateType,
          });
        } else {
          handleSnackbarVal(true, "error", response?.data?.message);
        }
      } catch (error) {
        setIsLoading(false);
        handleSnackbarVal(true, "error", "Something went wrong");
      }
    } else {
      setOpenPlanDialog(true);
    }
  };

  const handleFetchLikes = () => {
    handleFetchData(getTwitterPostLikes, "likes");
  };

  const handleFetchRetweets = () => {
    handleFetchData(getTwitterPostRetweets, "retweets");
  };

  return (
    <AppHeader>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Divider />
      {isLoading ? (
        <AnimatedLoader />
      ) : (
        <>
          <Stack direction={"row"} justifyContent={"center"} mt={2}>
            <SoundWaveGif />
          </Stack>

          <Typography
            variant={"h1"}
            className={globalClass.raDetailH1}
            textAlign={"center"}
          >
            Twitter giveaway Picker
          </Typography>
          <Container sx={{ maxWidth: "550px !important", mt: 4 }}>
            <Box
              bgcolor={appColor.white}
              borderRadius={"20px"}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
            >
              <StackTemplate
                stackProps={{
                  p: "10px",
                }}
              >
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Avatar src={tweetData?.authorProfileImage} alt="Logo" />
                  <Typography
                    variant={"h6"}
                    fontSize={13}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {tweetData?.authorName}
                  </Typography>
                </Stack>
                <Typography
                  variant={"body1"}
                  className={globalClass.raModify}
                  onClick={() => navigate(-1)}
                >
                  Modify
                </Typography>
              </StackTemplate>
              <Box p={"0px 10px 10px 10px"}>
                <Typography
                  variant={"body1"}
                  fontSize={13}
                  fontWeight={400}
                  fontFamily={appFonts?.RobotoFlex}
                >
                  {tweetData?.title}
                </Typography>
              </Box>
              <Divider />
              <Box width={"100%"} p={"2px"} minHeight={"200px"}>
                <img
                  src={
                    tweetData?.thumbnail
                      ? tweetData?.thumbnail
                      : appImages?.NoPostImage
                  }
                  width={"100%"}
                  height={"100%"}
                  style={{ borderRadius: "10px" }}
                />
              </Box>
              <Stack
                mt={1}
                pl={"12px"}
                spacing={1}
                direction={"row"}
                alignItems={"center"}
              >
                <Stack direction={"row"} spacing={"5px"} alignItems={"end"}>
                  <img src={appIcons.commentIcon} width={20} />
                  <Typography
                    variant={"body1"}
                    fontSize={11}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {tweetData ? tweetData?.commentCount : 0}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={"5px"} alignItems={"end"}>
                  <img src={appIcons.viewIcon} width={20} />
                  <Typography
                    variant={"body1"}
                    fontSize={11}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {tweetData ? tweetData?.viewCount : 0}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={"5px"} alignItems={"end"}>
                  <img src={appIcons.likeIcon} width={20} />
                  <Typography
                    variant={"body1"}
                    fontSize={11}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {tweetData ? tweetData?.likeCount : 0}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                p={"8px"}
                justifyContent={"end"}
                alignItems={"center"}
                spacing={2}
                mt={"10px"}
              >
                <Typography
                  variant={"body1"}
                  fontSize={12}
                  fontWeight={400}
                  color={appColor.black}
                  onClick={() => navigate(-1)}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Back
                </Typography>
                <Box width={88} height={30}>
                  <CustomButton
                    btnText={"Retweets"}
                    textWeight={400}
                    textSize={12}
                    iconSize={"15px !important"}
                    handleClickBtn={handleFetchRetweets}
                  />
                </Box>
                <Box width={88} height={30}>
                  <CustomButton
                    btnText={"Likes"}
                    textWeight={400}
                    textSize={12}
                    iconSize={"15px !important"}
                    handleClickBtn={handleFetchLikes}
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
          <Container sx={{ maxWidth: "1200px !important", marginTop: "10px" }}>
            <AppFooter />
          </Container>
        </>
      )}
      <BuyPlanModal open={openPlanDialog} handleClose={handleClosePlanDialog} />
      <SignupDialog
        open={openSignup}
        setOpen={setOpenSignup}
        openLogin={setOpenLogin}
      />
      <LoginDialog
        open={openLogin}
        setOpen={setOpenLogin}
        openSignup={setOpenSignup}
      />
    </AppHeader>
  );
}

export default PostStatus;
