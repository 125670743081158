import { Box, InputAdornment, TextField } from "@mui/material";
import React from "react";
import GlobalStyle from "../globalStyle";
import CustomButton from "./CustomButton";

function UrlInput({ url, setUrl, placeholder, handleClick }) {
  const classes = GlobalStyle();

  return (
    <TextField
      size="small"
      placeholder={placeholder}
      className={classes.inputField}
      value={url}
      onChange={(e) => setUrl(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box height={40} width={90}>
              <CustomButton
                btnText={"start"}
                textWeight={400}
                handleClickBtn={handleClick}
              />
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default UrlInput;
{
}
