import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import AnimatedButton from "./AnimatedButton";
import { appColor } from "../constants/Colors";
import CustomButton from "./CustomButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
    flexDirection: "column",
  },
  "& .MuiDialog-paper": {
    width: "450px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function WelcomeDialog({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        scroll="body"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {" "}
          <Typography
            variant={"h4"}
            fontSize={26}
            fontWeight={700}
            textAlign={"center"}
            mt={4}
            fontFamily={"Roboto Flex"}
          >
            💫 Welcome!!
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent style={{ padding: "0px 20px" }}>
          <Typography
            variant={"body1"}
            fontSize={14}
            fontWeight={400}
            textAlign={"center"}
            // mt={2}
            mb={2}
          >
            your account has been successfully created.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box width={"50%"} height={45}>
            <CustomButton
              btnText={"Welcome"}
              textWeight={500}
              handleClickBtn={() => setOpen(false)}
            />
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
