import React, { useContext, useEffect, useState } from "react";
import AppHeader from "../../../../components/AppHeader";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import Loader from "../../../../components/Loader";
import { useSelector } from "react-redux";
import { appColor } from "../../../../constants/Colors";
import { appIcons, appImages } from "../../../../assets";
import { AppContext } from "../../../../context";
import { fetchCertificate } from "../../../../service/common";

function Certitficate() {
  const [videoData, setVideoData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { win } = useContext(AppContext);
  const filterData = useSelector((state) => state?.history?.filters);
  const [certificateData, setCertificateData] = useState(null);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("videoInfo"));
    setVideoData(data);
    getCertificateData();
  }, []);

  const getCertificateData = () => {
    setIsLoading(true);
    fetchCertificate()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setCertificateData(data);
          console.log(data, "Sdseddferfefjgyusdf");
        } else {
          // handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // handleSnackbarVal(true, "error", err?.message);
      });
  };

  return (
    <AppHeader>
      <Loader isloading={isLoading} />
      <Divider />
      <Container sx={{ maxWidth: "610px !important", mt: "70px" }}>
        <Box
          boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
          borderRadius={"20px"}
          sx={{
            background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE)",
          }}
          overflow={"hidden"}
        >
          <Stack p={"10px"} direction={"column"}>
            <Typography
              variant={"h6"}
              fontSize={18}
              fontWeight={600}
              color={appColor.black}
              textAlign={"center"}
              fontFamily={"Roboto Flex"}
            >
              {/* Certificate of Giveaway 🎊 */}
              {certificateData?.heading}
            </Typography>
          </Stack>
          <Divider />
          <Stack p={"10px"} direction={"column"}>
            <Typography
              variant={"h6"}
              fontSize={16}
              fontWeight={500}
              color={appColor.black}
              fontFamily={"Roboto Flex"}
            >
              {videoData?.title}
            </Typography>
          </Stack>
          <Divider />
          <Stack p={"10px"} direction={"column"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant={"body1"}
                  fontFamily={"Roboto Flex"}
                  fontSize={14}
                  fontWeight={600}
                >
                  Type
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={"6px"}
                  alignItems={"center"}
                  mt={"1px"}
                >
                  <img
                    src={appIcons.youtubeIcon}
                    alt={"Youtube icon"}
                    width={13}
                    height={13}
                  />
                  <Typography
                    variant={"body1"}
                    fontFamily={"Roboto Flex"}
                    fontSize={13}
                    fontWeight={400}
                  >
                    Youtube Giveaway
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant={"body1"}
                  fontFamily={"Roboto Flex"}
                  fontSize={14}
                  fontWeight={600}
                  //   lineHeight={"15px"}
                >
                  Author
                </Typography>

                <Link fontFamily={"Roboto Flex"} fontSize={13} fontWeight={400}>
                  {videoData?.chanelName}
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant={"body1"}
                  fontFamily={"Roboto Flex"}
                  fontSize={14}
                  fontWeight={600}
                >
                  Participants
                </Typography>

                <Typography
                  variant={"body1"}
                  fontFamily={"Roboto Flex"}
                  fontSize={13}
                  fontWeight={400}
                >
                  {filterData?.allVideoComments?.length}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={8} overflow={"hidden"}>
                <Box>
                  <Typography
                    variant={"body1"}
                    fontFamily={"Roboto Flex"}
                    fontSize={14}
                    fontWeight={600}
                    lineHeight={"10px"}
                  >
                    Post
                  </Typography>

                  <Link
                    fontFamily={"Roboto Flex"}
                    fontSize={13}
                    fontWeight={400}
                    onClick={() =>
                      window.open(videoData?.youtubeLink, "_blank")
                    }
                  >
                    {videoData?.youtubeLink}
                  </Link>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  variant={"body1"}
                  fontFamily={"Roboto Flex"}
                  fontSize={14}
                  fontWeight={600}
                >
                  Date
                </Typography>

                <Typography
                  variant={"body1"}
                  fontFamily={"Roboto Flex"}
                  fontSize={13}
                  fontWeight={400}
                >
                  25-08-2023
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Divider />
          {win?.map((dataa) => {
            const topLevelComment = dataa.snippet.topLevelComment;
            const snippet = topLevelComment.snippet;
            const {
              authorProfileImageUrl,
              authorDisplayName,
              textOriginal,
              authorChannelUrl,
            } = snippet;
            return (
              <Stack
                direction={"row"}
                spacing={1.5}
                p={"10px"}
                bgcolor={"#C5D6F1"}
                alignItems={"start"}
                flexWrap={"wrap"}
                sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
              >
                <Box>
                  <Box
                    height={73}
                    width={73}
                    m={"0px auto"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    position={"relative"}
                    mb={"10px"}
                  >
                    <img
                      src={appImages.dottedLine}
                      width={"100%"}
                      // className="rotating-box"
                      style={{ position: "absolute" }}
                    />
                    <Avatar
                      sx={{ height: 65, width: 65, position: "absolute" }}
                      src={authorProfileImageUrl}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    variant={"body1"}
                    fontFamily={"Roboto Flex"}
                    fontSize={16}
                    fontWeight={600}
                  >
                    {authorDisplayName}
                  </Typography>
                  <Typography
                    variant={"body1"}
                    fontFamily={"Roboto Flex"}
                    fontSize={12}
                    fontWeight={400}
                  >
                    {textOriginal}
                  </Typography>
                </Box>
              </Stack>
            );
          })}
          <Stack
            direction={"row"}
            spacing={1}
            p={"10px"}
            bgcolor={appColor.black}
          >
            <img src={appImages.appWhiteLogo} alt={"App logo"} width={80} />

            <Typography
              variant={"body1"}
              color={"#ffffff"}
              fontSize={10}
              m={"0 auto"}
              flexGrow={1}
              textAlign={"center"}
            >
              Certified by dualwire.com
            </Typography>
          </Stack>
        </Box>
      </Container>
    </AppHeader>
  );
}

export default Certitficate;
