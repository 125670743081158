import React, { useContext, useRef, useState } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "./CustomButton";
import { appColor } from "../constants/Colors";
import { appImages } from "../assets";
import { AppContext } from "../context";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
    flexDirection: "column",
  },
  "& .MuiDialog-paper": {
    width: "450px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function NewWinnerProfile({ open, handleClose }) {
  const btnRef = useRef(null);
  const { newWinnerData } = useContext(AppContext);

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        scroll="body"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent style={{ padding: "0px 20px" }}>
          <Box
            height={120}
            width={120}
            m={"30px auto"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            position={"relative"}
            mb={"10px"}
          >
            <img
              src={appImages.dottedLine}
              width={"100%"}
              className="rotating-box"
              style={{ position: "absolute" }}
            />
            <Avatar
              sx={{ height: 100, width: 100, position: "absolute" }}
              src={newWinnerData?.image ? newWinnerData?.image : ""}
            />
          </Box>
          <Typography
            variant={"h6"}
            fontSize={16}
            fontWeight={600}
            textAlign={"center"}
          >
            {newWinnerData?.name ? newWinnerData?.name : ""}
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={12}
            textAlign={"center"}
            mt={1}
          >
            {newWinnerData?.text ? newWinnerData?.text : ""}
          </Typography>
          {/* <Typography
            variant={"body1"}
            fontSize={13}
            textAlign={"center"}
            mt={1}
            color={"#1377F8"}
          >
            #forestwonder #naturemagic #magicofthetrees
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Box width={"100%"} height={40}>
            <CustomButton
              btnText={"Go To Profile"}
              handleClickBtn={() => {
                btnRef.current.click();
                handleClose();
              }}
            />
            <a
              style={{ display: "none" }}
              href={newWinnerData?.authorUrl ? newWinnerData?.authorUrl : ""}
              ref={btnRef}
              target="_blank"
            ></a>
          </Box>
          {/* <Box width={"100%"} height={40} mt={1.5} ml={"0px !important"}>
            <CustomButton btnText={"Go To Comment"} />
          </Box> */}
          {/* <Typography
            variant={"h6"}
            fontSize={15}
            color={"#E62A21"}
            mt={1}
            borderBottom={"2px solid"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(false);
              setOpenReplaceWinner(true);
            }}
          >
            Replace
          </Typography> */}
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
