import React, { useContext, useState } from "react";
import AppHeader from "../../components/AppHeader";
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import SnackbarAlert from "../../components/SnackbarAlert";
import Loader from "../../components/Loader";
import TextLabel from "../../components/TextLabel";
import TextInput from "../../components/TextInput";
import { registerUser, socialRegister } from "../../service/authentiction";
import { AppContext } from "../../context";
import { googleAuth, firebaseAuth } from "../../components/FirebaseConfig";
import { signInWithPopup } from "firebase/auth";
import {
  VisibilityOff as VisibilityOffIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import { appIcons } from "../../assets";
import { useNavigate } from "react-router-dom";
import { appColor } from "../../constants/Colors";

function SignupPage() {
  const navigate = useNavigate();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [hidePassword, setHidePassword] = useState(false);
  const { setUserData } = useContext(AppContext);
  const [openWelcome, setOpenWelcome] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    fName: "",
    lName: "",
    email: "",
    password: "",
  });
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleChangeInput = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignup = () => {
    const { fName, lName, email, password } = inputValues;
    if (!fName) {
      handleSnackbarVal(true, "error", "First name is required");
    } else if (!lName) {
      handleSnackbarVal(true, "error", "Last name is required");
    } else if (!email) {
      handleSnackbarVal(true, "error", "Email is required");
    } else if (!emailRegex.test(email)) {
      handleSnackbarVal(true, "error", "Invalid email format");
    } else if (!password) {
      handleSnackbarVal(true, "error", "Pssword is required");
    } else {
      const payload = {
        firstName: fName,
        lastName: lName,
        email,
        password,
      };
      setIsLoading(true);
      registerUser(payload)
        .then((response) => {
          setIsLoading(false);
          if (response?.data?.status === "ok") {
            let userData = response?.data?.data;
            localStorage.setItem("user", JSON.stringify(userData));
            handleSnackbarVal(true, "success", response?.data?.message);
            setUserData(userData);
            setOpenWelcome(true);
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            handleSnackbarVal(true, "error", response?.data?.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", error?.message);
        });
    }
  };

  const handleSinginWithGoogle = () => {
    signInWithPopup(firebaseAuth, googleAuth)
      .then((result) => {
        console.log(result.user, "junaidddddd");
        let data = result?.user;
        let name = data?.displayName.split(" ");
        let newObj = {
          firstName: name[0],
          lastName: name[1] === undefined ? "" : name[1],
          email: data?.email,
          profileImage: data?.photoURL,
        };

        socialRegister(newObj)
          .then((response) => {
            setIsLoading(false);
            if (response?.data?.status === "ok") {
              let userData = response?.data?.data;
              localStorage.setItem("user", JSON.stringify(userData));
              handleSnackbarVal(true, "success", response?.data?.message);
              setUserData(userData);
              setOpenWelcome(true);
              setTimeout(() => {
                navigate("/");
              }, 2000);
            } else {
              handleSnackbarVal(true, "error", response?.data?.message);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            handleSnackbarVal(true, "error", error?.message);
          });
        console.log(data, "masooddddd");
        // return;
        // localStorage.setItem("user", JSON.stringify(newObj));
        // localStorage.setItem("token", JSON.stringify(data?.accessToken));
        // setOpen(false);
      })
      .catch((error) => {
        console.log(error, "ASDasdasdasdsad");
        // handleSnackbarVal(true, "error", error?.message);
      });
  };

  return (
    <AppHeader>
      <Divider />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Container sx={{ maxWidth: "450px !important", marginTop: "50px" }}>
        <Typography
          variant={"h2"}
          fontSize={26}
          fontWeight={700}
          textAlign={"center"}
          mt={3}
        >
          👋🏻 Join the Fun!!
        </Typography>
        <Typography
          variant={"h2"}
          fontSize={13}
          fontWeight={400}
          textAlign={"center"}
          mt={1}
        >
          Sign Up for Your Shot at Random Wins!!
        </Typography>
        <Grid container spacing={"10px"} mt={2}>
          <Grid item xs={12} sm={6}>
            <TextLabel inputText={"First Name"} />
            <TextInput
              placeholder={"Enter your first name"}
              inputType={"text"}
              inputVal={inputValues.fName}
              inputName={"fName"}
              handleChangeInput={handleChangeInput}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextLabel inputText={"Last Name"} />
            <TextInput
              placeholder={"Enter your last name"}
              inputType={"text"}
              inputVal={inputValues.lName}
              inputName={"lName"}
              handleChangeInput={handleChangeInput}
            />
          </Grid>
          <Grid item xs={12}>
            <TextLabel inputText={"Email"} />
            <TextInput
              placeholder={"Enter your email"}
              inputType={"email"}
              inputVal={inputValues.email}
              inputName={"email"}
              handleChangeInput={handleChangeInput}
            />
          </Grid>
          <Grid item xs={12}>
            <TextLabel inputText={"Password"} />
            <TextInput
              placeholder={"Enter your password"}
              inputType={hidePassword ? "text" : "password"}
              inputVal={inputValues.password}
              inputName={"password"}
              handleChangeInput={handleChangeInput}
              endIcon={
                hidePassword ? (
                  <RemoveRedEyeIcon
                    sx={{ color: "#C9CCD1", mr: "10px", cursor: "pointer" }}
                    onClick={() => setHidePassword(false)}
                  />
                ) : (
                  <VisibilityOffIcon
                    sx={{ color: "#C9CCD1", mr: "10px", cursor: "pointer" }}
                    onClick={() => setHidePassword(true)}
                  />
                )
              }
            />
          </Grid>
        </Grid>
        <Box width={"100%"} height={45} mt={2}>
          {/* <AnimatedButton
              buttonInput={"Create Account"}
              btnBackground={appColor.black}
              borderRadius={50}
              textSize={12}
              textColor={appColor.white}
              hoverProperties={{
                border: "none",
              }}
              handleClickBtn={()=>{
                setOpen(false)
                setOpenWelcome(true)
              }}
            /> */}
          <CustomButton
            btnText={"Create Account"}
            textWeight={500}
            handleClickBtn={handleSignup}
          />
        </Box>
        <Stack
          direction={"row"}
          mt={2.5}
          flexWrap={"wrap"}
          useFlexGap
          width={"100%"}
          justifyContent={"center"}
          spacing={2}
          height={"45px"}
        >
          <Stack
            direction={"row"}
            // p={1}
            spacing={1}
            border={"1px solid #C9CCD1"}
            borderRadius={"10px"}
            sx={{ cursor: "pointer", width: { xs: "100%", md: "45%" } }}
            justifyContent={"center"}
            alignItems={"center"}
            onClick={handleSinginWithGoogle}
          >
            <img src={appIcons.googleIcon} width={"20px"} />
            <Typography variant={"body1"} fontSize={12}>
              Sign up with Google
            </Typography>
          </Stack>
          {/* <Stack
                direction={"row"}
                p={1}
                spacing={1}
                border={"1px solid #C9CCD1"}
                borderRadius={"10px"}
                sx={{ cursor: "pointer", width: { xs: "100%", md: "45%" } }}
                justifyContent={"center"}
                alignItems={"center"}
                height={"45px"}
              >
                <img src={appIcons.facebookIcon} width={"20px"} />
                <Typography variant={"body1"} fontSize={12}>
                  Sign up with Facebook
                </Typography>
              </Stack> */}
        </Stack>
        <Typography variant={"body1"} fontSize={13} mt={2} textAlign={"center"}>
          Already have an account?
          <Link
            sx={{
              ml: "3px",
              color: appColor.black,
              fontWeight: 500,
              textDecorationColor: appColor.black,
              textDecorationThickness: "2px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/login")}
          >
            Login
          </Link>
        </Typography>
      </Container>
    </AppHeader>
  );
}

export default SignupPage;
