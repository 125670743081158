import React, { useContext, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  CssBaseline,
  Drawer,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { appColor } from "../constants/Colors";
import { appIcons, appImages, appLogo } from "../assets";
import AnimatedButton from "./AnimatedButton";
import LoginDialog from "../containers/auth/Login";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import CustomButton from "./CustomButton";
import { AppContext } from "../context";
import SignupDialog from "../containers/auth/Signup";
import ImageComponent from "./ImgeComponent";
import ImageUploader from "./ImgeComponent";
import AppFooter from "./AppFooter";
import CasinoIcon from "@mui/icons-material/Casino";
import MoneyIcon from "@mui/icons-material/Money";

const drawerWidth = 250;

export default function AppHeader(props) {
  const { window } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userData, setUserData, profileImage } = useContext(AppContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const open = Boolean(anchorEl);

  // for open menu on game tab
  const gameMenuRef = React.useRef(null);
  const [openGameMenu, setOpenGameMenu] = React.useState(false);

  const handleToggleGameMenu = () => {
    setOpenGameMenu((prevOpen) => !prevOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navItems = [
    { text: "Home", action: "/" },
    { text: "Dashboard", action: "/dashboard" },
    { text: "Pricing", action: "/shop" },
    { text: "My Account", action: "/myAccount" },
    { text: "Games", action: "/flip-a-coin" },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box>
      <CloseIcon
        style={{
          position: "absolute",
          right: "10px",
          top: "15px",
          background: "#fff",
          borderRadius: "50%",
          padding: "4px",
          fontSize: "30px",
          zIndex: 999,
          border: "1px solid #0000002b",
        }}
        onClick={handleDrawerToggle}
      />

      <Box display={"flex"} alignItems={"center"} gap={"5px"} p={1}>
        <Avatar
          src={user?.profileImage ? user?.profileImage : appImages.emojiAvatar}
          alt={"Emoji"}
          sx={{ height: 40, width: 40 }}
        />
        <Typography
          variant={"h6"}
          fontSize={12}
          color={appColor.black}
          sx={{ textDecoration: "underline" }}
          onClick={() => navigate("/shop")}
        >
          Upgrade
        </Typography>
        <Typography variant={"h6"} fontSize={13} color={appColor.black}>
          ⚡️
        </Typography>
      </Box>

      <List>
        {navItems.map(
          (item) => (
            // (user ||
            //   (item.action !== "/dashboard" && item.action !== "/shop")) && (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => navigate(item.action)}
                sx={{
                  backgroundColor:
                    location.pathname === item?.action ? "#f3f3f3" : "#ffff",
                }}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          )
          // )
        )}
        {user ? (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                localStorage.setItem("user", JSON.stringify(null));
                setUserData(null);
                navigate("/");
                handleDrawerToggle();
              }}
            >
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setOpenLogin(true);
                handleDrawerToggle();
              }}
            >
              <ListItemText primary={"Login"} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleCloseGameMenu = (event) => {
    if (gameMenuRef.current && gameMenuRef.current.contains(event.target)) {
      return;
    }

    setOpenGameMenu(false);
  };

  function handleListKeyDown(event) {
    console.log(event.key, "sdasjhdasdjhasdjhasdasjhd");
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenGameMenu(false);
    } else if (event.key === "Escape") {
      setOpenGameMenu(false);
    }
  }

  return (
    <>
      <Stack
        height={30}
        bgcolor={appColor.black}
        justifyContent={"center"}
        alignItems={"center"}
        direction={"row"}
        position={"relative"}
        zIndex={999}
      >
        <Typography>✨</Typography>
        <Typography
          variant={"h6"}
          fontSize={12}
          fontWeight={400}
          color={appColor.white}
        >
          Unlock the Excitement: Win Big with Our
        </Typography>
        <p className="animated-text">Raffles & Giveaways!!</p>
      </Stack>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          position="absolute"
          sx={{
            background: appColor.white,
            boxShadow: "none",
            top: { xs: "0px", sm: "30px" },
          }}
        >
          <Toolbar
            sx={{
              padding: { xs: "0px 10px", md: "0px 40px", lg: "0px 80px" },
              // minHeight: 80,
              flexDirection: "row-reverse",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ margin: "0 3px", display: { lg: "none" } }}
            >
              <MenuIcon sx={{ color: appColor.black }} />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: { xs: "flex-start", lg: "space-between" },
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "175px",
                }}
              >
                <img
                  src={appLogo.rafleLogo}
                  style={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/")}
                />
              </Box>
              <Box
                sx={{
                  display: { xs: "none", lg: "flex" },
                  gap: "20px",
                  marginLeft: "25px",
                }}
              >
                <Typography
                  onClick={() => navigate("/")}
                  className={
                    location.pathname == "/dashboard" ||
                    location.pathname == "/shop" ||
                    location.pathname == "/myAccount" ||
                    location.pathname == "/games/flip-a-coin" ||
                    location.pathname == "/games/roll-dice"
                      ? "not-active-tabbb"
                      : "active-tabbbb"
                  }
                >
                  Home
                </Typography>
                {/* {user ? ( */}
                <Typography
                  onClick={() => navigate("/dashboard")}
                  className={
                    location.pathname == "/dashboard"
                      ? "active-tabbbb"
                      : "not-active-tabbb"
                  }
                >
                  Dashboard
                </Typography>
                {/* ) : null} */}
                <Typography
                  onClick={() => navigate("/shop")}
                  className={
                    location.pathname == "/shop"
                      ? "active-tabbbb"
                      : "not-active-tabbb"
                  }
                >
                  Pricing
                </Typography>
                {/* {user ? ( */}
                <Typography
                  onClick={() => navigate("/myAccount")}
                  className={
                    location.pathname == "/myAccount"
                      ? "active-tabbbb"
                      : "not-active-tabbb"
                  }
                >
                  My Account
                </Typography>
                {/* <Typography
                  // onClick={() => navigate("/flip-a-coin")}
                  ref={gameMenuRef}
                  onClick={handleToggleGameMenu}
                  className={
                    location.pathname == "/flip-a-coin"
                      ? "active-tabbbb"
                      : "not-active-tabbb"
                  }
                >
                  Games
                </Typography> */}
                <div>
                  <Typography
                    ref={gameMenuRef}
                    className={
                      location.pathname == "/games/flip-a-coin" ||
                      location.pathname == "/games/roll-dice"
                        ? "active-tabbbb"
                        : "not-active-tabbb"
                    }
                    // id="composition-button"
                    // aria-controls={
                    //   openGameMenu ? "composition-menu" : undefined
                    // }
                    // aria-expanded={openGameMenu ? "true" : undefined}
                    // aria-haspopup="true"
                    onClick={handleToggleGameMenu}
                  >
                    Games
                  </Typography>
                  <Popper
                    open={openGameMenu}
                    anchorEl={gameMenuRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    sx={{ mt: 1 }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleCloseGameMenu}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                sx={{ fontSize: 14 }}
                                onClick={(e) => {
                                  navigate("/games/flip-a-coin");
                                  handleCloseGameMenu(e);
                                }}
                              >
                                <ListItemIcon
                                  sx={{ minWidth: "25px !important" }}
                                >
                                  <MoneyIcon
                                    sx={{ fontSize: 20 }}
                                    htmlColor="grey"
                                  />
                                </ListItemIcon>
                                Flip a Coin
                              </MenuItem>
                              <MenuItem
                                sx={{ fontSize: 14 }}
                                onClick={(e) => {
                                  navigate("/games/roll-dice");
                                  handleCloseGameMenu(e);
                                }}
                              >
                                <ListItemIcon
                                  sx={{ minWidth: "25px !important" }}
                                >
                                  <CasinoIcon
                                    sx={{ fontSize: 20 }}
                                    htmlColor="purple"
                                  />
                                </ListItemIcon>
                                Roll Dice
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
                {/* ) : null} */}
              </Box>

              {user ? (
                <Box
                  sx={{ display: { xs: "none", lg: "flex" } }}
                  alignItems={"center"}
                  gap={"5px"}
                >
                  <Avatar
                    src={appIcons.americanFlag}
                    alt={"flag"}
                    sx={{ height: 35, width: 35 }}
                  />
                  <Typography
                    variant={"h6"}
                    fontSize={13}
                    color={appColor.black}
                    mr={2}
                  >
                    ENG
                  </Typography>
                  <Typography
                    variant={"h6"}
                    fontSize={13}
                    color={appColor.black}
                  >
                    ⚡️
                  </Typography>
                  <Typography
                    variant={"h6"}
                    fontSize={12}
                    color={appColor.black}
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => navigate("/shop")}
                  >
                    Upgrade
                  </Typography>

                  <ImageUploader
                    picture={
                      user?.profileImage
                        ? user?.profileImage
                        : appImages.emojiAvatar
                    }
                    alt={"Emoji"}
                  />
                  <div>
                    <IconButton
                      disableRipple={true}
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      sx={{
                        padding: "0px !important",
                        "&:hover": {
                          backgroundColor: "#fff",
                        },
                      }}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                    <Menu
                      disableScrollLock={true}
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          localStorage.setItem("user", JSON.stringify(null));
                          setUserData(null);
                          navigate("/");
                          handleClose();
                        }}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </Box>
              ) : (
                <Box
                  sx={{ display: { xs: "none", lg: "flex" } }}
                  alignItems={"center"}
                  gap={"5px"}
                >
                  <Avatar
                    src={appIcons.americanFlag}
                    alt={"flag"}
                    sx={{ height: 35, width: 35 }}
                  />
                  <Typography
                    variant={"h6"}
                    fontSize={13}
                    color={appColor.black}
                    mr={2}
                  >
                    ENG
                  </Typography>
                  <Box width={120} height={40}>
                    <CustomButton
                      btnText={"login"}
                      textWeight={500}
                      handleClickBtn={() => navigate("/login")}
                    />
                  </Box>
                </Box>
              )}
            </Typography>
          </Toolbar>
        </AppBar>

        <Box component="nav">
          <Drawer
            // container={container}
            anchor="right"
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            padding: { xs: "2px 0px 0px 0px", md: "32px 0px 0px 0px" },
            width: "100%",
            // minHeight: "95vh",
            // background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
          }}
        >
          <Box sx={{ marginTop: { xs: "40px", sm: "40px" } }}></Box>
          {props.children}
        </Box>
        <LoginDialog
          open={openLogin}
          setOpen={setOpenLogin}
          openSignup={setOpenSignup}
        />
        <SignupDialog
          open={openSignup}
          setOpen={setOpenSignup}
          openLogin={setOpenLogin}
        />
      </Box>
    </>
  );
}
