export const requestType = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

export const endPoints = {
  // authentication
  register: "registerUser",
  login: "loginUser",
  updateUserProfile: "updateUserProfile",
  changePassword: "changePassword",
  sendForgotPasswordCode: "sendForgotPasswordCode",
  forgotPassword: "forgotPassword",
  getSettings: "getSettings",
  sendForgotPasswordCode: "sendForgotPasswordCode",
  socialLogin: "socialLogin",
  changePassword: "changePassword",
  deleteUserAccount: "deleteUserAccount",
  updateSteps: "update-steps",

  //getcomments
  getVideoInfo: "getVideoInfo",
  ytbackgroundComments: "youtube/backgound",
  getYoutbeComments: "youtube/comments",
  saveVideoData: "saveVideoData",
  getVideoHistoryById: "getVideoHistoryById",
  deleteVideoHistory: "deleteVideoHistory",
  updateVideoHistory: "updateVideoHistory",
  checkExistComment: "exists-comments",
  saveYoutubeBgData: "youtube/saveData",

  //home
  getHome: "getHome",
  getVideoInfo: "getVideoInfo",
  getSetting: "getSetting",
  getLoader: "getLoader",
  getCongratulation: "getCongratulation",
  getWinner: "getWinner",
  getCertificate: "getCertificate",

  // shop
  getPackages: "getPackages",
  upgradePlan: "upgradePlan",
  getPlansHeading: "getPlansHeading",

  //stripe
  createStripesession: "create-session-checkout",

  //privacy
  getPrivacy: "getPrivacy",

  //twiiter post info
  getTwitterPostInfo: "getTwitterPostInfo",
  fetchTwitterReply: "fetch-comments",
  getTwitterPostLikes: "getPostLikes",
  getTwitterPostRetweets: "getPostRetweets",

  //instagram
  getInstagramPostByUserName: "getInstagramPostByUserName",
  fetchSinglePost: "instagram/fetchSinglePost",
  fetchIntaComments: "instagram/fetchComments",

  //help center
  getAllHelp: "getAllHelp",

  //promo
  getPromo: "getPromo",

  //tiktok
  getTikTokVideoData: "tiktok/getVideoData",
  getTikTokVideComments: "tiktok/get-comments",
};
