import { createSlice } from "@reduxjs/toolkit";

const giveawaySlice = createSlice({
  name: "giveawaySlice",
  initialState: {
    appFilters: {
      giveawayType: "",
      dublicate: false,
      hashtag: false,
      block: false,
      extraChance: false,
      extraChanceNames: [],
      blockListNames: [],
      hashtagText: "",
      counter: 5,
      totalWinners: 1,
      totalSubstitutes: 0,
      actionType: "add",
      allComments: [],
      currentColor: "#ffffff",
      giveAwayId: "",
      twitterType: "",
    },
  },

  reducers: {
    setAppFilters: (state, action) => {
      state.appFilters = action.payload;
    },
  },
});

export const { setAppFilters } = giveawaySlice.actions;
export default giveawaySlice.reducer;
