import CloseIcon from "@mui/icons-material/Close";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import copy from "copy-to-clipboard";
import React, { useContext } from "react";
import CustomButton from "../../../../components/CustomButton";
import { appColor } from "../../../../constants/Colors";
import { AppContext } from "../../../../context";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
    flexDirection: "row",
    justifyContent: "center",
  },
  "& .MuiDialog-paper": {
    width: "450px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CopyPostDialog({
  open,
  handleClose,
  handleSnackbarVal,
}) {
  const { win } = useContext(AppContext);

  const handleCopyToClip = () => {
    if (win?.length > 0) {
      let newArr = win?.map((dataa, index) => {
        return `${index + 1}: ` + dataa?.name;
      });
      copy(
        "--- GIVEAWAY ENDED --- The winners are:\n" +
          newArr.join("\n") +
          "\nCertified by Raffle Select (Code pidp8s6R)"
      );
      handleSnackbarVal(true, "success", "Post copied");
      handleClose();
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        scroll="body"
        aria-labelledby="customized-dialog-title"
        open={open}
        disableScrollLock={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            variant={"h6"}
            fontWeight={"800"}
            fontFamily={"Roboto Flex"}
            textAlign={"center"}
            fontSize={25}
            mt={2}
          >
            💖 Communicate the winners
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={14}
            fontWeight={400}
            textAlign={"center"}
            fontFamily={"Roboto Flex"}
          >
            Copy and paste the comments on your social networks
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent style={{ padding: "0px 20px" }}>
          <Box
            border={"1px solid gray"}
            borderRadius={"10px"}
            padding={"10px 15px"}
            maxHeight={"300px"}
            overflow={"auto"}
          >
            <Typography variant="body1" fontSize={14} mb={1}>
              --- GIVEAWAY ENDED --- The winners are:
            </Typography>
            {win.map((itemmm, index) => {
              return (
                <Typography variant="body1" fontSize={13}>
                  {index + 1}. {itemmm?.name}
                </Typography>
              );
            })}
            <Typography variant="body1" fontSize={14} mt={1}>
              Certified by Raffle Select (Code pidp8s6R)
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box width={"140px"} height={40}>
            <CustomButton
              startIcon={
                <ContentCopyRoundedIcon
                  sx={{ position: "relative", zIndex: 1111 }}
                />
              }
              btnText={"Copy"}
              handleClickBtn={handleCopyToClip}
            />
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
