import {
  Avatar,
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { AppContext } from "../../../../context";
import { appColor } from "../../../../constants/Colors";
import { appIcons, appImages } from "../../../../assets";
import StackTemplate from "../../../../components/StackTemplate";
import GlobalStyle from "../../../../globalStyle";
import "./style.css";
import CustomButton from "../../../../components/CustomButton";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
    backgroundColor: "#1B1D28",
    textAlign: "center",
    padding: "3px 5px",
    fontSize: 10,
    fontWeight: 300,
  },
}));

const getColorStyle = (currentColor, appColor) => {
  return currentColor == "#161616" ||
    currentColor == "#5A663E" ||
    currentColor == "#798952"
    ? "#fff"
    : appColor.black;
};

function ShowComments({
  heading4,
  isLoading,
  handleReloadComments,
  handleFetchMoreComments,
  hasMoreComments,
}) {
  const globalClass = GlobalStyle();

  const { currentColor, youtubeData, isExist } = useContext(AppContext);
  const { allComments } = useSelector((state) => state?.giveaway?.appFilters);

  console.log(hasMoreComments, isExist,allComments.length, "sdjasdjhsjhasjhd");

  const commentList = useMemo(() => {
    if (allComments?.length <= 0) {
      return (
        <Box textAlign={"center"}>
          <img src={appImages?.noComments} width={"50%"} />
        </Box>
      );
    }

    return allComments?.map((dataa) => {
      return (
        <Stack
          key={dataa.id}
          direction={"row"}
          alignItems={"start"}
          spacing={1}
          borderBottom={`1px solid #C9CCD1`}
          paddingBottom={"8px"}
          mt={1}
        >
          <Avatar src={dataa?.image} sx={{ height: 36, width: 36 }} />
          <Stack direction={"column"}>
            <Typography
              variant={"h6"}
              fontSize={13}
              lineHeight={"normal"}
              fontWeight={600}
              color={getColorStyle(currentColor, appColor)}
            >
              {dataa?.name}
            </Typography>
            <Typography
              variant={"body1"}
              fontSize={11}
              lineHeight={"normal"}
              color={getColorStyle(currentColor, appColor)}
            >
              {dataa?.text}
            </Typography>
          </Stack>
        </Stack>
      );
    });
  }, [allComments]);

  return (
    <Box
      bgcolor={currentColor}
      className={globalClass.setMainCon}
      overflow={"hidden"}
    >
      <StackTemplate stackProps={{ p: "12px", spacing: 1 }}>
        <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
          <Typography
            variant={"h1"}
            className={globalClass.settingHeader}
            color={getColorStyle(currentColor, appColor)}
          >
            {heading4}
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={10}
            fontWeight={400}
            color={getColorStyle(currentColor, appColor)}
          >
            {/* (Total {allComments?.length}) */}
            (Total {youtubeData?.commentCount})
          </Typography>
        </Stack>
        {allComments?.length > 0 && (
          <BootstrapTooltip title={"Reload comments"}>
            <img
              src={appIcons.refreshIcon}
              alt={"Refresh Icon"}
              width={15}
              onClick={handleReloadComments}
              style={{ cursor: "pointer" }}
              className={isLoading ? "rotate-reload" : ""}
            />
          </BootstrapTooltip>
        )}
      </StackTemplate>
      <Divider />
      <Box p={"12px"} maxHeight={"350px"} overflow={"auto"}>
        {commentList}
      </Box>
      {isExist && hasMoreComments && (
        <Box height={35}>
          <CustomButton
            btnText={"Load More comments"}
            handleClickBtn={handleFetchMoreComments}
            borderRadius={"0px"}
          />
        </Box>
      )}
    </Box>
  );
}

export default ShowComments;
