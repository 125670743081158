import React, { useContext } from "react";
import { appColor } from "../constants/Colors";
import { Box, Typography } from "@mui/material";
import { AppContext } from "../context";

function Switch({ value, setValue, checkActive, handleSnackbarVal }) {
  const { currentColor } = useContext(AppContext);
  console.log(currentColor, "checlllllAvtiveeee");

  const checkBgColor = () => {
    if (currentColor == "#ffffff") {
      return appColor.cyanBlue;
    } else if (
      currentColor == "#161616" ||
      currentColor == "#5A663E" ||
      currentColor == "#798952"
    ) {
      return "#fff";
    } else {
      return appColor.black;
    }
  };

  return (
    <Box
      border={`1px solid ${
        currentColor == "#161616" ||
        currentColor == "#5A663E" ||
        currentColor == "#798952"
          ? "#C9CCD1"
          : appColor.black
      }`}
      borderRadius={"50px"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      p={"4px 8px"}
      width={70}
      position={"relative"}
      sx={{
        background: value
          ? "-webkit-linear-gradient(180deg, #ffda8d, #e62a21, #8121a3)"
          : appColor?.white,
      }}
    >
      <Box
        height={20}
        width={20}
        borderRadius={"50%"}
        // bgcolor={checkBgColor()}
        position={"absolute"}
        top={4}
        left={value ? "44px" : 5}
        sx={{
          cursor: "pointer",
          transition: "left 0.4s ease",
          background: !value
            ? "-webkit-linear-gradient(180deg, #ffda8d, #e62a21, #8121a3)"
            : appColor?.white,
        }}
        onClick={() => {
          if (checkActive) {
            setValue(!value);
          } else {
            handleSnackbarVal(
              true,
              "error",
              "Upgrade your plan to use this feature"
            );
          }
        }}
      ></Box>

      <Typography
        variant={"body1"}
        fontSize={13}
        // color={
        //   currentColor == "#161616" ||
        //   currentColor == "#5A663E" ||
        //   currentColor == "#798952"
        //     ? "#C9CCD1"
        //     : appColor.black
        // }
        textTransform={"capitalize"}
        visibility={value ? "visible" : "hidden"}
        color={value ? "#ffffff" : "#000000"}
      >
        Yes
      </Typography>

      <Typography
        variant={"body1"}
        fontSize={13}
        // color={
        //   currentColor == "#161616" ||
        //   currentColor == "#5A663E" ||
        //   currentColor == "#798952"
        //     ? "#C9CCD1"
        //     : appColor.black
        // }
        color={"#000000"}
        textTransform={"capitalize"}
        visibility={value ? "hidden" : "visible"}
      >
        No
      </Typography>
    </Box>
  );
}

export default Switch;
