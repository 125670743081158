import React, { useEffect, useState } from "react";
import AppHeader from "../../../components/AppHeader";
import SnackbarAlert from "../../../components/SnackbarAlert";
import Loader from "../../../components/Loader";
import { Box, Container, Divider, Typography } from "@mui/material";
import SocialCards from "./SocialCards";
import { appImages } from "../../../assets";
import AppFooter from "../../../components/AppFooter";
import { appFonts } from "../../../constants/Fonts";
import axios from "axios";

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchComments = async () => {
  //     try {
  //       const API_KEY = "AIzaSyBfG5TsICtyKC08-ntCHH9UhNiFL9B56JE";
  //       let nextPageToken = "";
  //       const allComments = [];

  //       while (nextPageToken !== undefined) {
  //         const response = await axios.get(
  //           `https://www.googleapis.com/youtube/v3/commentThreads`,
  //           {
  //             params: {
  //               part: "snippet",
  //               videoId: "zZasH6qkn8M",
  //               textFormat: "plainText",
  //               maxResults: 100,
  //               pageToken: nextPageToken,
  //               key: API_KEY,
  //             },
  //           }
  //         );

  //         const data = response.data;
  //         allComments.push(
  //           ...data.items.map(
  //             (item) => item.snippet.topLevelComment.snippet.textDisplay
  //           )
  //         );
  //         nextPageToken = data.nextPageToken;
  //       }

  //       setComments(allComments);
  //     } catch (error) {
  //       setError(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchComments();
  // }, []);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
        minHeight: "100vh",
      }}
    >
      <AppHeader>
        <Divider />
        <SnackbarAlert
          snackbarProps={snackbarProps}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <Loader isloading={isLoading} />
        <Box
          position={"absolute"}
          right={80}
          top={200}
          width={90}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <img src={appImages?.homeGraphic1} width={"100%"} />
        </Box>
        <Box
          position={"absolute"}
          left={10}
          bottom={20}
          width={50}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <img src={appImages?.homeGraphic2} width={"100%"} />
        </Box>
        <Container sx={{ maxWidth: "1250px !important", marginTop: "35px" }}>
          <Box>
            <Typography
              variant="h1"
              fontFamily={"BoldExtended"}
              textAlign={"center"}
              textTransform={"capitalize"}
              sx={{
                fontSize: { xs: "22px", sm: "30px", md: "35px", lg: "40px" },
                letterSpacing: { xs: "2px", sm: "4px" },
              }}
            >
              Start creating your
            </Typography>
            <Typography
              variant="h1"
              fontFamily={appFonts?.boldExtended}
              textAlign={"center"}
              textTransform={"capitalize"}
              marginTop={"8px"}
              sx={{
                fontSize: { xs: "22px", sm: "30px", md: "35px", lg: "40px" },
                letterSpacing: { xs: "2px", sm: "4px" },
              }}
            >
              <span className="gradient-text">Giveaway</span> today.
            </Typography>
            <Typography
              variant={"h3"}
              fontFamily={"Roboto Flex"}
              fontSize={"14px"}
              textAlign={"center"}
              m={"10px 0"}
            >
              Please make your selection from the available contests that you'd
              like to host.
            </Typography>
            <SocialCards />
          </Box>
        </Container>
      </AppHeader>

      <Container sx={{ maxWidth: "1200px !important", marginTop: "10px" }}>
        <AppFooter />
      </Container>
    </Box>
  );
}

export default Home;
