import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { appColor } from "../../../constants/Colors";
import TextInput from "../../../components/TextInput";
import { AppContext } from "../../../context";
import ImageUploader from "../../../components/ImgeComponent";
import { appImages } from "../../../assets";
import CustomButton from "../../../components/CustomButton";
import {
  changePassword,
  deleteAccount,
  updateUserProfile,
} from "../../../service/authentiction";
import axios from "axios";
import DeleteAlert from "../../../components/DeleteAlert";
import { useNavigate } from "react-router-dom";

const InputLabels = ({ text }) => {
  return (
    <Typography
      variant={"body1"}
      fontFamily={"Roboto Flex"}
      color={appColor.black}
      fontSize={13}
    >
      {text}
    </Typography>
  );
};

const BoxHeaders = ({ text }) => {
  return (
    <Box
      direction={"row"}
      p={"12px"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography
        variant={"h6"}
        fontSize={16}
        fontWeight={700}
        color={appColor.black}
        fontFamily={"Roboto Flex"}
      >
        {text}
      </Typography>
    </Box>
  );
};

function Preferences({ handleSnackbarVal, setIsLoading }) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const imgRef = useRef(null);
  const navigate = useNavigate();
  const [apiKey, setApiKey] = useState(
    "dw_25f45sdf4e5bfd158asd1dsg45asde8411cdb"
  );
  const { userData, setImageLoaing, setUserData } = useContext(AppContext);
  const [profileImage, setProfileImage] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    language: "",
    serverLocation: "",
  });
  const [resetPassword, setResePassword] = useState({
    password: "",
    newPassword: "",
  });

  useEffect(() => {
    setUserProfile({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      password: "***********",
      language: "Eng",
      serverLocation: "USA",
    });
    setProfileImage(userData?.profileImage);
  }, [userData]);

  const handleChangeProfile = (e) => {
    const { name, value } = e.target;
    setUserProfile({
      ...userProfile,
      [name]: value,
    });
  };

  const handleResetPassword = (e) => {
    const { name, value } = e.target;
    setResePassword((preeeee) => ({
      ...preeeee,
      [name]: value,
    }));
  };

  const handleChangePassword = () => {
    const { password, newPassword } = resetPassword;
    if (!password) {
      handleSnackbarVal(true, "error", "Current password is required");
    } else if (!newPassword) {
      handleSnackbarVal(true, "error", "New password is required");
    } else {
      let payload = {
        email: userData?.email,
        password,
        newPassword,
      };
      setIsLoading(true);
      changePassword(payload)
        .then((ress) => {
          setIsLoading(false);
          if (ress?.data?.status == "ok") {
            handleSnackbarVal(true, "success", ress?.data?.message);
            setResePassword({
              password: "",
              newPassword: "",
            });
          } else {
            handleSnackbarVal(true, "error", ress?.data?.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", err?.message);
        });
    }
  };

  const handleImageChange = (event) => {
    setImageLoaing(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("upload_preset", "b5kg6fiz");
    formData.append("cloud_name", "dx5ph9sov");
    axios({
      method: "post",
      url: "https://api.cloudinary.com/v1_1/dx5ph9sov/upload",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setProfileImage(res?.data?.url);
        setImageLoaing(false);
        handleSnackbarVal(true, "success", "Image uploaded successfully");
      })
      .catch((error) => {
        setImageLoaing(false);
        handleSnackbarVal(true, "error", error?.message);
      });
  };

  const handleUpdateProfile = () => {
    const { firstName, lastName, email } = userProfile;
    if (!firstName) {
      handleSnackbarVal(true, "error", "First name is required");
    } else if (!lastName) {
      handleSnackbarVal(true, "error", "Last name is required");
    } else if (!email) {
      handleSnackbarVal(true, "error", "Email is required");
    } else if (!emailRegex.test(email)) {
      handleSnackbarVal(true, "error", "Invalid email format");
    } else {
      let payload = {
        firstName,
        lastName,
        email,
        profileImage: profileImage ? profileImage : "",
      };
      setIsLoading(true);
      updateUserProfile(userData?._id, payload)
        .then((ress) => {
          setIsLoading(false);
          if (ress?.data?.status === "ok") {
            let userData = ress?.data?.data;
            localStorage.setItem("user", JSON.stringify(userData));
            handleSnackbarVal(true, "success", ress?.data?.message);
            setUserData(userData);
          } else {
            handleSnackbarVal(true, "error", ress?.data?.message);
          }
        })
        .catch((err) => {
          handleSnackbarVal(true, "error", err?.message);
          setIsLoading(false);
        });
    }
  };

  const handleDeleteAccount = () => {
    setIsLoading(true);
    deleteAccount(userData?._id)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          handleSnackbarVal(true, "success", res?.data?.message);
          localStorage.setItem("user", JSON.stringify(null));
          setUserData(null);
          navigate("/");
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        handleSnackbarVal(true, "error", err?.message);
        setIsLoading(false);
      });
  };

  const handleCloseDialog = () => {
    setOpenDelete(false);
  };

  return (
    <Box
      sx={{
        margin: { xs: "30px 5px", md: "30px 20px" },
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Box
            bgcolor={appColor.white}
            borderRadius={"20px"}
            boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
          >
            <BoxHeaders text={"My Profile 👤"} />
            <Divider />
            <Box sx={{ padding: { xs: "12px", md: "12px 20px" } }}>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12} md={3}>
                  <InputLabels text={"First Name"} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextInput
                    paddingCustom={"8.5"}
                    inputName={"firstName"}
                    inputVal={userProfile?.firstName}
                    handleChangeInput={handleChangeProfile}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputLabels text={"Last Name"} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextInput
                    paddingCustom={"8.5"}
                    inputName={"lastName"}
                    inputVal={userProfile?.lastName}
                    handleChangeInput={handleChangeProfile}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={3}>
                  <InputLabels text={"Email Address"} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextInput
                    onlyRead={true}
                    paddingCustom={"8.5"}
                    inputName={"email"}
                    inputVal={userProfile?.email}
                    handleChangeInput={handleChangeProfile}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={3}>
                  <InputLabels text={"Password"} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextInput
                    paddingCustom={"8.5"}
                    inputName={"password"}
                    inputVal={userProfile?.password}
                    handleChangeInput={handleChangeProfile}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={3}>
                  <InputLabels text={"Language"} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextInput
                    paddingCustom={"8.5"}
                    inputName={"language"}
                    inputVal={userProfile?.language}
                    handleChangeInput={handleChangeProfile}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={3}>
                  <InputLabels text={"Server Location"} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextInput
                    paddingCustom={"8.5"}
                    inputName={"serverLocation"}
                    inputVal={userProfile?.serverLocation}
                    handleChangeInput={handleChangeProfile}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={3}>
                  <InputLabels text={"Profile Picture"} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
                    <ImageUploader
                      picture={
                        profileImage ? profileImage : appImages.emojiAvatar
                      }
                      width={50}
                      height={50}
                      alt={"Emoji"}
                    />
                    <Stack direction={"row"} gap={"4px"} alignItems={"center"}>
                      <Typography
                        variant={"body1"}
                        fontSize={15}
                        color={"#1377F8"}
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => imgRef.current.click()}
                      >
                        Upload
                      </Typography>
                      <input
                        type="file"
                        ref={imgRef}
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                      <Typography
                        variant={"body1"}
                        color={"#C9CCD1"}
                        fontSize={11}
                      >
                        (jpg, png)
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Typography
                variant={"body1"}
                textAlign={"right"}
                color={appColor.black}
                fontSize={14}
                fontWeight={500}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => setOpenDelete(true)}
              >
                Delete Account
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <Box
                bgcolor={appColor.white}
                borderRadius={"20px"}
                boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
              >
                <BoxHeaders text={"API Keys 🗝"} />
                <Divider />
                <Box sx={{ padding: { xs: "12px", md: "12px 20px" } }}>
                  <InputLabels text={"Your Dual Wire’s API Key is:"} />
                  <Box mt={"4px"}>
                    <TextInput
                      paddingCustom={"8.5"}
                      inputVal={apiKey}
                      handleChangeInput={(e) => setApiKey(e.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid> */}
            <Grid item xs={12}>
              <Box
                bgcolor={appColor.white}
                borderRadius={"20px"}
                boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
              >
                <BoxHeaders text={"Change Password 🔐"} />
                <Divider />
                <Box sx={{ padding: { xs: "12px", md: "12px 20px" } }}>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12} md={4}>
                      <InputLabels text={"Current Password"} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextInput
                        paddingCustom={"8.5"}
                        inputType={"password"}
                        inputName={"password"}
                        inputVal={resetPassword?.password}
                        handleChangeInput={handleResetPassword}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabels text={"New Password"} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextInput
                        paddingCustom={"8.5"}
                        inputType={"password"}
                        inputName={"newPassword"}
                        inputVal={resetPassword?.newPassword}
                        handleChangeInput={handleResetPassword}
                      />
                    </Grid>
                  </Grid>
                  <Box height={35} width={100} margin={"20px 0 0 auto"}>
                    <CustomButton
                      btnText={"Change"}
                      textWeight={400}
                      handleClickBtn={handleChangePassword}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box height={40} width={150} margin={"20px auto"}>
        <CustomButton
          btnText={"Save Changes"}
          textWeight={400}
          handleClickBtn={handleUpdateProfile}
        />
      </Box>

      <DeleteAlert
        open={openDelete}
        handleClose={handleCloseDialog}
        handleDelete={handleDeleteAccount}
      />
    </Box>
  );
}

export default Preferences;
