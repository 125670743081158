import React, { useEffect, useState } from "react";
import AppHeader from "../../../components/AppHeader";
import Loader from "../../../components/Loader";
import SnackbarAlert from "./../../../components/SnackbarAlert";
import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import "./style.css";
import Preferences from "./Preferences";
import Billing from "./Billing";
import Help from "./Help";
import AppFooter from "../../../components/AppFooter";
import GotoLogin from "../../../components/GotoLogin";
import { getAllHelps } from "../../../service/comments";

function MyAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("Preferences");
  const [allHelps, setAllHelps] = useState([]);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    fetchAllHelps();
  }, []);

  const fetchAllHelps = () => {
    setIsLoading(true);
    getAllHelps()
      .then((response) => {
        setIsLoading(false);
        if (response?.data.status == "ok") {
          let data = response?.data?.data.slice().reverse();
          setAllHelps(data);
        } else {
          console.log("lund");
          handleSnackbarVal(true, "error", "Something went wrong");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", "jj went wrong");
      });
  };

  return (
    <Box>
      <AppHeader>
        <Divider />
        <SnackbarAlert
          snackbarProps={snackbarProps}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <Loader isloading={isLoading} />
        {!userData ? (
          <GotoLogin />
        ) : (
          <>
            {" "}
            <Container
              sx={{ maxWidth: "1200px !important", marginTop: "20px" }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"20px"}
              >
                <Typography
                  onClick={() => setCurrentTab("Preferences")}
                  className={
                    currentTab === "Preferences"
                      ? "active-component"
                      : "not-active-component "
                  }
                >
                  Preferences
                </Typography>
                <Typography
                  onClick={() => setCurrentTab("Billing")}
                  className={
                    currentTab === "Billing"
                      ? "active-component"
                      : "not-active-component "
                  }
                >
                  Billing
                </Typography>
                <Typography
                  onClick={() => setCurrentTab("Help")}
                  className={
                    currentTab === "Help"
                      ? "active-component"
                      : "not-active-component "
                  }
                >
                  Help
                </Typography>
              </Stack>
              {currentTab === "Preferences" ? (
                <Preferences
                  handleSnackbarVal={handleSnackbarVal}
                  setIsLoading={setIsLoading}
                />
              ) : currentTab === "Billing" ? (
                <Billing
                  handleSnackbarVal={handleSnackbarVal}
                  setIsLoading={setIsLoading}
                />
              ) : (
                <Help
                  handleSnackbarVal={handleSnackbarVal}
                  setIsLoading={setIsLoading}
                  allHelps={allHelps}
                />
              )}
            </Container>
          </>
        )}
        <Box display={"flex"} justifyContent={"center"}>
          <Container
            sx={{
              maxWidth: "1200px !important",
              marginTop: "10px",
              position: userData ? "relative" : "absolute",
              bottom: 0,
            }}
          >
            <AppFooter />
          </Container>
        </Box>
      </AppHeader>
    </Box>
  );
}

export default MyAccount;
