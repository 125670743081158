import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextInput from "../../../components/TextInput";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { makeStyles } from "@mui/styles";

const childAccordionData = [
  {
    title: "Can I add contest conditions and filters?",
    content: "The number of winners you want to obtain. From 1 to 99",
  },
  {
    title: "Can I run a giveaway with multiples Instagram posts?",
    content: "The number of substitutes you want to obtain. From 1 to 99 ",
  },
  {
    title: "Can I filter who has shared my post in Stories?",
    content:
      "This filter should be applied when you want all participants have the same chance of winning without taking into account the number of times they comment on your post",
  },
  {
    title: "Can I download a list of giveaway participants?",
    content:
      "Manually enter new participants or extra chances by stories. You can add,emails,names or usernames, whatever allow you to identify the entrant in case of win ",
  },
  {
    title: "Can I pay only for a single Giveaway or Promotions?",
    content: "Exclude users from the list of commentary / participants.",
  },
  {
    title: "Can I get an annual subscription to the platform?",
    content:
      "Use the logo upload and background colors to showcase your brand identity",
  },
  {
    title: "Can i cancel my subscription?",
    content: "The number {in seconds} to display the winners on the screen.",
  },
  {
    title: "Can i upgrade my plan?",
    content: "The number {in seconds} to display the winners on the screen.",
  },
  {
    title: "Is there a free trial of AppStores?",
    content: "The number {in seconds} to display the winners on the screen.",
  },
  {
    title: "If i subscribe, do i have to pay for my giveaways?",
    content: "The number {in seconds} to display the winners on the screen.",
  },
  {
    title: "Can i get a payment invoice?",
    content: "The number {in seconds} to display the winners on the screen.",
  },
];

const useStyles = makeStyles((theme) => ({
  accordianStyle: {
    "&.MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "& .MuiAccordionSummary-content .remove-margin": {
      margin: "0px",
      marginBottom: "10px !important",
    },
    "&.MuiPaper-root .MuiAccordionSummary-root": {
      padding: "0",
      borderBottom: "1px solid #dbdbdb",
    },
    "& .MuiAccordionDetails-root ": {
      padding: "0px 0px",
    },
  },
  accordianStyle2: {
    "&.MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&.MuiPaper-root .MuiAccordionSummary-root": {
      padding: "0",
    },
  },
}));

function Help({ allHelps }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState({});
  const [allQuestion, setAllQuestions] = useState(allHelps);
  const [filterData, setFilterData] = useState(allHelps);

  useEffect(() => {
    // setAllQuestions(allHelps);
    // setFilterData(allHelps);
  }, []);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    console.log(isExpanded, panel, "isExpandedisExpandedisExpanded");
    alert("hell");
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  const handleChangeFilter = (e) => {
    console.log(e.target.value, "jnjjjjkjkjkjkjk");
    const filterData = allQuestion?.filter((itemm) =>
      itemm.title.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilterData(filterData);
  };

  return (
    <Box sx={{ margin: { xs: "30px 5px", md: "30px 20px" } }}>
      <Typography
        variant={"h3"}
        fontFamily={"Roboto Flex"}
        fontSize={25}
        fontWeight={800}
        textAlign={"center"}
      >
        🙌🏻 Help Center
      </Typography>

      <Typography
        variant={"body1"}
        fontFamily={"Roboto Flex"}
        fontSize={13}
        textAlign={"center"}
        mt={1.5}
      >
        What can we help you with?
      </Typography>
      <Box margin={"10px auto"} sx={{ width: { xs: "100%", md: "50%" } }}>
        <TextInput
          placeholder={"Search here"}
          handleChangeInput={handleChangeFilter}
          startIcon={<SearchIcon />}
        />
      </Box>
      <Box
        margin={"0px auto"}
        sx={{
          width: { xs: "100%", md: "50%" },
        }}
      >
        {/* <Accordion className={classes.accordianStyle} expanded={true}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="remove-margin"
          > */}
            {/* <Box>
              <Typography variant={"h6"} fontSize={16} fontWeight={600}>
                Are all the apps Free?
              </Typography>
              <Typography variant={"body1"} fontSize={11}>
                All our applications have a free option, which allows you to try
                AppSorteos with the limitations indicated in the free plan. If
                you opt for a paid plan, you get access to premium features of
                our application. If you wish, you can check what you get from
                AppSorteos in its free option and in our recommended, Premium
                options. View Plans & Pricing
              </Typography>
            </Box> */}
          {/* </AccordionSummary> */}
          {/* <AccordionDetails> */}
            {filterData?.map((item, index) => (
              // Child Accordion
              <Accordion key={index} className={classes.accordianStyle2}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        transform: expanded[`panel-${index}`]
                          ? "rotate(0deg)"
                          : "rotate(270deg)",
                        transition: "transform 0.3s ease-in-out",
                      }}
                    />
                  }
                  onClick={() => handleAccordionChange(`panel-${index}`)}
                  aria-controls={`panel-${index}-content`}
                  id={`panel-${index}-header`}
                >
                  <Typography variant={"h6"} fontSize={13} fontWeight={500}>
                    {item?.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Typography variant={"body1"} fontSize={13} fontWeight={500}>
                    {item?.title}
                  </Typography> */}
                  <Typography variant={"body1"} fontSize={13} mt={"5px"}>
                    {item?.content}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          {/* </AccordionDetails> */}
        {/* </Accordion> */}
      </Box>
    </Box>
  );
}

export default Help;
