import React, { useState } from "react";
import AppHeader from "../../components/AppHeader";
import SnackbarAlert from "../../components/SnackbarAlert";
import Loader from "../../components/Loader";
import { Box, Container, Divider, Grid, Link, Typography } from "@mui/material";
import CustomButton from "../../components/CustomButton";
import ReactCodeInput from "react-code-input";
import "./style.css";
import { appColor } from "../../constants/Colors";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { appIcons } from "../../assets";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextLabel from "../../components/TextLabel";
import TextInput from "../../components/TextInput";
import { forgotPassword } from "../../service/authentiction";

function VerifyCode() {
  const navigate = useNavigate();
  const { state: email } = useLocation();
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(false);

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleForgotPassword = () => {
    if (code.length > 4) {
      handleSnackbarVal(true, "error", "code is required");
    } else if (!password) {
      handleSnackbarVal(true, "error", "New Password is required");
    } else {
      setIsLoading(true);
      let payload = {
        email,
        otp: code,
        password,
      };
      forgotPassword(payload)
        .then((ress) => {
          setIsLoading(false);
          if (ress?.data?.status == "ok") {
            handleSnackbarVal(true, "success", ress?.data?.message);
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else {
            handleSnackbarVal(true, "error", ress?.data?.message);
          }
        })
        .catch((errr) => {
          setIsLoading(false);
          handleSnackbarVal(true, "errro", errr?.message);
        });
    }
  };

  return (
    <AppHeader>
      <Divider />

      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Container sx={{ maxWidth: "450px !important", marginTop: "50px" }}>
        <Typography
          variant={"h4"}
          fontSize={26}
          fontWeight={700}
          textAlign={"center"}
          mt={3}
        >
          🗝 Password Reset
        </Typography>
        <Typography
          variant={"body1"}
          fontSize={13}
          fontWeight={400}
          textAlign={"center"}
          mt={1}
        >
          We sent a code to {email}
        </Typography>
        <Grid container m={"20px 0"} spacing={3}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Box className="code-inputsss">
              <ReactCodeInput
                type="text"
                fields={4}
                value={code}
                onChange={(code) => setCode(code)}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextLabel inputText={"Enter New Password"} />
            <TextInput
              placeholder={"Enter your password"}
              inputType={hidePassword ? "text" : "password"}
              inputVal={password}
              handleChangeInput={(e) => setPassword(e.target.value)}
              endIcon={
                hidePassword ? (
                  <RemoveRedEyeIcon
                    sx={{ color: "#C9CCD1", mr: "10px", cursor: "pointer" }}
                    onClick={() => setHidePassword(false)}
                  />
                ) : (
                  <VisibilityOffIcon
                    sx={{ color: "#C9CCD1", mr: "10px", cursor: "pointer" }}
                    onClick={() => setHidePassword(true)}
                  />
                )
              }
            />
          </Grid>
        </Grid>
        <Box display={"flex"} justifyContent={"center"}>
          <Box width={"150px"} height={42}>
            <CustomButton
              btnText={"Continue"}
              textWeight={500}
              handleClickBtn={handleForgotPassword}
            />
          </Box>
        </Box>
        <Typography variant={"body1"} fontSize={13} mt={2} textAlign={"center"}>
          Didn’t receive the email?
          <Link
            sx={{
              ml: "3px",
              color: appColor.black,
              fontWeight: 500,
              textDecorationColor: appColor.black,
              textDecorationThickness: "2px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/forgotPassword")}
          >
            Click here
          </Link>
        </Typography>
        <Typography
          mt={2}
          gap={"5px"}
          fontSize={13}
          variant={"body1"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/login")}
        >
          <img src={appIcons?.backArrow} width={"18px"} /> Back to login
        </Typography>
      </Container>
    </AppHeader>
  );
}

export default VerifyCode;
