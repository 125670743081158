import axios from "axios";

// export let baseUrl = "http://192.168.59.171:4000/api/";
// let baseUrl = "https://sirldigital.com/rafle_select/api/";
// export let baseUrl = "https://backend-new-dual.azurewebsites.net/api/";
export let baseUrl = "https://backend.raffleselect.com/api/";

const api = async (path, params, method) => {
  let options;
  options = {
    headers: {
      "Content-Type": "application/json",
    },
    method: method,
    ...(params && { data: JSON.stringify(params) }),
  };
  return axios(baseUrl + path, options)
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      return error.response;
    });
};

export default api;
