import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

function AppFooter() {
  const navigate = useNavigate();
  return (
    <Box
      padding={"10px 0px"}
      sx={{
        background: "transparent",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexWrap={"wrap"}
        // gap={"15px"}
        sx={{ gap: { xs: "5px", md: "15px" } }}
      >
        <Typography variant={"body1"} fontSize={13}>
          © RaffleSelect LLC
        </Typography>
        <Stack
          direction={"row"}
          sx={{ gap: { xs: "5px", md: "15px" } }}
          flexWrap={"wrap"}
        >
          <Typography variant={"body1"} fontSize={13}>
            Contact Us support@raffleselect.com
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={13}
            sx={{ cursor: "pointer" }}
          >
            <Link to="/terms-and-conditions">Terms and Condition</Link>
          </Typography>
          <Typography
            variant={"body1"}
            fontSize={13}
            sx={{ cursor: "pointer" }}
          >
            <Link to="/privacy-policy">Privacy Policy</Link>
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}

export default AppFooter;
