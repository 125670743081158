import Api from "./index";
import { endPoints, requestType } from "../constants/Variable";

export const registerUser = (params) => {
  return Api(endPoints.register, params, requestType.POST);
};

export const socialRegister = (params) => {
  return Api(endPoints.socialLogin, params, requestType.POST);
};

export const loginUser = (params) => {
  return Api(endPoints.login, params, requestType.POST);
};

export const sendOtpCode = (params) => {
  return Api(endPoints.sendForgotPasswordCode, params, requestType.POST);
};

export const forgotPassword = (params) => {
  return Api(endPoints.forgotPassword, params, requestType.POST);
};

export const updateUserProfile = (id, params) => {
  return Api(`${endPoints.updateUserProfile}/${id}`, params, requestType.PUT);
};

export const deleteVideoHistory = (id, params) => {
  return Api(`${endPoints.deleteVideoHistory}/${id}`, null, requestType.DELETE);
};

export const changePassword = (params) => {
  return Api(endPoints.changePassword, params, requestType.POST);
};

export const deleteAccount = (id) => {
  return Api(`${endPoints.deleteUserAccount}/${id}`, null, requestType.DELETE);
};

export const createStripeSession = (params) => {
  return Api(endPoints.createStripesession, params, requestType.POST);
};

export const updateSteps = (id, params) => {
  return Api(`${endPoints.updateSteps}/${id}`, params, requestType.PUT);
};
