import {
  Avatar,
  Box,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appIcons, appImages } from "../../../../assets";
import AnimatedLoader from "../../../../components/AnimatedLoader";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import BuyPlanModal from "../../../../components/BuyPlan";
import CustomButton from "../../../../components/CustomButton";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import StackTemplate from "../../../../components/StackTemplate";
import { appColor } from "../../../../constants/Colors";
import { AppContext } from "../../../../context";
import GlobalStyle from "../../../../globalStyle";
import { setAppFilters } from "../../../../redux/slices/GiveawaySlice";
import { getTiktokVideoComments } from "../../../../service/comments";
import LoginDialog from "../../../auth/Login";
import SignupDialog from "../../../auth/Signup";

function TikTokPostStats() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalClass = GlobalStyle();
  const [isLoading, setIsLoading] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const { userData, postData, handleUpdateSteps } = useContext(AppContext);
  const [openPlanDialog, setOpenPlanDialog] = useState(false);
  const filterData = useSelector((state) => state?.giveaway?.appFilters);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  console.log(postData, "postDatapostDatapostData");

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleClosePlanDialog = () => {
    setOpenPlanDialog(false);
  };

  useEffect(() => {
    if (userData) {
      handleUpdateSteps(userData?._id, 1, "Tiktok");
    }
  }, [userData]);

  const handleFetchComments = async () => {
    try {
      if (!userData) {
        setOpenSignup(true);
        return;
      }

      if (postData?.commentCount === 0) {
        handleSnackbarVal(true, "error", "Opps this post has 0 comments");
        return;
      }

      if (postData?.commentCount <= userData?.comments) {
        setIsLoading(true);
        const response = await getTiktokVideoComments(postData?.videoId);
        setIsLoading(false);

        if (response?.data?.status === "ok") {
          const comments = response?.data?.data;
          dispatch(
            setAppFilters({
              ...filterData,
              allComments: comments,
            })
          );
          navigate("/tiktok-giveaway/comment-filters");
        } else {
          handleSnackbarVal(true, "error", response?.data?.message);
        }
      } else {
        setOpenPlanDialog(true);
      }
    } catch (error) {
      setIsLoading(false);
      handleSnackbarVal(true, "error", "Something went wrong");
    }
  };

  return (
    <AppHeader>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Divider />
      {isLoading ? (
        <AnimatedLoader />
      ) : (
        <>
          <Stack mt={4}>
            <Typography
              variant={"h1"}
              className={globalClass.raDetailH1}
              textAlign={"center"}
            >
              Tiktok Comment Picker
            </Typography>
            <Typography
              variant={"body1"}
              fontSize={14}
              textAlign={"center"}
              mt={1}
            >
              Select a random comment winner from your Tiktok videos at no cost.
            </Typography>
          </Stack>
          <Container sx={{ maxWidth: "500px !important", mt: 4 }}>
            <Box
              bgcolor={appColor.white}
              borderRadius={"20px"}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
            >
              <StackTemplate
                stackProps={{
                  p: "10px",
                }}
              >
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Avatar src={postData?.image} alt="Logo" />
                  <Typography
                    variant={"h6"}
                    fontSize={13}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {postData?.username}
                  </Typography>
                </Stack>
              </StackTemplate>
              <Divider />

              <Box width={"70%"} p={"2px"} height={"400px"} margin={"0 auto"}>
                <img
                  src={
                    postData?.thumbnail
                      ? postData?.thumbnail
                      : appImages?.NoPostImage
                  }
                  width={"100%"}
                  height={"100%"}
                  style={{ borderRadius: "10px", objectFit: "cover" }}
                />
              </Box>
              <Stack
                spacing={1}
                width={"70%"}
                direction={"row"}
                alignItems={"center"}
                margin={"10px auto"}
              >
                <Stack direction={"row"} spacing={"5px"} alignItems={"end"}>
                  <img src={appIcons.heartBlack} width={20} />
                  <Typography
                    variant={"body1"}
                    fontSize={11}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {postData ? postData?.likeCount : 0}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={"5px"} alignItems={"end"}>
                  <img src={appIcons.commentBlack} width={20} />
                  <Typography
                    variant={"body1"}
                    fontSize={11}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {postData ? postData?.commentCount : 0}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={"5px"} alignItems={"end"}>
                  <img src={appIcons.shareBlack} width={20} />
                  <Typography
                    variant={"body1"}
                    fontSize={11}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {postData ? postData?.shareCount : 0}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                p={"8px"}
                justifyContent={"end"}
                alignItems={"center"}
                spacing={2}
                mt={"10px"}
              >
                <Typography
                  variant={"body1"}
                  fontSize={12}
                  fontWeight={400}
                  color={appColor.black}
                  onClick={() => navigate(-1)}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Back
                </Typography>
                <Box width={88} height={30}>
                  <CustomButton
                    btnText={"Comments"}
                    textWeight={400}
                    textSize={12}
                    iconSize={"15px !important"}
                    handleClickBtn={handleFetchComments}
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
          <Container sx={{ maxWidth: "1200px !important", marginTop: "10px" }}>
            <AppFooter />
          </Container>
        </>
      )}
      <BuyPlanModal open={openPlanDialog} handleClose={handleClosePlanDialog} />
      <SignupDialog
        open={openSignup}
        setOpen={setOpenSignup}
        openLogin={setOpenLogin}
      />
      <LoginDialog
        open={openLogin}
        setOpen={setOpenLogin}
        openSignup={setOpenSignup}
      />
    </AppHeader>
  );
}

export default TikTokPostStats;
