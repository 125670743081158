import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { appColor } from "../constants/Colors";
import CustomButton from "./CustomButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
    flexDirection: "column",
    gap: "15px",
  },
  "& .MuiDialog-paper": {
    width: "500px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, padding: { xs: "0px 10px", sm: "0 30px", md: "0 40px" } }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function BussinessAccountDialog({
  open,
  handleClose,
  handleNaviagteHome,
}) {
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        scroll="body"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            mt={8}
            fontSize={30}
            variant={"h1"}
            fontWeight={"800"}
            fontFamily={"Roboto Flex"}
            textAlign={"center"}
            lineHeight={"normal"}
          >
            <span style={{ color: "red", fontSize: "35px" }}>!!</span> We
            couldn't find your account
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            padding: {
              xs: "5px 10px !important",
              sm: "10px 30px !important",
              md: "10px 50px !important",
            },
          }}
        >
          <Typography
            mt={2}
            fontSize={16}
            variant={"h6"}
            fontWeight={"400"}
            fontFamily={"Roboto Flex"}
            textAlign={"center"}
            lineHeight={"normal"}
          >
            Your Instagram account must be Business or Creator. Connect with
            Facebook and accept the permissions to start your giveaway.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box height={45} width={"100%"}>
            <CustomButton
              btnText={"Connect with facebook"}
              // handleClickBtn={handleDelete}
            />
          </Box>
          <Box height={45} width={"100%"}>
            <CustomButton
              btnText={"My account is not Business"}
              handleClickBtn={handleNaviagteHome}
            />
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
