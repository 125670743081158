import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { appColor } from "../constants/Colors";
import { makeStyles } from "@mui/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
    flexDirection: "column",
  },
  "& .MuiDialog-paper": {
    width: "450px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const childAccordionData = [
  {
    title: "Number of Winners",
    content: "The number of winners you want to obtain. From 1 to 99",
  },
  {
    title: "Number of Substitutes",
    content: "The number of substitutes you want to obtain. From 1 to 99 ",
  },
  {
    title: "Exclude Duplicates",
    content:
      "This filter should be applied when you want all participants have the same chance of winning without taking into account the number of times they comment on your post",
  },
  {
    title: "Extra Chances",
    content:
      "Manually enter new participants or extra chances by stories. You can add,emails,names or usernames, whatever allow you to identify the entrant in case of win ",
  },
  {
    title: "Block List",
    content: "Exclude users from the list of commentary / participants.",
  },
  {
    title: "Branding",
    content:
      "Use the logo upload and background colors to showcase your brand identity",
  },
  {
    title: "Countdown",
    content: "The number {in seconds} to display the winners on the screen.",
  },
];

const useStyle = makeStyles((theme) => ({
  accordianStyle: {
    "&.MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&.MuiPaper-root .MuiAccordionSummary-root": {
      padding: "0",
      borderBottom: "1px solid #dbdbdb",
    },
    "& .MuiAccordionDetails-root ": {
      padding: "12px 0px",
    },
  },
  accordianStyle2: {
    "&.MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&.MuiPaper-root .MuiAccordionSummary-root": {
      padding: "0",
    },
  },
}));

export default function HelpDialog({ open, setOpen }) {
  const classes = useStyle();
  const [expanded, setExpanded] = useState({});

  console.log(expanded, "ascjhasjh");

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    console.log(isExpanded, panel, "isExpandedisExpandedisExpanded");
    alert("hell");
    setExpanded({ ...expanded, [panel]: isExpanded });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        scroll="body"
        aria-labelledby="customized-dialog-title"
        open={open}
        disableScrollLock={false}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            variant={"h2"}
            fontSize={25}
            fontWeight={700}
            textAlign={"center"}
            mt={3}
          >
            🙌🏻 Help
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent style={{ padding: "10px 20px" }}>
          <Accordion className={classes.accordianStyle} expanded={true}>
            {/* <AccordionSummary
              expandIcon={<ExpandLessIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Box>
                <Typography variant={"h6"} fontSize={15} fontWeight={600}>
                  Number of Winners
                </Typography>
                <Typography variant={"body1"} fontSize={10}>
                  The number of winners (or prizes) you want to obtain. From 1
                  to 99.
                </Typography>
              </Box>
            </AccordionSummary> */}
            <AccordionDetails>
              {childAccordionData.map((item, index) => (
                // Child Accordion
                <Accordion key={index} className={classes.accordianStyle2}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={{
                          transform: expanded[`panel-${index}`]
                            ? "rotate(0deg)"
                            : "rotate(270deg)",
                          transition: "transform 0.3s ease-in-out",
                        }}
                      />
                    }
                    onClick={() => handleAccordionChange(`panel-${index}`)}
                    aria-controls={`panel-${index}-content`}
                    id={`panel-${index}-header`}
                  >
                    <Typography variant={"h6"} fontSize={15} fontWeight={600}>
                      {item?.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant={"body1"} fontSize={13} mt={"5px"}>
                      {item?.content}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
