import { Box } from "@mui/material";
import React from "react";
import { appGifs } from "../assets";

function SoundWaveGif({}) {
  return (
    <Box>
      <img src={appGifs?.soundWaveGif} height={"70px"} />
      <img src={appGifs?.soundWaveGif} height={"70px"} />
      <img src={appGifs?.soundWaveGif} height={"70px"} />
    </Box>
  );
}

export default SoundWaveGif;
