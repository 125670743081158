import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import TextLabel from "./../../../../components/TextLabel";
import CustomButton from "../../../../components/CustomButton";
import { appAudio } from "../../../../assets";
import AppHeader from "../../../../components/AppHeader";

const maxRollTimes = 10;
const faces = 6; // Assuming 6 faces for the dice

const counting = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

function shuffleArray(array) {
  // Shuffle the array using Fisher-Yates algorithm
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function Dice() {
  const [intrvl, setIntrvl] = useState();
  const [diceFaces, setDiceFaces] = useState([1]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [rollTimes, setRollTimes] = useState(maxRollTimes);
  const [noOfDice, setNoOfDice] = useState(1);
  const [noOfSides, setNoOfSides] = useState(6);
  const [totalSum, setTotalSum] = useState(0);
  const [rolling, setRolling] = useState(false); // Track if dice are currently rolling

  console.log(rolling, "Sadsdasdjhasadjh");

  useEffect(() => {
    if (rollTimes === 0) {
      clearInterval(intrvl);
      setBtnDisabled(false);
      setRolling(false);
      // Calculate total sum when dice stop rolling
      const sum = diceFaces.reduce((acc, face) => acc + face, 0);
      setTotalSum(sum);
    }
  }, [rollTimes, intrvl, diceFaces]);

  useEffect(() => {
    // Update the number of dice faces immediately when the number of dice changes
    setDiceFaces(Array.from({ length: noOfDice }, () => 1));
    console.log(
      Array.from({ length: noOfDice }, () => 1),
      "adasdasdasdasds"
    );
  }, [noOfDice]);

  const rollDice = () => {
    const coinSpinSound = document.getElementById("diceRollSound");
    setBtnDisabled(true);
    clearInterval(intrvl);
    let counter = Math.floor(Math.random() * maxRollTimes + 1);
    setRollTimes(counter);
    setRolling(true); // Set rolling to true when dice start rolling
    const interval = setInterval(() => {
      // coinSpinSound.play();
      const newDiceFaces = Array.from(
        { length: noOfDice },
        () => Math.floor(Math.random() * noOfSides) + 1
      );
      setDiceFaces(newDiceFaces);
      counter--;
      setRollTimes(counter);
    }, 200);
    setIntrvl(interval);
  };

  const handleChangeDice = (e) => {
    setNoOfDice(e.target.value);
    setTimeout(() => {
      setTotalSum(0);
    }, 1000);
  };

  const rollButton = (
    <Box width={150} height={40} mt={2} mb={2}>
      <CustomButton
        btnText={"Roll Dice"}
        textWeight={500}
        handleClickBtn={rollDice}
        btnDisable={btnDisabled}
      />
    </Box>
  );

  return (
    <AppHeader>
      <Divider />
      <Container sx={{ maxWidth: "800px !important", marginTop: "35px" }}>
        <div className="main-container">
          <audio id="diceRollSound">
            <source src={appAudio.diceRollSound} type="audio/mpeg" />
          </audio>
          <Container maxWidth={"sm"}>
            <h1>Roll the dice Online</h1>
            <Grid container mb={3}>
              <Grid item xs={12} md={6}>
                <Stack gap={"10px"} direction={"row"} alignItems={"center"}>
                  <TextLabel inputText={"Number of dices"} textSize={15} />
                  <FormControl>
                    <Select
                      value={noOfDice}
                      onChange={handleChangeDice}
                      size="small"
                    >
                      {counting?.map((val, i) => {
                        return (
                          <MenuItem value={val} key={i}>
                            {val}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack gap={"10px"} direction={"row"} alignItems={"center"}>
                  <TextLabel inputText={"Number of sides"} textSize={15} />
                  <FormControl>
                    <Select
                      value={noOfSides}
                      onChange={(e) => setNoOfSides(e.target.value)}
                      size="small"
                    >
                      {counting?.map((val, i) => {
                        return (
                          <MenuItem value={val} key={i}>
                            {val}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Container>
          {noOfSides <= 6 ? (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={5}
              mb={5}
              flexWrap={"wrap"}
            >
              {diceFaces.map((face, index) => (
                <div key={index} className={`dice face-${face}`}>
                  <div className="face-1">
                    <div className="dot-container">
                      <div className="dot"></div>
                    </div>
                  </div>
                  <div className="face-3">
                    <div className="dot-container">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                  <div className="face-4">
                    <div className="dot-container">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                  <div className="face-2">
                    <div className="dot-container">
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                  <div className="face-5">
                    <div className="dot-container">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                  <div className="face-6">
                    <div className="dot-container">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                </div>
              ))}
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={5}
              mb={5}
              flexWrap={"wrap"}
            >
              {diceFaces.map((face, index) => (
                <>
                  {console.log(face, "ASdasdjkasdjkas")}
                  <div
                    key={index}
                    className={`dice-number-container ${
                      rolling ? "number-container-animate" : null
                    }`}
                  >
                    {/* {Array.from({ length: face }, (_, i) => i + 1).map(
                      (num,ind) => (
                        <> */}
                    {/* {console.log(num,ind+1, "sdasjdjhdashjdashjd")} */}
                    <div className="number">{face}</div>
                    {/* </>
                      )
                    )} */}
                  </div>
                </>
              ))}
            </Stack>
          )}
          <Typography
            variant="h2"
            fontSize={22}
            fontWeight={500}
            textAlign={"center"}
          >
            <div>
              Your result: <strong>{totalSum} </strong>
            </div>
          </Typography>
          <div className="button-container">{rollButton}</div>
        </div>
      </Container>
    </AppHeader>
  );
}

export default Dice;
