import React, { useContext } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { appColor } from "../constants/Colors";
import CustomButton from "./CustomButton";
import { AppContext } from "../context";
import { appFonts } from "../constants/Fonts";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  "& .MuiDialog-paper": {
    width: "450px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function BuyPlanModal({ open, handleClose, dialogData }) {
  const navigate = useNavigate();
  const { userData } = useContext(AppContext);
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        scroll="body"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            variant={"h6"}
            fontWeight={"800"}
            fontFamily={appFonts.RobotoFlex}
            textAlign={"center"}
            fontSize={25}
            mt={1}
          >
            Upgrade Your Plan!
            {/* {dialogData?.dialogTitle} */}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent style={{ padding: "0px 40px" }}>
          <Typography
            variant={"body1"}
            fontSize={14}
            fontWeight={400}
            // textAlign={"center"}
            fontFamily={appFonts.RobotoFlex}
            mt={1}
          >
            Your Plan does not support comments of more than{" "}
            {userData?.comments}.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Box width={"160px"} height={40}>
            <CustomButton
              btnText={"Upgrade plan"}
              handleClickBtn={() => {
                handleClose();
                navigate("/shop");
              }}
            />
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
