import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import Loader from "../../components/Loader";
import SnackbarAlert from "../../components/SnackbarAlert";
import TextInput from "../../components/TextInput";
import TextLabel from "../../components/TextLabel";
import { appColor } from "../../constants/Colors";
import { AppContext } from "../../context";
import { loginUser } from "../../service/authentiction";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: "0px 24px",
    flexDirection: "column",
    paddingBottom: "40px",
    display: "block",
  },
  "& .MuiDialog-paper": {
    width: "450px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function LoginDialog({ open, setOpen, openSignup }) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUserData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleClose = () => {
    setOpen(false);
    setEmail("");
    setPassword("");
  };

  const handleLogin = () => {
    if (email == "") {
      handleSnackbarVal(true, "error", "Email is required");
    } else if (!emailRegex.test(email)) {
      handleSnackbarVal(true, "error", "Invalid email format");
    } else if (password == "") {
      handleSnackbarVal(true, "error", "Password is required");
    } else {
      let payload = {
        email,
        password,
      };
      setIsLoading(true);
      loginUser(payload)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.status == "ok") {
            let userData = res?.data?.data?.userDetails;
            let userToken = res?.data?.data?.token;
            localStorage.setItem("user", JSON.stringify(userData));
            localStorage.setItem("token", JSON.stringify(userToken));
            setUserData(userData);
            handleSnackbarVal(
              true,
              "success",
              `Welcom Back ${userData?.firstName} ${userData?.lastName}`
            );
            setEmail("");
            setPassword("");
            handleClose();
          } else {
            handleSnackbarVal(true, "error", res?.data?.message);
          }
        })
        .catch((errr) => {
          setIsLoading(false);
          handleSnackbarVal(true, "error", errr?.message);
          console.log(errr, "EROOORRRR");
        });
    }
  };

  return (
    <div>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <div>
        <BootstrapDialog
          onClose={handleClose}
          scroll="body"
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Typography
              variant={"h4"}
              fontSize={26}
              fontWeight={700}
              textAlign={"center"}
              mt={3}
            >
              👋🏻 Join the Fun!!
            </Typography>
            <Typography
              variant={"body1"}
              fontSize={13}
              fontWeight={400}
              textAlign={"center"}
              mt={1}
            >
              Login for your shot at randoms wins!!
            </Typography>
          </BootstrapDialogTitle>
          <DialogContent style={{ padding: "0px 20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextLabel inputText={"Email"} />
                <TextInput
                  placeholder={"Enter your email"}
                  inputType={"email"}
                  inputVal={email}
                  handleChangeInput={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextLabel inputText={"Password"} />
                <TextInput
                  placeholder={"Enter your password"}
                  inputType={hidePassword ? "text" : "password"}
                  inputVal={password}
                  handleChangeInput={(e) => setPassword(e.target.value)}
                  endIcon={
                    hidePassword ? (
                      <RemoveRedEyeIcon
                        sx={{ color: "#C9CCD1", mr: "10px", cursor: "pointer" }}
                        onClick={() => setHidePassword(false)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ color: "#C9CCD1", mr: "10px", cursor: "pointer" }}
                        onClick={() => setHidePassword(true)}
                      />
                    )
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Box textAlign={"right"} m={"10px 0px"}>
              <Typography
                variant={"span"}
                fontSize={13}
                color={"#161616"}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                  navigate("/forgotPassword");
                }}
              >
                Forgot password?
              </Typography>
            </Box> */}
            <Box m={"10px 0px"}>
              <Box width={"100%"} height={40}>
                <CustomButton
                  btnText={"Login to continue"}
                  textWeight={500}
                  handleClickBtn={handleLogin}
                />
              </Box>
            </Box>
            <Typography
              variant={"body1"}
              fontSize={13}
              mt={2}
              textAlign={"center"}
            >
              Do not have an account?
              <Link
                sx={{
                  ml: "3px",
                  color: appColor.black,
                  fontWeight: 500,
                  textDecorationColor: appColor.black,
                  textDecorationThickness: "2px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen(false);
                  openSignup(true);
                }}
              >
                Create an Account
              </Link>
            </Typography>
          </DialogActions>
          {/* <SignupDialog open={openSignup} setOpen={setOpenSignup} /> */}
        </BootstrapDialog>
      </div>
    </div>
  );
}
