import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Avatar,
  Box,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppHeader from "../../../../components/AppHeader";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import { appColor } from "../../../../constants/Colors";

function InstaAccounts() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  return (
    <AppHeader>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Divider />

      <Container
        maxWidth={"sm"}
        sx={{
          minHeight: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h1"
          fontSize={18}
          fontWeight={500}
          textAlign={"center"}
        >
          Please select an instagram account
        </Typography>
        <Box
          mt={5}
          bgcolor={appColor.white}
          borderRadius={"10px"}
          overflow={"hidden"}
          width={"100%"}
          boxShadow={
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          }
        >
          {state?.map((elemmm, index) => {
            let userimage = elemmm?.picture?.data?.url;
            return (
              <Stack
                key={index}
                direction={"row"}
                padding={2}
                alignItems={"center"}
                justifyContent={"space-between"}
                borderBottom={"1px solid #d9d9d9"}
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/instagram-giveaway/pages-posts", {
                    state: elemmm,
                  })
                }
              >
                <Stack
                  direction={"row"}
                  gap={"20px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Avatar src={userimage} />
                  <Typography variant="body1" fontSize={15}>
                    {elemmm?.name}
                  </Typography>
                </Stack>
                <IconButton>
                  <KeyboardArrowRightIcon htmlColor={appColor.gray} />
                </IconButton>
              </Stack>
            );
          })}
        </Box>
      </Container>
    </AppHeader>
  );
}

export default InstaAccounts;
