import React from "react";
import "./style.css"

function TiktokLoader() {
  return (
    <div>
      <span class="tiktok-loader"></span>
    </div>
  );
}

export default TiktokLoader;
