import { createSlice } from "@reduxjs/toolkit";

const historySlice = createSlice({
  name: "history",
  initialState: {
    filters: {
      dublicate: false,
      hashtag: false,
      block: false,
      extraChance: false,
      extraChanceNames: [],
      blockListNames: [],
      hashtagText: "",
      counter: 5,
      totalWinners: 1,
      totalSubstitutes: 0,
      type: "add",
      videoId: "",
      allVideoComments: [],
    },
  },

  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setFilters } = historySlice.actions;
export default historySlice.reducer;
