import React, { useState, useEffect } from "react";
import AppHeader from "../../../../components/AppHeader";
import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import "./style.css";
import CustomButton from "../../../../components/CustomButton";
import { appColor } from "../../../../constants/Colors";
import { appAudio } from "../../../../assets";
import Dice from "../diceRole";

function FlipCoin() {
  const [result, setResult] = useState("");
  const [headCount, setHeadCount] = useState(0);
  const [tailCount, setTailCount] = useState(0);
  const [startFlip, setStartFlip] = useState(false);

  const handleFlipCoin = () => {
    console.log(result, "ASdasdasdasdasdd");
    setResult("");
    setTimeout(() => {
      const spin = Math.random();

      setStartFlip(true);
      const coinSpinSound = document.getElementById("coinSpinSound");
      coinSpinSound.play();

      if (spin < 0.5) {
        setResult("heads", () => {
          console.log("heads");
        });
      } else {
        setResult("tails", () => {
          console.log("tails");
        });
      }
    }, 10);
  };

  useEffect(() => {
    if (startFlip) {
      const animationDuration = 3000;
      setTimeout(() => {
        if (result === "tails") {
          setHeadCount((prev) => prev + 1);
        } else {
          setTailCount((prev) => prev + 1);
        }
        setStartFlip(false);
      }, animationDuration);
    }
  }, [startFlip, result]);

  console.log(result, "resulttttttt");

  return (
    <AppHeader>
      <audio id="coinSpinSound">
        <source src={appAudio.coinFlipSound} type="audio/mpeg" />
      </audio>
      <Divider />
      <Container sx={{ maxWidth: "1250px !important", marginTop: "35px" }}>
        <Container maxWidth={"sm"}>
          <Stack alignItems={"center"} gap={"5px"} mb={3}>
            <Typography
              variant="h1"
              color={appColor.black}
              fontSize={35}
              fontWeight={600}
            >
              Heads or Tails
            </Typography>
            <Typography
              variant="body1"
              color={appColor.gray}
              fontSize={16}
              fontWeight={400}
            >
              Flip a coin online with this virtual coin flipper, it's easy and
              free.
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"20px"}
            mb={3}
          >
            <Typography variant="body1">
              Heads: <span style={{ fontWeight: "600" }}>{headCount}</span>
            </Typography>
            <Typography variant="body1">
              Tails: <span style={{ fontWeight: "600" }}>{tailCount}</span>
            </Typography>
          </Stack>

          <Box id="coin" className={`coin ${result}`} onClick={handleFlipCoin}>
            <Box
              className={`side-${result === "tails" ? "b" : "a"} `}
              bgcolor={appColor.black}
            >
              <Box className="coin-doted-line">
                <Typography
                  variant="h2"
                  color={appColor.white}
                  fontSize={25}
                  fontWeight={600}
                >
                  TAILS
                </Typography>
              </Box>
            </Box>
            <Box
              // className={`side-${result === "heads" ? "b" : "a"} `}
              className={`side-${result === "heads" ? "b" : "a"} `}
              bgcolor={appColor.instaPink}
            >
              <Box className="coin-doted-line">
                <Typography
                  variant="h2"
                  color={appColor.white}
                  fontSize={25}
                  fontWeight={600}
                >
                  HEADS
                </Typography>
              </Box>
            </Box>
          </Box>
          <Stack mt={3} alignItems={"center"} gap={"5px"}>
            <Box width={150} height={40}>
              <CustomButton
                btnText={"Flip the coin!"}
                textWeight={500}
                handleClickBtn={handleFlipCoin}
                btnDisable={startFlip}
              />
            </Box>
            <Typography variant="body1" fontSize={12}>
              (touch screen or press space bar)
            </Typography>
          </Stack>
        </Container>
      </Container>
    </AppHeader>
  );
}

export default FlipCoin;
