import { makeStyles } from "@mui/styles";

export const TableStyle = makeStyles((theme) => ({
  styleTable: {
    "&.MuiPaper-root": {
      borderRadius: "0px",
      //   border: "1px solid black",
    },
    "&.MuiPaper-root .MuiTable-root .MuiTableHead-root": {
      backgroundColor: "#FFD6EB",
    },

    "&.MuiPaper-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiSvgIcon-root:first-child":
      {
        color: "#000",
      },
    // "&.MuiPaper-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiSvgIcon-root":
    //   {
    //     color: "#C5D6F1 !important",
    //   },
    "&.MuiPaper-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:first-child ":
      {
        fontWeight: 600,
        fontSize: 17,
        textAlign: "left",
        width: "24%",
      },
    "&.MuiPaper-root .MuiTable-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root ":
      {
        fontWeight: 600,
        fontSize: 17,
        textAlign: "center",
        padding: "16px 0px 16px 10px !important",
      },
    "&.MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root":
      {
        fontSize: 13,
        fontWeight: 500,
        borderRight: "1px solid #a1a1a1",
        borderBottom: "1px solid #a1a1a1",
        fontFamily: "Roboto Flex",
        width: "320px !important",
        cursor: "pointer",
        padding: "16px 0px 16px 10px !important",
      },
  },
}));
