import { configureStore } from "@reduxjs/toolkit";
import historySlice from "./slices/historySlice";
import giveawaySlice from "./slices/GiveawaySlice";

export const store = configureStore({
  reducer: {
    history: historySlice,
    giveaway: giveawaySlice,
  },
});
