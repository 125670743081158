import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { AppContext } from "../context";

function LoaderScreen() {
  const { countDown } = useContext(AppContext);
  const [counter, setCounter] = useState(countDown);
  const [scale, setScale] = useState(0.5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 1) {
          clearInterval(interval);
          return prevCounter;
        } else {
          setScale(1);
          setTimeout(() => {
            setScale((prevScale) => (prevScale === 0.5 ? 0.8 : 0.5));
          }, 1000);
          return prevCounter - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
        height: "180px",
        width: "180px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        // style={{
        //   position: "absolute",
        //   transition: "all 1s ease 0s",
        //   transform: `scale(${scale})`,
        // }}
        className="loader-text"
      >
        <p
          style={{
            fontSize: "80px",
            fontWeight: 700,
            fontFamily: "Roboto Flex",
          }}
        >
          {counter}
        </p>
      </div>
      <div className="custom-loader"></div>
    </div>
  );
}

export default LoaderScreen;
