import React, { useContext } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppContext } from "../context";
import { appColor } from "../constants/Colors";

const useStyle = makeStyles((theme) => ({
  inputField: {
    "&.MuiFormControl-root .MuiOutlinedInput-root": {
      fontWeight: 400,
      borderRadius: 6,
      paddingRight: 0,

      fontSize: 14,
      "&.Mui-focused fieldset": {
        border: "1px solid #C9CCD1",
      },
    },
    // "&.MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input": {
    //   padding: "10.5px",
    // },
    "&.MuiFormControl-root .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline":
      {
        // borderColor: allColors.borderColor,
      },
  },
}));

const TextInput = ({
  inputType,
  placeholder,
  startIcon,
  endIcon,
  inputVal,
  inputName,
  handleChangeInput,
  multiline,
  minRows,
  disabled,
  paddingCustom,
  onlyRead,
}) => {
  const classes = useStyle();
  const { currentColor } = useContext(AppContext);
  return (
    <TextField
      type={inputType ? inputType : "text"}
      placeholder={placeholder}
      size="small"
      fullWidth
      name={inputName}
      // sx={{
      //   "& .MuiOutlinedInput-root": {
      //     color:
      //       currentColor == "#161616" ||
      //       currentColor == "#5A663E" ||
      //       currentColor == "#798952"
      //         ? "#fff"
      //         : appColor.black,
      //   },

      //   "& .MuiOutlinedInput-notchedOutline": {
      //     borderColor:
      //       currentColor == "#161616" ||
      //       currentColor == "#5A663E" ||
      //       currentColor == "#798952"
      //         ? "#fff"
      //         : appColor.black,
      //   },
      // }}
      sx={{
        "& .MuiOutlinedInput-input": {
          padding: paddingCustom ? paddingCustom : "10.5px",
        },
      }}
      value={inputVal}
      minRows={minRows}
      multiline={multiline}
      onChange={handleChangeInput}
      disabled={disabled}
      className={classes.inputField}
      InputProps={{
        readOnly: onlyRead,
        ...(startIcon && {
          startAdornment: (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
        }),
        ...(endIcon && {
          endAdornment: (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
        }),
      }}
    />
  );
};

export default TextInput;
