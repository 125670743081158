import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appIcons } from "../../../assets";
import AppFooter from "../../../components/AppFooter";
import AppHeader from "../../../components/AppHeader";
import Loader from "../../../components/Loader";
import SnackbarAlert from "../../../components/SnackbarAlert";
import { AppContext } from "../../../context";
import {
  fetchPackagesData,
  fetchPackagesHeading,
  getPromo,
  upgradePlan,
} from "../../../service/common";
import PlansAndPricingCard from "./PlansAndPricingCard";
import {
  LiveStreamerTable,
  PlansAndPricingTable,
  ProTable,
} from "./PlansAndPricingTable";
import UpgradePlanDilaog from "./UpgradePlanDialog";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

function Shop() {
  const navigate = useNavigate();
  const [free, setFree] = useState(null);
  const [gold, setGold] = useState(null);
  const [silver, setSilver] = useState(null);
  const [platinum, setPlatinum] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [packageHeading, setPackageHeading] = useState(null);
  const [promoData, setPromoData] = useState(null);
  const [promoValue, setPromoValue] = useState("");
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  var urlParams = new URLSearchParams(window.location.search);
  var packageName = urlParams.get("packageName");
  var duration = urlParams.get("duration");
  const { setUserData } = useContext(AppContext);
  const userData = JSON.parse(localStorage.getItem("user"));
  let yesIcon = <img src={appIcons.tickIcon} width={"18px"} />;
  let noIcon = <img src={appIcons.crossIcon} width={"18px"} />;

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    if (packageName == "cancel" && packageName !== null) {
      handleSnackbarVal(
        true,
        "error",
        "Error purchasing while paying. Please try again letter"
      );
    } else {
      if (packageName !== "" && packageName !== null) {
        const user = JSON.parse(localStorage.getItem("user"));
        var currentDate = moment();
        var oneMonthLater = currentDate.add(1, "months");
        var formattedMonth = oneMonthLater.format("YYYY-MM-DD");
        var oneYearLater = currentDate.add(1, "years");
        var formattedYear = oneYearLater.format("YYYY-MM-DD");
        let payload = {
          package: packageName,
          duration: duration,
          ...(duration === "monthly" && { expiryDate: formattedMonth }),
          ...(duration === "yearly" && { expiryDate: formattedYear }),
        };
        upgradePlan(user?._id, payload)
          .then((res) => {
            setIsLoading(false);
            if (res?.data?.status == "ok") {
              let data = res?.data?.data;
              setUserData(data);
              localStorage.setItem("user", JSON.stringify(data));
              handleSnackbarVal(true, "success", res?.data?.message);
              navigate("/shop");
            } else {
              handleSnackbarVal(true, "error", res?.data?.message);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            handleSnackbarVal(true, "error", err?.message);
          });
      }
    }

    getPackagData();
    getPackagesHeading();
    fetchPromo();
  }, []);

  const getPackagData = () => {
    setIsLoading(true);
    fetchPackagesData()
      .then((ress) => {
        setIsLoading(false);
        if (ress?.data?.status == "ok") {
          console.log(ress, "ressressress");
          let data = ress?.data?.data;
          setFree(data[0]);
          setSilver(data[1]);
          setGold(data[2]);
          setPlatinum(data[3]);
        }
      })
      .catch((errr) => {
        setIsLoading(false);
      });
  };

  const getPackagesHeading = () => {
    setIsLoading(true);
    fetchPackagesHeading()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setPackageHeading(data);
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  const handleChange = (event) => {
    setIsLoading(true);
    setChecked(event.target.checked);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const dumyCardData = [
    {
      heading: "Free",
      amount: checked
        ? `${free?.price ? free?.price * 12 : "00"}/year`
        : `${free?.price ? free?.price : "00"}/month`,
      innerTitle: `Up to ${free?.comments ? free?.comments : "00"} Comments`,
      btnText: userData?.package === "Free" ? "Current Plan" : "Select Plan",
      cardColor: userData?.package === "Free" ? "#DD2A7B" : "#FFD6EB",
      packageAmount: checked ? free?.price * 12 : free?.price,
      options: [
        {
          icon: "tick",
          text: "Instagram Giveaways",
        },
        {
          icon: "tick",
          text: "Youtube Giveaways",
        },
        {
          icon: "tick",
          text: "Tiktok Giveaways",
        },
        {
          icon: "tick",
          text: "Twitter Giveaways",
        },
        {
          icon: "tick",
          text: "Facebook Giveaways",
        },
        {
          icon: "tick",
          text: "Bonus Entries",
        },
        {
          icon: "cross",
          text: "Block Accounts",
        },
        {
          icon: "cross",
          text: "Branding",
        },
        {
          icon: "cross",
          text: "Countdown Clock",
        },
        {
          icon: "cross",
          text: "Multi-Network Giveaways",
        },
      ],
    },
    {
      heading: "Silver",
      amount: checked
        ? `${silver?.price ? silver?.price * 12 : "00"}/year`
        : `${silver?.price ? silver?.price : "00"}/month`,
      innerTitle: `Up to ${
        silver?.comments ? silver?.comments : "00"
      } Comments`,
      btnText: userData?.package === "Silver" ? "Current Plan" : "Select Plan",
      cardColor: userData?.package === "Silver" ? "#DD2A7B" : "#FFD6EB",
      packageAmount: checked ? silver?.price * 12 : silver?.price,
      options: [
        {
          icon: "tick",
          text: "Instagram Giveaways",
        },
        {
          icon: "tick",
          text: "Youtube Giveaways",
        },
        {
          icon: "tick",
          text: "Tiktok Giveaways",
        },
        {
          icon: "tick",
          text: "Twitter Giveaways",
        },
        {
          icon: "tick",
          text: "Facebook Giveaways",
        },
        {
          icon: "tick",
          text: "Bonus Entries",
        },
        {
          icon: "tick",
          text: "Block Accounts",
        },
        {
          icon: "cross",
          text: "Branding",
        },
        {
          icon: "cross",
          text: "Countdown Clock",
        },
        {
          icon: "cross",
          text: "Multi-Network Giveaways",
        },
      ],
    },
    {
      heading: "Gold",
      amount: checked
        ? `${gold?.price ? gold?.price * 12 : "00"}/year`
        : `${gold?.price ? gold?.price : "00"}/month`,
      innerTitle: `Up to ${gold?.comments ? gold?.comments : "00"} Comments`,
      btnText: userData?.package === "Gold" ? "Current Plan" : "Select Plan",
      cardColor: userData?.package === "Gold" ? "#DD2A7B" : "#FFD6EB",
      packageAmount: checked ? gold?.price * 12 : gold?.price,
      options: [
        {
          icon: "tick",
          text: "Instagram Giveaways",
        },
        {
          icon: "tick",
          text: "Youtube Giveaways",
        },
        {
          icon: "tick",
          text: "Tiktok Giveaways",
        },
        {
          icon: "tick",
          text: "Twitter Giveaways",
        },
        {
          icon: "tick",
          text: "Facebook Giveaways",
        },
        {
          icon: "tick",
          text: "Bonus Entries",
        },
        {
          icon: "tick",
          text: "Block Accounts",
        },
        {
          icon: "tick",
          text: "Branding",
        },
        {
          icon: "tick",
          text: "Countdown Clock",
        },
        {
          icon: "cross",
          text: "Multi-Network Giveaways",
        },
      ],
    },
    {
      heading: "Platinum",
      amount: checked
        ? `${platinum?.price ? platinum?.price * 12 : "00"}/year`
        : `${platinum?.price ? platinum?.price : "00"}/month`,
      innerTitle: `Up to ${platinum?.comments ? "Unlimited" : "00"} Comments`,
      btnText:
        userData?.package === "Platinum" ? "Current Plan" : "Select Plan",
      cardColor: userData?.package === "Platinum" ? "#DD2A7B" : "#FFD6EB",

      packageAmount: checked ? platinum?.price * 12 : platinum?.price,

      options: [
        {
          icon: "tick",
          text: "Instagram Giveaways",
        },
        {
          icon: "tick",
          text: "Youtube Giveaways",
        },
        {
          icon: "tick",
          text: "Tiktok Giveaways",
        },
        {
          icon: "tick",
          text: "Twitter Giveaways",
        },
        {
          icon: "tick",
          text: "Facebook Giveaways",
        },
        {
          icon: "tick",
          text: "Bonus Entries",
        },
        {
          icon: "tick",
          text: "Block Accounts",
        },
        {
          icon: "tick",
          text: "Branding",
        },
        {
          icon: "tick",
          text: "Countdown Clock",
        },
        {
          icon: "tick",
          text: "Multi-Network Giveaways",
        },
      ],
    },
  ];

  const firstHeader = ["", "Free", "Silver", "Gold", "Platinum"];
  const firstBodyData = [
    {
      type: "Instagram Giveaway",
      free: "Up to 600 Comments",
      silver: "Up to 2,000 Comments",
      gold: "Up to 5,000 Comments",
      Platinum: "Up to unlimited Comments",
      toltip: "",
    },
    {
      type: "youtube Giveaway",
      free: `Up to ${free?.comments ? free?.comments : "00"} Comments`,
      silver: `Up to ${silver?.comments ? silver?.comments : "00"} Comments`,
      gold: `Up to ${gold?.comments ? gold?.comments : "00"} Comments`,
      Platinum: `Up to ${platinum?.comments ? "unlimited" : "00"} Comments`,
      toltip: "",
    },
    {
      type: "Tiktok Giveaway",
      free: "Up to 600 Comments",
      silver: "Up to 2,000 Comments",
      gold: "Up to 5,000 Comments",
      Platinum: "Up to unlimited Comments",
      toltip: "",
    },
    {
      type: "Twitter Giveaway",
      free: "Up to 600 Comments",
      silver: "Up to 2,000 Comments",
      gold: "Up to 5,000 Comments",
      Platinum: "Up to unlimited Comments",
      toltip: "",
    },
    {
      type: "Facebook Giveaway",
      free: "Up to 600 Comments",
      silver: "Up to 2,000 Comments",
      gold: "Up to 5,000 Comments",
      Platinum: "Up to unlimited Comments",
      toltip: "",
    },

    {
      type: "Winners",
      free: "1-99",
      silver: "1-99",
      gold: "1-99",
      Platinum: "1-99",
      toltip: "Select the number of winners from 1-99",
    },
    {
      type: "Substitutes",
      free: "1-99",
      silver: "1-99",
      gold: "1-99",
      Platinum: "1-99",
      toltip: "Select the number of substitutes from 1-99",
    },
    {
      type: "Exclude Duplicate Entries",
      free: free?.duplicate ? yesIcon : noIcon,
      silver: silver?.duplicate ? yesIcon : noIcon,
      gold: gold?.duplicate ? yesIcon : noIcon,
      Platinum: platinum?.duplicate ? yesIcon : noIcon,
      toltip: "Remove duplicates to give everyone an equal chance",
    },
    {
      type: "Filter Entries By Hashtag",
      free: free?.hashtag ? yesIcon : noIcon,
      silver: silver?.hashtag ? yesIcon : noIcon,
      gold: gold?.hashtag ? yesIcon : noIcon,
      Platinum: platinum?.hashtag ? yesIcon : noIcon,
      toltip: "Select winners using particular hashtag",
    },
    {
      type: " Multi-Network Giveaway ",
      free: free?.multiNetwork ? yesIcon : noIcon,
      silver: silver?.multiNetwork ? yesIcon : noIcon,
      gold: gold?.multiNetwork ? yesIcon : noIcon,
      Platinum: platinum?.multiNetwork ? yesIcon : noIcon,
      toltip: "Multi-Network Giveaway",
    },
  ];

  const secondHeader = [
    packageHeading?.heading2
      ? `${packageHeading.heading2} \u{1F6C8}`
      : "Select PRO Features",
    "",
    "",
    "",
    "",
  ];
  const secondBodyData = [
    {
      type: "Bonus Entries",
      free: free?.extraChance ? yesIcon : noIcon,
      silver: silver?.extraChance ? yesIcon : noIcon,
      gold: gold?.extraChance ? yesIcon : noIcon,
      Platinum: platinum?.extraChance ? yesIcon : noIcon,
      toltip: "Include specific users to get an extra shot of winning",
    },
    {
      type: "Block Accounts",
      free: free?.blockList ? yesIcon : noIcon,
      silver: silver?.blockList ? yesIcon : noIcon,
      gold: gold?.blockList ? yesIcon : noIcon,
      Platinum: platinum?.blockList ? yesIcon : noIcon,
      toltip: "Block specific users from winning ",
    },
  ];

  const thirdHeader = [
    `${
      packageHeading?.heading3
        ? `${packageHeading?.heading3} \u{1F6C8}`
        : "Live Streamer PRO Features "
    }`,
    "",
    "",
    "",
    "",
  ];
  const thirdBodyData = [
    {
      type: "Branding",
      free: free?.branding ? yesIcon : noIcon,
      silver: silver?.branding ? yesIcon : noIcon,
      gold: gold?.branding ? yesIcon : noIcon,
      Platinum: platinum?.branding ? yesIcon : noIcon,
      toltip: " Show off your brand colors and logo with this feature",
    },
    {
      type: " Countdown Clock",
      free: free?.countDown ? yesIcon : noIcon,
      silver: silver?.countDown ? yesIcon : noIcon,
      gold: gold?.countDown ? yesIcon : noIcon,
      Platinum: platinum?.countDown ? yesIcon : noIcon,
      toltip: "Great for livestreaming – so suspenseful! ",
    },
    // {
    //   type: " Multi-Network Giveaway ",
    //   free: free?.multiNetwork ? yesIcon : noIcon,
    //   silver: silver?.multiNetwork ? yesIcon : noIcon,
    //   gold: gold?.multiNetwork ? yesIcon : noIcon,
    //   Platinum: platinum?.multiNetwork ? yesIcon : noIcon,
    //   toltip: "Multi-Network Giveaway",
    // },
  ];

  const handleOpenDialog = (data) => {
    if (userData) {
      if (data?.btnText !== "Current Plan") {
        setOpenDialog(true);
        setDialogData(data);
      } else {
        handleSnackbarVal(
          true,
          "error",
          `You are already using ${data?.heading} plan`
        );
      }
    } else {
      navigate("/login");
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData(null);
    setPromoValue("");
    setPromoDiscount(0);
    setTotalPrice(0);
  };

  const fetchPromo = () => {
    setIsLoading(true);
    getPromo()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setPromoData(data);
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", "Somthing went wrong");
      });
  };

  return (
    <Box>
      <AppHeader>
        <Loader isloading={isLoading} />
        <SnackbarAlert
          snackbarProps={snackbarProps}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <Divider />
        <Container sx={{ maxWidth: "1250px !important", marginTop: "30px" }}>
          <Typography
            variant={"h6"}
            fontWeight={700}
            fontSize={28}
            fontFamily={"Roboto Flex"}
            textAlign={"center"}
          >
            🤑 Plans & Pricing
          </Typography>
          <Typography
            variant={"body1"}
            fontWeight={400}
            fontSize={12}
            fontFamily={"Roboto Flex"}
            textAlign={"center"}
          >
            Pick the plan that’s right for you
          </Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <PinkSwitch checked={checked} onChange={handleChange} />
            <Typography
              variant={"body1"}
              fontWeight={500}
              fontSize={15}
              fontFamily={"Roboto Flex"}
              textAlign={"center"}
            >
              {checked ? "Buy yearly Plan" : "Buy Monthly Plan"}
            </Typography>
          </Stack>

          <Typography
            variant={"body1"}
            fontWeight={400}
            fontSize={12}
            fontFamily={"Roboto Flex"}
            textAlign={"center"}
          ></Typography>
          <Grid container spacing={3} mt={1}>
            {dumyCardData?.map((data) => {
              return (
                <Grid item xs={12} sm={6} md={3}>
                  <PlansAndPricingCard
                    data={data}
                    openDialog={handleOpenDialog}
                  />
                </Grid>
              );
            })}
          </Grid>

          {promoData?.isAvailable && (
            <Typography
              mt={3}
              variant={"body1"}
              fontWeight={500}
              fontSize={18}
              fontFamily={"Roboto Flex"}
              textAlign={"center"}
            >
              🎊🎊 {promoData?.promoText}
            </Typography>
          )}

          <Typography
            mt={6}
            variant={"h6"}
            fontWeight={700}
            fontSize={28}
            fontFamily={"Roboto Flex"}
            textAlign={"center"}
          >
            💸 Compare our plans side by side
          </Typography>
          <Typography
            mb={3}
            variant={"body1"}
            fontWeight={400}
            fontSize={12}
            fontFamily={"Roboto Flex"}
            textAlign={"center"}
          >
            Effortlessly view plan options to make an informed decision
          </Typography>
          <Box
            borderRadius={"15px"}
            border={"1px solid black"}
            overflow={"hidden"}
            mb={2}
          >
            <PlansAndPricingTable
              tableHeader={firstHeader}
              bodyData={firstBodyData}
            />
            <ProTable tableHeader={secondHeader} bodyData={secondBodyData} />
            <LiveStreamerTable
              tableHeader={thirdHeader}
              bodyData={thirdBodyData}
            />
          </Box>
        </Container>
        <UpgradePlanDilaog
          open={openDialog}
          handleClose={handleCloseDialog}
          dialogData={dialogData}
          setIsLoading={setIsLoading}
          handleSnackbarVal={handleSnackbarVal}
          promoData={promoData}
          duration={checked ? "yearly" : "monthly"}
          promoValue={promoValue}
          setPromoValue={setPromoValue}
          promoDiscount={promoDiscount}
          setPromoDiscount={setPromoDiscount}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
        />
        <Container sx={{ maxWidth: "1200px !important", marginTop: "5px" }}>
          <AppFooter />
        </Container>
      </AppHeader>
    </Box>
  );
}

export default Shop;
