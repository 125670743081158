import { Box, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../context";

function ImageUploader({ picture, alt, width, height }) {
  const { imageLoading } = useContext(AppContext);

  return (
    <Box
      height={width ? width : 35}
      width={height ? height : 35}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={"50%"}
      //   border={"1px solid #f3f3f3 "}
      overflow={"hidden"}
    >
      {imageLoading ? (
        <CircularProgress size={25} />
      ) : (
        <img
          src={picture}
          alt={alt}
          //   onLoad={handleLoadPicture}
          width={"100%"}
          height={"100%"}
        />
      )}
    </Box>
  );
}

export default ImageUploader;
