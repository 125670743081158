import * as React from "react";
import { TableStyle } from "./Style";
import {
  Tooltip,
  tooltipClasses,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
    backgroundColor: "#1B1D28",
    textAlign: "center",
    padding: "3px 5px",
    fontSize: 10,
    fontWeight: 300,
  },
}));

const BootstrapTooltip1 = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    width: 250,
    backgroundColor: "#1B1D28",
    // textAlign: "center",
    padding: "3px 5px",
    fontSize: 13,
    fontWeight: 400,
  },
}));

function PlansAndPricingTable({ tableHeader, bodyData }) {
  const classes = TableStyle();
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <TableContainer component={Paper} className={classes.styleTable}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeader.map((header) => {
              return <TableCell>{header}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyData.map((row, index) => (
            <TableRow
              key={index}
              //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <BootstrapTooltip title={row.toltip} placement={"top"}>
                  {row.type}{" "}
                  {row.type === "Facebook Giveaway" && (
                    <span
                      style={{
                        fontSize: "10px",
                        fontStyle: "italic",
                        color: "red",
                      }}
                    >
                      comming soon
                    </span>
                  )}
                </BootstrapTooltip>
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.free}
                {/* </BootstrapTooltip> */}
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.silver}
                {/* </BootstrapTooltip> */}
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.gold}
                {/* </BootstrapTooltip> */}
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.Platinum}
                {/* </BootstrapTooltip> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ProTable({ tableHeader, bodyData }) {
  const classes = TableStyle();
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <TableContainer component={Paper} className={classes.styleTable}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              Select PRO Features{" "}
              <BootstrapTooltip1
                placement={"top"}
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                title={
                  <>
                    <p> Bonus Entries:</p>
                    <p style={{ fontSize: 10, fontWeight: 300 }}>
                      • Include specific user to get an extra shot at winning
                    </p>
                    <p> Block Accounts:</p>
                    <p style={{ fontSize: 10, fontWeight: 300 }}>
                      • Block Specific users from winning
                    </p>
                  </>
                }
              >
                <InfoIcon
                  sx={{ fontSize: 20, marginBottom: "-4px", cursor: "pointer" }}
                  onClick={() => setShowTooltip(!showTooltip)}
                />
              </BootstrapTooltip1>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            {/* {tableHeader.map((header) => {
              return <TableCell>{header}</TableCell>;
            })} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyData.map((row, index) => (
            <TableRow
              key={index}
              //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <BootstrapTooltip title={row.toltip}>
                  {row.type}
                </BootstrapTooltip>
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.free}
                {/* </BootstrapTooltip> */}
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.silver}
                {/* </BootstrapTooltip> */}
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.gold}
                {/* </BootstrapTooltip> */}
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.Platinum}
                {/* </BootstrapTooltip> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function LiveStreamerTable({ tableHeader, bodyData }) {
  const classes = TableStyle();
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <TableContainer component={Paper} className={classes.styleTable}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              Live Streamer PRO Features
              <BootstrapTooltip1
                placement={"top"}
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                title={
                  <>
                    <p>Branding:</p>
                    <p style={{ fontSize: 10, fontWeight: 300 }}>
                      • Show off your brand colors and logo with this feature
                    </p>
                    <p> Countdown clock:</p>
                    <p style={{ fontSize: 10, fontWeight: 300 }}>
                      • Great for livestreaming – so suspenseful!
                    </p>
                  </>
                }
              >
                <InfoIcon
                  sx={{ fontSize: 20, marginBottom: "-4px", cursor: "pointer" }}
                  onClick={() => setShowTooltip(!showTooltip)}
                />
              </BootstrapTooltip1>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            {/* {tableHeader.map((header) => {
              return <TableCell>{header}</TableCell>;
            })} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyData.map((row, index) => (
            <TableRow
              key={index}
              //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <BootstrapTooltip title={row.toltip}>
                  {row.type}
                </BootstrapTooltip>
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.free}
                {/* </BootstrapTooltip> */}
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.silver}
                {/* </BootstrapTooltip> */}
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.gold}
                {/* </BootstrapTooltip> */}
              </TableCell>
              <TableCell align="center">
                {/* <BootstrapTooltip title={row.toltip}> */}
                {row.Platinum}
                {/* </BootstrapTooltip> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export { ProTable, PlansAndPricingTable, LiveStreamerTable };
