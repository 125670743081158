import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appIcons } from "../../../assets";
import AppFooter from "../../../components/AppFooter";
import AppHeader from "../../../components/AppHeader";
import CustomButton from "../../../components/CustomButton";
import GotoLogin from "../../../components/GotoLogin";
import Loader from "../../../components/Loader";
import SnackbarAlert from "../../../components/SnackbarAlert";
import { AppContext } from "../../../context";
import { setAppFilters } from "../../../redux/slices/GiveawaySlice";
import { setFilters } from "../../../redux/slices/historySlice";
import { getVideoHistoryById } from "../../../service/comments";
import { DashboardStyle } from "./Style";
import DeleteDialog from "./deleteDialog";
import moment from "moment";

const giveAway = [
  { giveawayType: "all", giveAway: "All" },
  { giveawayType: "instagram", giveAway: "Instagram Giveaway" },
  { giveawayType: "facebook", giveAway: "Facebook Giveaway" },
  { giveawayType: "randomname", giveAway: "Random Name Winner" },
  { giveawayType: "twitter", giveAway: "Twitter Giveaway" },
  { giveawayType: "multinetwork", giveAway: "Multi-Network Giveaway" },
  { giveawayType: "tiktok", giveAway: "Tiktok Giveaway" },
  { giveawayType: "wheeldecide", giveAway: "Wheel Decide" },
  { giveawayType: "triviamaker", giveAway: "Trivia Maker" },
  { giveawayType: "youtube", giveAway: "Youtube GiveAway" },
  { giveawayType: "randomnumbers", giveAway: "Random Numbers" },
];

const dateWise = [
  "Last 3 months",
  "Last 6 months",
  "Last 12 months",
  "All Times",
];

const addEditModalStyle = {
  elevation: 0,
  sx: {
    overflow: "visible",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE)",
    boxShadow: "none",
    border: "1px solid black",
    width: "90px",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "& .MuiMenuItem-root": {
      fontSize: "13px",
      padding: "3px 14px",
      "&:hover": {
        background: "transparent",
      },
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 10,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
      borderLeft: "1px solid black",
      borderTop: "1px solid black",
    },
  },
};

function Dashboard() {
  const classes = DashboardStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allGiveaway, setAllGiveaway] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterGiveaway, setFilterGiveaway] = useState([]);
  const [giveAwayType, setGiveAwayType] = useState("all");
  const [filterByDate, setFilterByDate] = useState("Last 3 months");
  const {
    setCurrentColor,
    setYoutubeData,
    setTweetData,
    userData,
    setPostData,
    setWin,
    setSubs,
  } = useContext(AppContext);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };
  const open = Boolean(anchorEl);

  const handleClick = (event, data) => {
    setDialogData(data);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCLoseDelete = () => {
    setOpenDelete(false);
    setDialogData(null);
  };

  useEffect(() => {
    let params = {
      giveawayType: "",
      dublicate: false,
      hashtag: false,
      block: false,
      extraChance: false,
      extraChanceNames: [],
      blockListNames: [],
      hashtagText: "",
      counter: 5,
      totalWinners: 1,
      totalSubstitutes: 0,
      actionType: "add",
      allComments: [],
      currentColor: "#ffffff",
      giveAwayId: "",
      twitterType: "",
    };
    dispatch(setFilters(params));
    if (userData) {
      getVideoHistory(userData?._id);
    }
  }, [userData]);

  const getVideoHistory = (_id) => {
    setIsLoading(true);
    getVideoHistoryById(_id)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status == "ok") {
          let data = res?.data?.data;
          setAllGiveaway(data);
          setFilterGiveaway(data);
        } else {
          handleSnackbarVal(true, "error", res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleSnackbarVal(true, "error", err?.message);
      });
  };

  console.log(dialogData, "dialogData");

  const handleEdit = (data) => {
    let params = {
      dublicate: dialogData?.isDublicate,
      hashtag: dialogData?.ishashtag,
      block: dialogData?.isBlockList,
      extraChance: dialogData?.isExtraBonus,
      extraChanceNames: dialogData?.extraBonusNames,
      blockListNames: dialogData?.blockListNames,
      hashtagText: dialogData?.hashtagValue,
      counter: dialogData?.counter,
      totalWinners: dialogData?.winnersCount,
      totalSubstitutes: dialogData?.substitutesCount,
      actionType: "edit",
      giveAwayId: dialogData?._id,
      giveawayType: dialogData?.giveawayType,
      allComments: [],
      currentColor: "#ffffff",
      twitterType: dialogData?.twitterType,
    };
    setWin(dialogData?.winners);
    setSubs(dialogData?.substitutes);
    dispatch(setAppFilters(params));
    setCurrentColor(dialogData?.currentColor);

    if (dialogData?.giveawayType === "Youtube") {
      let newObj = {
        chanelName: dialogData?.channelName,
        commentCount: dialogData?.commentCount,
        likeCount: dialogData?.likeCount,
        profilePic: "",
        thumbnail: dialogData?.videoThumbnail,
        title: dialogData?.videoTitle,
        viewCount: dialogData?.viewCount,
        youtubeLink: dialogData?.videoLink,
        videoId: dialogData?.contentId,
      };
      handleClose();
      // navigate("/youtube-giveaway/comment-filters");
      navigate("/youtube-giveaway/certificate-winners");
      setYoutubeData(newObj);
      localStorage.setItem("video-Info", JSON.stringify(newObj));
    } else if (dialogData?.giveawayType === "Tiktok") {
      let newPostData = {
        commentCount: dialogData?.commentCount,
        likeCount: dialogData?.likeCount,
        postUrl: dialogData?.videoLink,
        shareCount: dialogData?.viewCount,
        thumbnail: dialogData?.videoThumbnail,
        title: dialogData?.videoTitle,
        username: dialogData?.channelName,
        videoId: dialogData?.contentId,
      };
      setPostData(newPostData);
      handleSnackbarVal(true, "error", "We are working on it");
      navigate("/tiktok-giveaway/certificate-winners");
      localStorage.setItem("post_data", JSON.stringify(newPostData));
    } else if (dialogData?.giveawayType === "Instagram") {
      let params = {
        username: dialogData?.channelName,
        comments_count: dialogData?.commentCount,
        permalink: dialogData?.videoLink,
        caption: dialogData?.videoTitle,
      };
      setPostData(params);
      navigate("/instagram-giveaway/certificate-winners");
      handleClose();
    } else {
      let newObj = {
        authorName: dialogData?.channelName,
        commentCount: dialogData?.commentCount,
        likeCount: dialogData?.likeCount,
        authorProfileImage: "",
        thumbnail: dialogData?.videoThumbnail,
        title: dialogData?.videoTitle,
        viewCount: dialogData?.viewCount,
        twitterUrl: dialogData?.videoLink,
        tweetId: dialogData?.contentId,
      };
      handleClose();
      navigate("/twitter-giveaway/certificate-winners");
      setTweetData(newObj);
      localStorage.setItem("twitter-post", JSON.stringify(newObj));
    }
  };

  const handleFilter = (e) => {
    setGiveAwayType(e.target.value);
    if (e.target.value == "all") {
      setFilterGiveaway(allGiveaway);
    } else {
      const filtered = allGiveaway.filter(
        (data) => data.giveawayType.toLowerCase() === e.target.value
      );
      setFilterGiveaway(filtered);
    }
  };

  const handleOpenDeleteDialog = () => {
    setOpenDelete(true);
    handleClose();
  };

  return (
    <>
      <AppHeader>
        <Divider />
        <Loader isloading={isLoading} />
        <SnackbarAlert
          snackbarProps={snackbarProps}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        {!userData ? (
          <GotoLogin />
        ) : (
          <Container sx={{ maxWidth: "1250px !important", marginTop: "30px" }}>
            {allGiveaway.length > 0 ? (
              <Grid container spacing={3} mb={5}>
                <Grid item xs={12} sm={2}>
                  <InputLabel className={classes.inputLabel}>Type</InputLabel>
                  <FormControl fullWidth className={classes.muiSelect}>
                    <Select
                      value={giveAwayType}
                      onChange={handleFilter}
                      size={"small"}
                      MenuProps={{
                        classes: {
                          paper: classes.selectMenu,
                        },
                      }}
                    >
                      {giveAway.map((data) => {
                        return (
                          <MenuItem value={data.giveawayType}>
                            {data.giveAway}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <InputLabel className={classes.inputLabel}>Date</InputLabel>
                  <FormControl fullWidth className={classes.muiSelect}>
                    <Select
                      value={filterByDate}
                      onChange={(e) => setFilterByDate(e.target.value)}
                      size={"small"}
                      MenuProps={{
                        classes: {
                          paper: classes.selectMenu,
                        },
                      }}
                    >
                      {dateWise.map((data) => {
                        return <MenuItem value={data}>{data}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ) : null}

            {filterGiveaway?.map((data, index) => {
              return (
                <Box
                  className={classes.givawayCon}
                  justifyContent={
                    data.status === "Completed" ? "space-between" : "flex-start"
                  }
                  key={index}
                >
                  <Box>
                    <img
                      src={
                        data?.giveawayType === "Twitter"
                          ? appIcons.twitterColorIcon
                          : data?.giveawayType === "Tiktok"
                          ? appIcons.tikTokColorIcon
                          : data?.giveawayType === "Instagram"
                          ? appIcons.instagramColorIcon
                          : appIcons.youtubeIcon
                      }
                      width={"25px"}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant={"h6"}
                      fontWeight={600}
                      fontSize={"14px"}
                    >
                      {data?.videoTitle}
                    </Typography>
                    <Stack direction={"row"} alignItems={"center"} spacing={2}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={"5px"}
                      >
                        <img src={appIcons.calendorIcon} width={"15px"} />
                        <Typography variant={"body1"} fontSize={10}>
                          {moment(data?.createdAt).format("Do-MMMM-YYYY")}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={"3px"}
                      >
                        <img src={appIcons.persoinIcon} width={"15px"} />
                        <Typography variant={"body1"} fontSize={10}>
                          {data?.channelName}
                        </Typography>
                      </Stack>{" "}
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={"3px"}
                      >
                        <img src={appIcons.commentIcon} width={"15px"} />
                        <Typography variant={"body1"} fontSize={10}>
                          {data?.commentCount}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={"3px"}
                      >
                        {/* <img src={appIcons.commentIcon} width={"15px"} /> */}
                        <Typography variant={"body1"} fontSize={10}>
                          {data?.status}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>

                  {data.status === "Completed" && (
                    <Box display={"flex"} flex={1} justifyContent={"flex-end"}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <IconButton
                          onClick={(e) => handleClick(e, data)}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Box>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={addEditModalStyle}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem onClick={handleEdit}>Edit</MenuItem>
                        <MenuItem onClick={handleOpenDeleteDialog}>
                          Delete
                        </MenuItem>
                      </Menu>
                    </Box>
                  )}
                </Box>
              );
            })}
            {filterGiveaway.length < 1 && (
              <Box mt={8}>
                <Typography
                  variant={"h5"}
                  fontWeight={600}
                  textAlign={"center"}
                >
                  Create your first Giveaway
                </Typography>
                <Typography
                  variant={"body1"}
                  fontWeight={400}
                  fontSize={"14px"}
                  textAlign={"center"}
                >
                  Create giveaways for Youtube, Facebook, Tiktok, Lists and more
                </Typography>
                <Box width={"160px"} height={40} margin={"20px auto"}>
                  <CustomButton
                    btnText={"Create"}
                    handleClickBtn={() => navigate("/")}
                  />
                </Box>
              </Box>
            )}
          </Container>
        )}

        {/* <Box display={"flex"} justifyContent={"center"}>
          <Container
            sx={{
              maxWidth: "1200px !important",
              marginTop: "10px",

              position: {
                xs: "relative",
                md: filterGiveaway.length > 3 ? "relative" : "absolute",
              },
              bottom: 0,
            }}
          >
            <AppFooter />
          </Container>
        </Box> */}
        <DeleteDialog
          open={openDelete}
          setOpen={setOpenDelete}
          handleClose={handleCLoseDelete}
          dialogData={dialogData}
          setIsLoading={setIsLoading}
          handleSnackbarVal={handleSnackbarVal}
          getVideoHistory={getVideoHistory}
        />
      </AppHeader>
    </>
  );
}

export default Dashboard;
