import { makeStyles } from "@mui/styles";
import { appFonts } from "../constants/Fonts";
import { appColor } from "../constants/Colors";

const GlobalStyle = makeStyles((theme) => ({
  inputField: {
    width: "70%",
    [theme.breakpoints.down(900)]: {
      width: "90%",
    },
    [theme.breakpoints.down(600)]: {
      width: "90%",
    },
    "&.MuiFormControl-root .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontSize: 13,
      borderRadius: "50px",
      paddingRight: "6px",
      "&.Mui-focused fieldset": {
        border: "2px solid #ebebeb",
      },
    },
    "&.MuiFormControl-root .MuiOutlinedInput-root .MuiOutlinedInput-input": {
      padding: "15px 18px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #d5d5d5b5 !important",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      "&:hover": {
        border: "2px solid ##97999D",
      },
    },
  },

  // all components home page style

  rahomeh1: {
    fontSize: "14px !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    fontFamily: `${appFonts.RobotoFlex} !important`,
    letterSpacing: "8px !important",
    marginBottom: "8px !important",
    [theme.breakpoints.down("sm")]: {
      letterSpacing: "4px !important",
    },
  },

  rahomeh2: {
    fontWeight: "900 !important",
    textTransform: "uppercase !important",
    letterSpacing: "2px !important",
    fontFamily: `${appFonts?.boldExtended} !important`,
  },

  rahomeh3: {
    fontSize: "14px !important",
    color: `${appColor.black} !important`,
    fontFamily: `${appFonts.RobotoFlex} !important`,
    margin: "20px 0px !important",
  },

  //post detaill

  raDetailH1: {
    fontSize: "28px !important",
    fontWeight: "600 !important",
    letterSpacing: "3px !important",
    fontFamily: `${appFonts.boldExtended} !important`,
    wordSpacing: "6px !important",
  },

  //detail h1 tag style
  detailH1: {
    marginBottom: "24px !important",
    fontWeight: "600 !important",
    textAlign: "center !important",
    letterSpacing: "3px !important",
    wordSpacing: "6px !important",
    fontSize: "28px !important",
    fontFamily: `${appFonts?.boldExtended} !important`,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px !important",
    },
  },

  //filterspage
  setMainCon: {
    borderRadius: "20px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },

  setHeadCon: {
    flexDirection: "row !important",
    padding: "12px !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },

  settingHeader: {
    fontSize: "18px !important",
    fontWeight: "600 !important",
  },

  //stack Class
  stackClass: {
    flexDirection: "row !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },

  //global
  raBackButton: {
    fontSize: "12px !important",
    fontWeight: "400 !important",
    color: `${appColor.black} !important`,
    textDecoration: "underline !important",
    cursor: "pointer !important",
  },

  raModify: {
    fontSize: "13px !important",
    fontWeight: "600 !important",
    color: `${appColor.black} !important`,
    cursor: "pointer !important",
    textDecoration: "underline ",
    textDecorationThickness: "2px ",
    textDecorationColor: "#b3b3b3 ",
  },
}));

export default GlobalStyle;
