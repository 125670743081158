import { Box } from "@mui/material";
import React from "react";
import { appGifs } from "../assets";
import "./style.css"

function Instawave({}) {
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <img src={appGifs?.instaWave} height={"40px"} className="rotate-wave" />
      <img src={appGifs?.instaWave} height={"40px"} className="rotate-wave" />
      <img src={appGifs?.instaWave} height={"40px"} className="rotate-wave" />
      <img src={appGifs?.instaWave} height={"40px"} className="rotate-wave" />
      {/* <img src={appGifs?.instaWave} height={"40px"} className="rotate-wave" /> */}
    </Box>
  );
}

export default Instawave;
