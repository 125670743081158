import { makeStyles } from "@mui/styles";

export const DashboardStyle = makeStyles((theme) => ({
  inputLabel: {
    fontSize: "11px !important",
    marginBottom: "5px",
  },
  muiSelect: {
    "&.MuiFormControl-root .MuiOutlinedInput-root": {
      borderRadius: "8px",
      color: "#5e5c5cde",
      fontSize: "13px",
      "&.Mui-focused fieldset": {
        border: "1px solid #C9CCD1",
      },
    },
  },
  selectMenu: {
    "&.MuiMenu-paper": {
      background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE)",
      boxShadow: "none",
      border: "1px solid #d9d4d4",
    },
    "&.MuiMenu-paper .MuiList-root": {
      padding: "0px",
    },
    "&.MuiMenu-paper .MuiList-root .MuiMenuItem-root": {
      borderBottom: "1px solid #d9d4d4",
      padding: "4px 15px 4px 15px",
      fontSize: "12px",
      color: "#000",
    },
    "&.MuiMenu-paper .MuiList-root .Mui-selected": {
      color: "#1377F8",
      background: "transparent",
    },
  },

  givawayCon: {
    border: "1px solid black",
    padding: "12px 20px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    gap: "10px",
    flexWrap: "nowrap",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
}));
