import React, { useContext } from "react";
import { SketchPicker } from "react-color";
import { AppContext } from "../context";

const ColorPickerComponent = ({ handleSnakbarVal }) => {
  const { currentColor, setCurrentColor, userData } = useContext(AppContext);
  console.log(userData, "sdasdasdasdasdasdmmmm");

  const handleChangeComplete = (pass) => {
    if (userData?.branding) {
      setCurrentColor(pass?.hex);
    } else {
      handleSnakbarVal(true, "error", "Upgrade your plan to use this feature");
    }
  };

  return (
    <SketchPicker
      color={currentColor}
      onChangeComplete={handleChangeComplete}
    />
  );
};

export default ColorPickerComponent;
