import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appIcons, appImages } from "../../../../assets";
import AnimatedLoader from "../../../../components/AnimatedLoader";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import BussinessAccountDialog from "../../../../components/BussinessAccountDialog";
import Loader from "../../../../components/Loader";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import TiktokLoader from "../../../../components/TiktokLoader";
import UrlInput from "../../../../components/urlInput";
import { AppContext } from "../../../../context";
import GlobalStyle from "../../../../globalStyle";
import { setAppFilters } from "../../../../redux/slices/GiveawaySlice";
import "./style.css";
import { getTiktokVideoData } from "../../../../service/comments";
import CustomButton from "../../../../components/CustomButton";
import Swal from "sweetalert2";

let params = {
  giveawayType: "Tiktok",
  dublicate: false,
  hashtag: false,
  block: false,
  extraChance: false,
  extraChanceNames: [],
  blockListNames: [],
  hashtagText: "",
  counter: 5,
  totalWinners: 1,
  totalSubstitutes: 0,
  actionType: "add",
  allComments: [],
  currentColor: "#ffffff",
  giveAwayId: "",
};

/* global TikAPI */

function TikTokGivaway() {
  const classes = GlobalStyle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [tiktokLink, setTikTokLink] = useState("");
  const { setCurrentColor, setCountDown, setPostData } = useContext(AppContext);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    dispatch(setAppFilters(params));
    setCurrentColor("#fff");
    setCountDown(5);
  }, []);

  const handleConnetToTikTok = () => {
    TikAPI.popup({
      client_id: "c_U4EAN8CYVF",
      scope: ["view_profile", "explore", "media_actions"],
    });
  };

  TikAPI.onLogin(function (data) {
    console.log("TikTok User Data:", data);
    // Aap yahaan se user data ka istemal karein, jaise ki state mein set karna, Redux store mein dispatch karna, etc.
  });

  const handleGetVideoData = () => {
    let VIDEO_ID = "";
    let tikTokUrl = "https://www.tiktok.com/";
    let validUrl = tiktokLink.startsWith("https://www.tiktok.com/@");

    if (!tiktokLink) {
      handleSnackbarVal(true, "error", "Plesae enter video url");
      return;
    }

    if (tikTokUrl.toLowerCase() === tiktokLink.toLowerCase() || !validUrl) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "The URL you entered is incorrect.",
        confirmButtonColor: "#ff3600",
        customClass: {
          container: "custom-swal-container",
        },
      });
      return;
    }

    if (validUrl) {
      const regex = /\/video\/(\d+)/;
      const match = tiktokLink.match(regex);
      const videoId = match ? match[1] : null;
      setDataLoading(true);
      getTiktokVideoData(videoId)
        .then((response) => {
          setDataLoading(false);
          if (response?.data?.status === "ok") {
            let data = response?.data?.data;
            const newData = {
              ...data,
              videoId,
              postUrl: tiktokLink,
            };
            setPostData(newData);
            navigate("/tiktok-giveaway/video-details");
            localStorage.setItem("post_data", JSON.stringify(newData));
          } else {
            handleSnackbarVal(true, "error", response?.data?.message);
          }
        })
        .catch((error) => {
          setDataLoading(false);
          handleSnackbarVal(true, "error", "Something went wrong");
          console.log(error);
        });
    }
  };

  return (
    <Box>
      <AppHeader>
        <Divider />
        <SnackbarAlert
          snackbarProps={snackbarProps}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <Loader isloading={isLoading} />
        {dataLoading ? (
          <AnimatedLoader />
        ) : (
          <Container
            sx={{
              maxWidth: "1250px !important",
              marginTop: { xs: "10px", md: "70px" },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  position={"relative"}
                  sx={{ top: { xs: "0", md: "-50px" } }}
                >
                  <Box
                    margin={"auto"}
                    sx={{ width: { xs: "100%", sm: "85%" } }}
                  >
                    <img
                      src={appImages?.tiktokHome}
                      alt="Insagram Image"
                      width={"100%"}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant={"h4"} className={classes.rahomeh1}>
                  TikTok Comment Picker
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
                  <Typography
                    variant={"h1"}
                    className={classes.rahomeh2}
                    sx={{ fontSize: { xs: 30, sm: 40, lg: 50 } }}
                  >
                    Your Input,✍
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ gap: "80px" }}
                >
                  {/* <Box> */}
                  <TiktokLoader />
                  {/* </Box> */}
                  <Typography
                    variant={"h1"}
                    className={classes.rahomeh2}
                    sx={{ fontSize: { xs: 30, sm: 40, md: 45, lg: 50 } }}
                  >
                    our choice
                  </Typography>
                </Stack>
                <Typography variant={"body1"} className={classes.rahomeh3}>
                  Why delay? Come join us on this voyage where comments
                  transform into stars and where every voice has the opportunity
                  to sparkle and seize triumph.
                </Typography>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  {/* <Box height={45} sx={{ width: { xs: "100%", md: "280px" } }}>
                    <CustomButton
                      startIcon={
                        <img
                          src={appIcons.tikTokColorIcon}
                          width={"25px"}
                          style={{ zIndex: "999" }}
                        />
                      }
                      btnText={"Connect with Twitter"}
                      textWeight={400}
                      textSize={16}
                      iconSize={"15px !important"}
                      btnBackground={"#24ece7"}
                      handleClickBtn={handleConnetToTikTok}
                    />
                  </Box> */}

                  <UrlInput
                    placeholder={"Enter a Tiktok Video URL"}
                    url={tiktokLink}
                    setUrl={setTikTokLink}
                    handleClick={handleGetVideoData}
                  />
                </Stack>
                <Typography variant={"body1"} fontSize={12} mt={"14px"}>
                  🔗 Utilize this Tiktok Giveaway picker to randomly choose
                  comments from Tiktok videos.
                </Typography>
              </Grid>
            </Grid>
            {!dataLoading && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                sx={{ marginTop: { xs: "20px", md: "0px" } }}
              >
                <Container
                  sx={{
                    maxWidth: "1200px !important",
                    position: { xs: "relative" },
                    bottom: 0,
                  }}
                >
                  <AppFooter />
                </Container>
              </Box>
            )}
          </Container>
        )}
      </AppHeader>
    </Box>
  );
}

export default TikTokGivaway;
