import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { appColor } from "../constants/Colors";
import CustomButton from "./CustomButton";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
    flexDirection: "column",
    gap: "15px",
  },
  "& .MuiDialog-paper": {
    width: "500px",
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #FFFFFF, #F1F7FE);",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, padding: { xs: "0px 10px", sm: "0 30px", md: "0 40px" } }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: appColor.black }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CommentWaitDialog = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const handleDialogClose = () => {
    handleClose();
    navigate("/dashboard");
  };

  return (
    <BootstrapDialog
      onClose={handleDialogClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
        <Typography
          mt={8}
          fontSize={30}
          variant={"h1"}
          fontWeight={"800"}
          fontFamily={"Roboto Flex"}
          textAlign={"center"}
          lineHeight={"normal"}
        >
          Fetching Comments
        </Typography>
      </BootstrapDialogTitle>
      <DialogContent
        sx={{
          padding: {
            xs: "5px 10px !important",
            sm: "10px 30px !important",
            md: "10px 50px !important",
          },
        }}
      >
        <Typography
          mt={2}
          fontSize={16}
          variant={"h6"}
          fontWeight={"400"}
          fontFamily={"Roboto Flex"}
          textAlign={"center"}
          lineHeight={"normal"}
        >
          This post/tweet has a large number of comments. We are currently
          fetching them for you. We will notify you via email once the process
          is complete.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box height={45} width={"100%"}>
          <CustomButton
            btnText={"Explore Our Other Products"}
            handleClickBtn={handleDialogClose}
          />
        </Box>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default CommentWaitDialog;
