import React from "react";
import { Button } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { appColor } from "../constants/Colors";

function CustomButton({
  textColor,
  textWeight,
  transformText,
  btnBackground,
  textSize,
  hoverProperties,
  borderRadius,
  btnText,
  iconSize,
  handleClickBtn,
  startIcon,
  btnDisable,
}) {
  return (
    <Button
      variant={"outlined"}
      startIcon={startIcon}
      endIcon={<EastIcon sx={{ fontSize: iconSize, color: appColor.white }} />}
      onClick={handleClickBtn}
      disabled={btnDisable || false}
      sx={{
        color: textColor ? textColor : appColor.white,
        borderRadius: borderRadius ? borderRadius : "50px",
        fontWeight: textWeight ? textWeight : 500,
        textTransform: transformText ? transformText : "capitalize",
        backgroundColor: btnBackground ? btnBackground : appColor.black,
        fontSize: textSize ? textSize : "14px",
        width: "100%",
        height: "100%",
        border: "none",
        overflow: "hidden",
        position: "relative",
        padding: "0",
        // transition: "0.5s ease-in-out",
        "&:hover": {
          ...hoverProperties,
          border: "0px",
          color: appColor.white,
          "& .MuiButton-endIcon": {
            display: "inherit",
            position: "inherit",
            zIndex: "999",
            transition: "0.5s ease-in-out",
          },
        },
        "& .MuiSvgIcon-root:hover": {
          color: appColor.white,
        },
        "& .MuiButton-endIcon": {
          display: "none",
          marginLeft: "2px",
          transition: "0.5s ease-in-out",
        },
      }}
      className="animated-button"
    >
      <div
        className="animated-button-bg"
        style={{
          background:
            "-webkit-linear-gradient(180deg, #ffda8d, #e62a21, #8121a3)",
        }}
      ></div>
      <span
        style={{
          position: "inherit",
          zIndex: "999",
        }}
      >
        {btnText}
      </span>
    </Button>
  );
}

export default CustomButton;
