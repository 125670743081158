import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDSR9-Yditp2m4ORlP6hp9trc4aBJoVKLs",
  authDomain: "dual-wire-651e8.firebaseapp.com",
  projectId: "dual-wire-651e8",
  storageBucket: "dual-wire-651e8.appspot.com",
  messagingSenderId: "217444626212",
  appId: "1:217444626212:web:ed76591a83c3df107d4db1",
};

const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);
const googleAuth = new GoogleAuthProvider();
const facebookAuth = new FacebookAuthProvider();

export { googleAuth, firebaseAuth, facebookAuth };
