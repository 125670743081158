import { Avatar, Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { appImages } from "../assets";
import { appColor } from "../constants/Colors";

function DownloadPicture({ certificateData }) {
  const [videoData, setVideoData] = useState("");
  const baseImage = `data:image/jpeg;base64, ${certificateData?.image}`;
  console.log(baseImage, "certificateDatacertificateData");

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("videoInfo"));
    setVideoData(data);
  }, []);

  return (
    <Box
      style={{
        backgroundColor: "#161616",
        borderRadius: "20px",
        minHeight: "225px",
        width: "145px",
        backgroundImage: `url(${appImages.cBackground1})`,
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 20px",
        overflow: "hidden",
      }}
      className="StoryCertificate"
    >
      <img
        src={appImages.cBackground2}
        style={{
          width: "100%",
          position: "absolute",
        }}
      />
      <img
        src={appImages.winnerColor}
        style={{
          width: "45%",
          position: "absolute",
          top: "8px",
          left: "8px",
        }}
      />
      <img
        src={appImages.winnerWhite}
        style={{
          width: "32%",
          position: "absolute",
          bottom: "2px",
          left: "5px",
        }}
      />
      <Box
        sx={{
          borderRadius: "20px",
          border: "1px solid #fff",
          padding: "10px 0",
          backgroundColor: appColor.black,
          width: "110px",
        }}
      >
        <Avatar
          src={certificateData?.image}
          sx={{
            height: 53,
            width: 53,
            border: `1px solid ${appColor.cyanBlue}`,
            padding: "2px",
            margin: "0 auto",
          }}
        />
        <Typography
          variant={"body1"}
          textAlign={"center"}
          fontSize={9}
          fontWeight={500}
          color={appColor.cyanBlue}
          mt={"4px"}
          fontFamily={"Roboto Flex"}
        >
          {certificateData?.name}
        </Typography>
        <Typography
          variant={"body1"}
          textAlign={"center"}
          fontSize={7}
          color={appColor.white}
          lineHeight={"normal"}
          p={"0 10px"}
          fontFamily={"Roboto Flex"}
          //   mt={"4px"}
        >
          {certificateData?.title}
        </Typography>
        <Typography
          variant={"body1"}
          textAlign={"center"}
          fontSize={7}
          lineHeight={"normal"}
          sx={{ textDecoration: "underline" }}
          color={appColor.cyanBlue}
          fontFamily={"Roboto Flex"}
        >
          {certificateData?.author}
        </Typography>
        <Typography
          variant={"body1"}
          textAlign={"center"}
          fontSize={6}
          lineHeight={"normal"}
          sx={{ textDecoration: "underline" }}
          color={appColor.white}
          fontFamily={"Roboto Flex"}
        >
          dualwire.com
        </Typography>
      </Box>
    </Box>
  );
}

export default DownloadPicture;
