import {
  Avatar,
  Box,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appIcons } from "../../../../assets";
import AnimatedLoader from "../../../../components/AnimatedLoader";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import BuyPlanModal from "../../../../components/BuyPlan";
import CustomButton from "../../../../components/CustomButton";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import StackTemplate from "../../../../components/StackTemplate";
import { appColor } from "../../../../constants/Colors";
import { appFonts } from "../../../../constants/Fonts";
import { AppContext } from "../../../../context";
import GlobalStyle from "../../../../globalStyle";
import LoginDialog from "../../../auth/Login";
import SignupDialog from "../../../auth/Signup";
import { getInstaPosComments } from "../../../../service/comments";
import { setAppFilters } from "../../../../redux/slices/GiveawaySlice";

const AllCount = ({ icons, count }) => {
  return (
    <Stack direction={"row"} gap={"5px"} alignItems={"center"}>
      <img src={icons} width={20} />
      <Typography variant={"body1"} fontWeight={600} color={appColor.black}>
        {count || 0}
      </Typography>
    </Stack>
  );
};

function SinglePost() {
  const globalClass = GlobalStyle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const { allInstaPosts, userData } = useContext(AppContext);
  const { ownerData, postData } = allInstaPosts;
  const [openPlanDialog, setOpenPlanDialog] = useState(false);
  const filterData = useSelector((state) => state?.giveaway?.appFilters);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  const handleClosePlanDialog = () => {
    setOpenPlanDialog(false);
  };

  const handleFetchReply = () => {
    if (userData) {
      if (postData?.comments_count <= userData?.comments) {
        setIsLoading(true);
        getInstaPosComments({ mediaId: postData?.id })
          .then((response) => {
            setIsLoading(false);
            if (response?.data?.status === "ok") {
              let comments = response?.data?.data;
              console.log(comments, "commentscommentscomments");
              dispatch(setAppFilters({ ...filterData, allComments: comments }));
              navigate("/instagram-giveaway/comment-filters");
            } else {
              handleSnackbarVal(true, "error", response?.data?.message);
            }
          })
          .catch((resError) => {
            setIsLoading(false);
            handleSnackbarVal(true, "error", "Something went wrong");
          });
      } else {
        setOpenPlanDialog(true);
      }
    } else {
      setOpenSignup(true);
    }
  };

  return (
    <AppHeader>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Divider />
      {isLoading ? (
        <AnimatedLoader />
      ) : (
        <>
          <Typography
            variant={"h1"}
            className={globalClass.raDetailH1}
            textAlign={"center"}
            m={"30px 0 10px 0"}
          >
            Instagram Comment Picker
          </Typography>
          <Typography
            variant={"body1"}
            textAlign={"center"}
            fontSize={14}
            fontFamily={appFonts?.RobotoFlex}
          >
            Select a random comment winner from your Instagram posts and videos
            at no cost.
          </Typography>
          <Container sx={{ maxWidth: "550px !important", mt: 4 }}>
            <Box
              bgcolor={appColor.white}
              borderRadius={"20px"}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
            >
              <Stack
                p={"10px"}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Avatar src={ownerData?.profile_picture_url} alt="Logo" />
                  <Typography
                    variant={"h6"}
                    fontSize={13}
                    fontWeight={600}
                    color={appColor.black}
                  >
                    {ownerData?.name || ownerData?.username}
                  </Typography>
                </Stack>
                <Typography
                  variant={"body1"}
                  onClick={() => navigate(-1)}
                  className={globalClass.raModify}
                >
                  Modify
                </Typography>
              </Stack>
              <Divider />
              <Box width={"100%"} p={"2px"} minHeight={"200px"}>
                <img
                  src={
                    postData?.thumbnail_url
                      ? postData?.thumbnail_url
                      : postData?.media_url
                  }
                  width={"100%"}
                  height={"100%"}
                  style={{ borderRadius: "10px" }}
                />
              </Box>
              <StackTemplate
                stackProps={{
                  justifyContent: "flex-start",
                  m: "8px 0 0 8px",
                  gap: "15px",
                }}
              >
                <AllCount
                  icons={appIcons?.heartBlack}
                  count={postData?.like_count}
                />
                <AllCount
                  icons={appIcons?.commentBlack}
                  count={postData?.comments_count}
                />
                <AllCount
                  icons={appIcons?.shareBlack}
                  count={postData?.like_count}
                />
              </StackTemplate>
              <StackTemplate
                stackProps={{
                  justifyContent: "flex-end",
                  mt: "10px",
                  gap: 2,
                  p: 1,
                }}
              >
                <Typography
                  variant={"body1"}
                  className={globalClass.raBackButton}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Typography>
                <Box width={90} height={30}>
                  <CustomButton
                    btnText={"Comments"}
                    textWeight={400}
                    textSize={10}
                    iconSize={"15px !important"}
                    handleClickBtn={handleFetchReply}
                  />
                </Box>
              </StackTemplate>
            </Box>
          </Container>
          <Container sx={{ maxWidth: "1200px !important", marginTop: "10px" }}>
            <AppFooter />
          </Container>
        </>
      )}

      <BuyPlanModal open={openPlanDialog} handleClose={handleClosePlanDialog} />
      <SignupDialog
        open={openSignup}
        setOpen={setOpenSignup}
        openLogin={setOpenLogin}
      />
      <LoginDialog
        open={openLogin}
        setOpen={setOpenLogin}
        openSignup={setOpenSignup}
      />
    </AppHeader>
  );
}

export default SinglePost;
