import React, { useContext, useEffect, useState } from "react";
import AppHeader from "../../../../components/AppHeader.js";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import SnackbarAlert from "../../../../components/SnackbarAlert.js";
import { AppContext } from "../../../../context/index.js";
import GlobalStyle from "../../../../globalStyle/index.js";
import StackTemplate from "../../../../components/StackTemplate.js";
import { appFonts } from "../../../../constants/Fonts.js";
import InstaPostStyle from "./Style.js";
import { appIcons } from "../../../../assets/index.js";
import { appColor } from "../../../../constants/Colors.js";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../../../components/Loader.js";
import CustomButton from "../../../../components/CustomButton.js";

const REQUIRED_IG_POST_FIELDS =
  "caption,media_url,timestamp,like_count,media_type,comments_count,permalink,thumbnail_url,shortcode,is_comment_enabled,ig_id,username";

const AllCounts = ({ icon, count }) => {
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <img src={icon} width={"23%"} height={"23%"} alt={"icon"} />
      <Typography variant={"body2"} color={appColor.white} fontSize={12}>
        {count}
      </Typography>
    </Box>
  );
};

function PagesPosts() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = InstaPostStyle();
  const globalClass = GlobalStyle();
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [nextPage, setNextPage] = useState(null); // For next page URL
  const {postData, setPostData } = useContext(AppContext);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  console.log(postData,"postDatapostDatapostData")

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    handleFetchUserPosts();
  }, []);

  // Function to fetch posts for the first time
  const handleFetchUserPosts = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `https://graph.facebook.com/v20.0/${state?.instagram_business_account?.id}/media?fields=${REQUIRED_IG_POST_FIELDS}&limit=50&access_token=${state?.access_token}`
      );
      setIsLoading(false);

      if (response?.status === 200 || response?.status === 201) {
        setPosts(response?.data?.data);
        setNextPage(response?.data?.paging?.next || null); // Store the next page URL
      } else {
        handleSnackbarVal(
          true,
          "error",
          "Something went wrong while fetching posts, Go Back"
        );
      }
    } catch (error) {
      handleSnackbarVal(
        true,
        "error",
        "Something went wrong while fetching posts, Go Back"
      );
    }
  };

  // Function to fetch more posts when "Load More" is clicked
  const handleFetchMorePosts = async () => {
    if (!nextPage) return; // If there's no next page, don't proceed

    try {
      setIsLoading(true);
      const response = await axios.get(nextPage); // Fetch the next batch of posts
      setIsLoading(false);

      if (response?.status === 200 || response?.status === 201) {
        setPosts((prevPosts) => [...prevPosts, ...response?.data?.data]); // Append new posts to existing posts
        setNextPage(response?.data?.paging?.next || null); // Update the next page URL
      } else {
        handleSnackbarVal(
          true,
          "error",
          "Something went wrong while fetching more posts"
        );
      }
    } catch (error) {
      handleSnackbarVal(
        true,
        "error",
        "Something went wrong while fetching more posts"
      );
    }
  };

  return (
    <AppHeader>
      <Divider />
      <Loader isloading={isLoading} />
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <StackTemplate
        stackProps={{
          justifyContent: "center",
          gap: "20px",
          margin: "30px 0 10px 0",
        }}
      >
        <Avatar
          src={state?.picture?.data?.url}
          alt={state?.name}
          sx={{
            height: 50,
            width: 50,
          }}
        />
        <Typography variant={"h1"} className={globalClass.raDetailH1}>
          hello, {state?.instagram_business_account?.username}
        </Typography>
      </StackTemplate>
      <Typography
        variant={"body1"}
        textAlign={"center"}
        fontSize={14}
        fontFamily={appFonts?.RobotoFlex}
      >
        Please, select a post
      </Typography>
      <Container sx={{ maxWidth: { sm: "1000px !important", lg: "lg" } }}>
        <Grid container spacing={2} mt={4}>
          {posts?.map((dataa, index) => {
            return (
              <Grid item xs={12} sm={4} md={3} key={index}>
                <Box
                  className={classes.instaCrad}
                  onClick={() => {
                    navigate("/instagram-giveaway/post-detail");
                    setPostData({
                      ...dataa,
                      profile_picture_url: state?.picture?.data?.url,
                      type: "page",
                      access_token: state?.access_token,
                    });
                  }}
                >
                  <StackTemplate
                    stackProps={{
                      position: "absolute",
                      justifyContent: "center !important",
                    }}
                  >
                    <AllCounts
                      icon={appIcons?.commentWhite}
                      count={dataa?.comments_count}
                    />
                    <AllCounts
                      icon={appIcons?.heartWhite}
                      count={dataa?.like_count}
                    />
                    <AllCounts
                      icon={appIcons?.shareWhite}
                      count={dataa?.comments_count}
                    />
                  </StackTemplate>
                  <img
                    src={
                      dataa?.thumbnail_url
                        ? dataa?.thumbnail_url
                        : dataa?.media_url
                    }
                    alt={dataa?.caption}
                    className={classes.instaCradImg}
                    loading="lazy"
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
        {nextPage && (
          <Box display={"flex"} justifyContent={"center"} my={4}>
            <Box width={150} height={46}>
              <CustomButton
                btnText={"Load More"}
                textWeight={600}
                textSize={16}
                // iconSize={"15px !important"}
                handleClickBtn={handleFetchMorePosts}
              />
            </Box>
          </Box>
        )}
      </Container>
    </AppHeader>
  );
}

export default PagesPosts;
