import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { appImages } from "../../../../assets";
import AnimatedLoader from "../../../../components/AnimatedLoader";
import AppFooter from "../../../../components/AppFooter";
import AppHeader from "../../../../components/AppHeader";
import Loader from "../../../../components/Loader";
import SnackbarAlert from "../../../../components/SnackbarAlert";
import SoundWaveGif from "../../../../components/SoundWaveGif";
import UrlInput from "../../../../components/urlInput";
import { AppContext } from "../../../../context";
import GlobalStyle from "../../../../globalStyle";
import { setAppFilters } from "../../../../redux/slices/GiveawaySlice";
import { getTwitterPost } from "../../../../service/comments";
import { fetchHome } from "../../../../service/common";
import StackTemplate from "./../../../../components/StackTemplate";
import "./style.css";

function TwitterGiveAway() {
  const classes = GlobalStyle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [homeData, setHomeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tweetLoading, setTweetLoading] = useState(false);
  const [twitterUrl, setTwitterUrl] = useState("");
  const { setTweetData, setCurrentColor, setCountDown } =
    useContext(AppContext);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleSnackbarVal = (open, type, message) => {
    setSnackbarProps({
      open,
      type,
      message,
    });
  };

  const handleCloseSnackbar = () => {
    handleSnackbarVal(false, "", "");
  };

  useEffect(() => {
    let params = {
      giveawayType: "Twitter",
      dublicate: false,
      hashtag: false,
      block: false,
      extraChance: false,
      extraChanceNames: [],
      blockListNames: [],
      hashtagText: "",
      counter: 5,
      totalWinners: 1,
      totalSubstitutes: 0,
      actionType: "add",
      giveAwayId: "",
      allComments: [],
      currentColor: "#ffffff",
      twitterType: "",
    };
    dispatch(setAppFilters(params));
    setCurrentColor("#fff");
    setCountDown(5);
  }, []);

  const handleFetchTwitterPost = () => {
    if (!twitterUrl) {
      handleSnackbarVal(true, "error", "Please enter the URL first");
    } else {
      let validUrl =
        twitterUrl.startsWith("https://twitter.com") ||
        twitterUrl.startsWith("https://x.com");
      let tweetId = "";
      let isIncludeSi = twitterUrl.includes("?s");
      if (isIncludeSi) {
        tweetId = twitterUrl.split("/").pop().split("?s")[0];
      } else {
        tweetId = twitterUrl.split("/").pop();
      }
      if (validUrl && tweetId) {
        setTweetLoading(true);
        getTwitterPost({ tweetId })
          .then((response) => {
            setTweetLoading(false);
            if (response?.data?.status == "ok") {
              navigate("/twitter-giveaway/tweet-details", { state: tweetId });
              let data = response?.data?.data;
              let newData = { ...data, tweetId, twitterUrl };
              setTweetData(newData);
              localStorage.setItem("post_data", JSON.stringify(newData));
            } else {
              handleSnackbarVal(true, "error", response?.data?.message);
            }
          })
          .catch((resError) => {
            setTweetLoading(false);
            handleSnackbarVal(true, "error", resError?.message);
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "The URL you entered is incorrect.",
          confirmButtonColor: "#ff3600",
          customClass: {
            container: "custom-swal-container",
          },
        });
      }
    }
  };

  return (
    <AppHeader>
      <SnackbarAlert
        snackbarProps={snackbarProps}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Loader isloading={isLoading} />
      <Divider />
      {tweetLoading ? (
        <AnimatedLoader />
      ) : (
        <>
          <Container sx={{ maxWidth: "1250px !important", marginTop: "70px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box>
                  <img
                    src={appImages.radiusCircle}
                    width={"400px"}
                    style={{
                      position: "absolute",
                      top: "4%",
                      left: "8%",
                      zIndex: "666",
                    }}
                    className="rectangle-image"
                  />
                  <Box
                    position={"absolute"}
                    top={"30px"}
                    zIndex={"1100"}
                    left={"22%"}
                    sx={{
                      top: { xs: "72px", sm: "30px", md: "30px", lg: "30px" },
                      left: { xs: "30%", sm: "36%", md: "23%", lg: "20%" },
                    }}
                  >
                    <Box sx={{ width: 220 }}>
                      <img
                        src={appImages.giveAway}
                        width={"100%"}
                        className="main-image"
                      />
                    </Box>
                    <Box
                      height={220}
                      width={220}
                      position={"absolute"}
                      top={"45%"}
                      left={"30%"}
                      zIndex={"1150"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        top: { xs: "48%", sm: "70%" },
                        left: { xs: "16.5px", sm: "-50px" },
                        height: { xs: "155px", sm: "200px" },
                        width: { xs: "155px", sm: "200px" },
                      }}
                    >
                      <img
                        src={appImages.roundText}
                        width={"100%"}
                        className="round-text"
                      />
                      <img
                        src={appImages.whiteLine}
                        style={{ position: "absolute", width: "85%" }}
                      />
                      <img
                        src={appImages.blackCirlcle}
                        width={"78%"}
                        style={{
                          position: "absolute",
                        }}
                      />
                      <img
                        src={appImages.playButton}
                        style={{
                          width: "12%",
                          position: "absolute",
                        }}
                      />
                      <img
                        src={appImages.cirlcleTransparent}
                        style={{
                          width: "70%",
                          position: "absolute",
                          left: "-80px",
                        }}
                        className="rectangle-image"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ mt: { xs: "15rem", sm: "22rem", md: "0px" } }}
              >
                <Typography variant={"h4"} className={classes.rahomeh1}>
                  Twitter Giveaway Picker
                </Typography>
                <Typography
                  variant={"h1"}
                  className={classes.rahomeh2}
                  sx={{ fontSize: { xs: 30, sm: 40, lg: 50 } }}
                >
                  Your Giveaway,
                </Typography>
                <StackTemplate
                  stackProps={{
                    gap: 1,
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    variant={"h1"}
                    className={classes.rahomeh2}
                    sx={{ fontSize: { xs: 30, sm: 40, md: 45, lg: 50 } }}
                  >
                    Our Pick
                  </Typography>

                  <SoundWaveGif />
                </StackTemplate>
                <Typography variant={"body1"} className={classes.rahomeh3}>
                  Unlock the excitement as every single like and retweet gets
                  it’s chance to shine and claim victory. Try it today and
                  witness the magic unfold as engagement turns into coveted
                  wins!
                </Typography>

                <UrlInput
                  placeholder={"Enter a Twitter post url"}
                  url={twitterUrl}
                  setUrl={setTwitterUrl}
                  handleClick={handleFetchTwitterPost}
                />
                <Typography variant={"body1"} fontSize={12} mt={"14px"}>
                  🔗 pick a random like/retweet from your twitter post with this
                  twitter giveaway picker
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      {!tweetLoading && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          sx={{ marginTop: { xs: "20px", md: "0px" } }}
        >
          <Container
            sx={{
              maxWidth: "1200px !important",
              position: { xs: "relative", md: "absolute" },
              bottom: 0,
            }}
          >
            <AppFooter />
          </Container>
        </Box>
      )}
    </AppHeader>
  );
}

export default TwitterGiveAway;
