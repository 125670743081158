import React, { useContext } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { appColor } from "../constants/Colors";
import { AppContext } from "../context";

const QuantityCounter = ({
  selectedQty,
  handleIncrease,
  handleDecrease,
  handleChangeCounters,
}) => {
  const { currentColor } = useContext(AppContext);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        border: `1px solid ${
          currentColor == "#161616" ||
          currentColor == "#5A663E" ||
          currentColor == "#798952"
            ? "#C9CCD1"
            : appColor.black
        }`,
        borderRadius: "10px",
        overflow: "hidden",
        paddingRight: "6px",
      }}
    >
      <input
        style={{
          width: "55px",
          height: "30px",
          textAlign: "center",
          background: "inherit",
          outline: "none",
          border: "none",
          fontFamily: "Poppins",
          fontSize: "18px",
          color:
            currentColor == "#161616" ||
            currentColor == "#5A663E" ||
            currentColor == "#798952"
              ? "#fff"
              : appColor.black,
        }}
        // disabled
        onChange={handleChangeCounters}
        value={selectedQty}
        type="number"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <KeyboardArrowUpIcon
          style={{
            fontSize: "18px",
            color:
              currentColor == "#161616" ||
              currentColor == "#5A663E" ||
              currentColor == "#798952"
                ? "#fff"
                : appColor.black,
            cursor: "pointer",
          }}
          onClick={handleIncrease}
        />
        <KeyboardArrowDownIcon
          style={{
            fontSize: "18px",
            color:
              currentColor == "#161616" ||
              currentColor == "#5A663E" ||
              currentColor == "#798952"
                ? "#fff"
                : appColor.black,
            cursor: "pointer",
          }}
          onClick={handleDecrease}
        />
      </div>
    </div>
  );
};

export default QuantityCounter;
